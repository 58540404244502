import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
const { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface Data {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
  }
}
export interface S {
  // Customizable Area Start
  loading: boolean;
  detail: any;
  data: Data[];
  err: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPrivacyPolicyApiCallID: string = "";
  getDetailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      detail: "",
      data: [],
      err: false
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDetailApiCallId) {
          if (responseJson?.data?.length > 0) {
            this.setState({ detail: responseJson?.data[0]?.attributes });
          }
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getDetails();
    this.getPrivacyPolicy();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  getPrivacyPolicy = () => {
    this.setState({ loading: true });

    fetch(baseURL + "/privacy_policies")
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ data: responseJson?.data, loading: false });
      })
      .catch(err => {
        this.setState({ err: true, loading: false });
      });
  }

  getDetails = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
