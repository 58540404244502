import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");
export const baseurl = require('../../../../packages/framework/src/config');

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}


export interface S {
    // Customizable Area Start
    loading: boolean;
    cmsDetail: any;
    data: string;
    documentArr: any[],
    invoiceId: any,
    transactionType: any,
    AmountPaid: any,
    invoiceAmount: any,
    totalAmount: any,
    totalDiscount: any,
    totalPrice: any,
    netAmount: any,
    accountId: any,
    invoiceDate: any,
    modelState: any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SuccessPaymentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    InvoiceCreate: string = "";
    successPaymentAPiCallId: string = "";
    ShowInvoice: string = "";
    DownloadString: string = "";
    getCmsDetailApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            cmsDetail: null,
            data: "",
            documentArr: [],
            invoiceId: "",
            transactionType: "",
            AmountPaid: "",
            invoiceAmount: "",
            totalAmount: "",
            totalDiscount: "",
            totalPrice: "",
            netAmount: "",
            modelState: false,
            accountId: "",
            invoiceDate: ""
            // Customizable Area End
        };
    }

    async componentDidMount() {
        this.getCmsDetails();
        this.getSuccessPayment();
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        window.scrollTo(0, 0);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson && responseJson.data) {
                if (apiRequestCallId === this.successPaymentAPiCallId) {
                    if (responseJson.data.attributes?.status === "completed") {
                        toast.success("We Have Received Your Payment!");

                        this.setState({
                            accountId: responseJson.data.attributes.account_id,
                            invoiceAmount: responseJson.data.attributes.order_data.amount_total,
                            transactionType: responseJson.data.attributes.order_data.payment_method_types[0],
                            netAmount: responseJson.data.attributes.order_data.amount_total,
                            totalAmount: responseJson.data.attributes.order_data.amount_total,
                            invoiceDate: responseJson.data.attributes.updated_at
                        });

                        setTimeout(() => {
                            this.createInvoice();
                        }, 2000);
                    }
                } else if (apiRequestCallId === this.InvoiceCreate) {
                    setTimeout(() => {
                        this.setState({
                            invoiceId: responseJson.data.id
                        });

                        this.invoiceShow();
                    }, 2000);
                } else if (apiRequestCallId === this.getCmsDetailApiCallID) {
                    this.setState({ cmsDetail: responseJson.data?.attributes });
                }
            }
            this.setState({ loading: false });
        }
        // Customizable Area End
    }

    // Customizable Area Start

    downloadFile = (blob: any, fileName: any) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        toast.success("Please Check Your Invoice")
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 500);
        setTimeout(() => {
            this.setState({
                modelState: true
            });
        }, 2000);
    };

    downloadInvoice = async () => {
        toast.warning("Please wait");

        const localToken = localStorage.getItem("authToken") as string;
        const CourseId = localStorage.getItem("id") as any;
        try {
            fetch(`${baseurl.baseURL}/bx_block_invoice/invoices/invoice_pdf?subscription_plan_id=${CourseId}&id=${this.state.invoiceId}`, { headers: { token: localToken, "accept": "application/pdf" } })
                .then((response) => response.blob())
                .then((data) => {
                    this.downloadFile(data, 'ECO_DRIVE_INVOICE.pdf');
                }).catch((error) => console.log(error, "pdf error"));

        } catch (error) {
            console.log(error, "error");
        }
    }

    modelClose = () => {
        this.setState({
            modelState: false
        })
        this.props.navigation.navigate("Dashboard");
    }

    getCmsDetails = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.successPaymentCmsApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCmsDetailApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.successPaymentCmsApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.successPaymentCmsApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getSuccessPayment = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.successPaymentAPiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_payments/payments/get_stripe_payment"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createInvoice = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.contentTypeApiDocument,
            token: localStorage.getItem("authToken")
        };

        const sendData = localStorage.getItem("sendItem") as any;
        const apiData = JSON.parse(sendData);
        const id = localStorage.getItem("id");
        const count = localStorage.getItem('count');
        const name = localStorage.getItem("name");
        const net = localStorage.getItem("net");
        const vat = localStorage.getItem('vat');
        const amount = localStorage.getItem("amount");

        const httpBody = {
            "data": {
                "attributes": {
                    "licence_type": apiData.licence_type,
                    "course_type": name,
                    "gear_type": apiData.gear_type,
                    "transaction_type": this.state.transactionType,
                    "stage": "Registration",
                    "company_phone": "97155864821",
                    "trn": "CRP745896",
                    'subscription_plan_id': id,
                    "s_no": "01",
                    "supply_place": "Dubai",
                    "invoice_amount": amount,
                    "total_amount": net,
                    "total_discount": 0.00,
                    "total_price": net,
                    "net_amount": net,
                    "vat": vat,
                    "quantity": count,
                    "account_id": this.state.accountId
                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.InvoiceCreate = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_invoice/invoices"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    invoiceShow = () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ShowInvoice = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_invoice/invoices/${this.state.invoiceId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}