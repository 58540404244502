import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Box } from "@material-ui/core";
const { baseURL } = require("../../framework/src/config.js");

export interface Detail {
  id: string;
  attributes: {
    id: number;
    whatsup_name: string;
    whatsup_icon: string;
    whatsup_link: string;
  }
}
export interface state {
  iconToggle: any;
  detail: Detail[];
}

export default class WhatsappIcon extends Component<{}, { iconToggle: any, detail: any[] }> {

  constructor(props: state) {
    super(props);
    this.state = {
      iconToggle: false,
      detail: []
    }
  }

  componentDidMount() {
    this.getWhatsAppList();
  }

  getWhatsAppList = () => {
    const fetchUrl = `${baseURL}/bx_block_dashboard/social_infos`
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson?.data) {
          this.setState({
            detail: responseJson?.data
          })
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <WhatsApp>
          {this.state.iconToggle &&
            <QuickBox>
              {this.state.detail.length !== 0 &&
                <Menu>
                  {this.state.detail.map((item: Detail, index: number) => (
                    <MenuItem key={index}>
                      <span>
                        <img src={baseURL + item.attributes.whatsup_icon} />
                      </span>
                      <Link style={{ textDecoration: "none", color: "#151817" }} to={item.attributes.whatsup_link}>{item.attributes.whatsup_name}</Link>
                    </MenuItem>
                  ))}
                </Menu>
              }
            </QuickBox>
          }
        </WhatsApp>
        <Box
          position="absolute"
          right={{ xs: "20px", sm: "30px", md: "50px" }}
          bottom={{ xs: "20px", sm: "30px", md: "50px" }}
          onClick={() => {
            this.setState({ iconToggle: !this.state.iconToggle })
          }}>
          <img src={"assets/images/whatapp.png"} className="whatapp-img" />
        </Box>
      </>
    );
  }
}

const WhatsApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 135px;
  right: 0px;
  @media(max-width:768px){
    bottom: 90px;
  }
`;

const QuickBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  z-index:999;
`;

const Menu = styled.ul`
  list-style: none;
  width: 362px;
  @media(max-width:768px){
    width:290px;
  }
`;

const MenuItem = styled.li`
  border-bottom: 1.5px solid rgb(219, 219, 219);
  padding: 15px 20px;
  letter-spacing: 0.8;
  background-color: white;
  font-size: 20px;
  color: #151817;
  font-weight: 700;
  img {
    height: 40px;
    width: 40px;
  };
  span {
    margin-right: 17px;
  };
  @media(max-width:768px){
    padding: 10px 15px;
    font-size:15px;
    img {
      height: 30px;
      width: 30px;
    }
  }
`;
