import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
const { baseURL } = require("../../../framework/src/config");

export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  history: any;
  match: any;
}

export interface Data {
  id: number;
  type: string;
  attributes: {
    title: string;
    description: string;
    doc_type: string;
    image_or_video: string;
    posted_on: string;
  }
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  CmsData: any;
  news: Data[];
  events: Data[];
  videos: Data[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewsAndEventController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newsEventApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      CmsData: "",
      news: [],
      events: [],
      videos: [],
      loading: false,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson && responseJson.data) {
        if (apiRequestCallId === this.newsEventApiCallId) {
          let news: Data[] = [];
          let events: Data[] = [];
          let videos: Data[] = [];

          responseJson.data.map((item: Data) => {
            switch (item?.attributes?.doc_type) {
              case "news":
                news.push({ ...item, id: news.length });
                break;
              case "event":
                events.push({ ...item, id: events.length });
                break;
              case "video":
                videos.push({ ...item, id: videos.length });
                break;
              default:
                news.push({ ...item, id: news.length });
                break;
            }
          })

          this.setState({ news, events, videos });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  redirectToDetailPage = (id: number, type: string, data: Data[]) => {
    this.props.history.push({
      pathname: configJSON.REDIRECT_TO,
      state: { id, type, data }
    });
  }

  getCmsApi() {
    const fetchUrl: string = `${baseURL}/bx_block_dashboard/page_titles/:id?name=News`
    fetch(`${fetchUrl}`)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          CmsData: ResponseJson?.data?.attributes
        })
      }).catch((error) =>
        console.log(error, "News page")
      );
  }

  fetchNewsEventData = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newsEventApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
