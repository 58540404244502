import React from "react";
// Customizable Area Start
import { 
  Button,
  Box 
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import OtpInput from "react-otp-input";
import OnlineLectureOTPController, { Props } from "./OnlineLectureOTPController.web";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End

export default class OnlineLectureOTP extends OnlineLectureOTPController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
       //Merge Engine DefaultContainer
       <div className="otp common common-form">
         {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
          <div className="first-section">
            <div className="content">
              <h2 className="title">OTP</h2>
              <h5 className="sub-title">Enter OTP to access the online Lecture.</h5>
            </div>
            <div className="form">
              <Box component="p">OTP*</Box>
              <OtpInput
                containerStyle="otp-input-box"
                inputStyle="input-otp"
                className="input-otp-wrap"
                onChange={(otp:number)=>this.changeOtp(otp)}
                value={this.state.otp}
                numInputs={6}
                isInputNum
              />
              <Box component="span" className="text-danger">{this.state.isButtonClicked && this.state.otpError}</Box>
              <ProceedButton fullWidth onClick={()=> this.verifyOtp()}>Proceed</ProceedButton>
              {this.state.seconds === 0 ?
                <Box textAlign="end">
                  <Box component="span" style={{color:"#B0B0B0",borderBottom:"1px solid #B0B0B0"}}>Didn't receive OTP?</Box>
                  <Box component="span" style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=> this.resendOtp()}>{"  "}Resend</Box>
                </Box>
                :<Box textAlign="end">
                  <Box component="span" style={{color:"#B0B0B0",borderBottom:"1px solid #B0B0B0"}}>Resend OTP in</Box> 
                  <Box component="span" style={{fontWeight:"bold"}}>{"  "}00:{this.state.seconds < 10 ? "0"+this.state.seconds : this.state.seconds}</Box>
                </Box>
              }
            </div>
          </div>
       </div>
     //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const ProceedButton = styled(Button)({
  backgroundColor: "black",
  margin: "70px 0px 20px 0px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  },
});

// Customizable Area End