import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  InputBase,
  Radio,
  RadioGroup,
  FormControlLabel,
  Link,
  TextareaAutosize
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import {YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import moment from "moment";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import Spinner from "../../../components/src/Spinner.web";
const wpIcon = require("../assets/whatapp.png");
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
// Customizable Area End

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: 'ITC Avant Garde Gothic Std'
});

const EIDButton = styled(Box)({
  backgroundColor: "black",
  color: "white",
  cursor:"pointer",
  padding:"0px 20px", 
  display:"flex",
  height:"86px",
  borderRadius: "15px",
  fontSize:"16px",
  fontWeight: "bold",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
});

const dobStyle = {
  borderBottom:"1px solid #BBBCBC",
  paddingBottom:"6px",
  display:"flex",
  justifyContent:"space-between", 
  alignItems:"center",
  flexWrap:"wrap"
} as const;

const SubmitButton = styled(Button)({
  backgroundColor: "black",
  margin: "20px 0px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  },
});

const CustomLink = styled(Link)({
  color:"black",
  cursor:"pointer",
  textDecoration:"none",
  fontWeight:"bold",
  "&:hover": {
    color:"black",
    textDecoration:"none",
  },
});

const phoneCodeData = [
  { label:"+91", value:"+91" },
  { label:"+971", value:"+971" },
];

const languageData = [
  { label: "Arabic", value: "Arabic" },
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Urdu", value: "Urdu" },
];

const vehicleGearDataForCar = [
  { label: "Automatic", value: "Automatic" },
  { label: "Manual", value: "Manual" },
];

const vehicleGearData = [
  { label: "Manual", value: "Manual" },
];

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  registrationSchema = () => {
    return Yup.object().shape({
      vehicleType: Yup.string().required("*Vehicle Type is required"),
      vehicleGear: Yup.string().required("*Vehicle Gear is required"),
      fullName: Yup.string().required("*Full Name is required")
      .matches(/^[A-Za-z ]*$/, "*Please enter valid name"),
      gender: Yup.string().required("*Select any option"),
      email: Yup.string()
      .email("*Enter valid email")
      .required("*Email is required"),
      phoneNumber: Yup.string().required("*Phone Number is required")
      .min(9,"*Enter valid phone number"),
      // .matches(/^(5)[0-9]+$/, "*Enter valid phone number"),
      dob: Yup.date().when(
          "vehicleType",
          (value:any, schema:any) =>{ 
            if(value){
              let date = new Date(moment().subtract(18, "years").toISOString());
              return schema.max(date,"*Date must be above 18+ year");
            }
      }).typeError("Enter valid date of birth")
      .required("*Date of Birth is required"),
      language: Yup.string().required("*Language is required"),
      nationality: Yup.string().required("*Nationality is required"),
      residencyVisa: Yup.string().required("*Select any option")
  });
  };

  render() {
    return (
      <div className="registrations common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url("assets/images/home-bg.png")` }} >
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Join Eco Drive"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Eco Drive - The driving Institute."}</h5>
          </div>
          <Formik
            enableReinitialize={this.state.readData}
            initialValues={{
              vehicleType: this.state.vehicleType,
              vehicleGear: this.state.vehicleGear,
              fullName: this.state.fullName,
              gender:this.state.gender,
              email: this.state.email,
              phoneNumber: this.state.phoneNumber,
              dob: this.state.dob,
              language: this.state.language,
              nationality: this.state.nationality,
              residencyVisa: this.state.residencyVisa,
            }} 
            validationSchema={this.registrationSchema}
            onSubmit={(values) => {
              this.submitHandler();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >
                {this.state.readData && this.setState({readData: false})}
                <Grid container spacing={7} className="grid-padding">
                  <Grid item xs={12} sm={6} md={6}>
                    <Box mb={2}>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        id="frontReadEID"
                        onChange={(event: any) =>
                          this.setState({
                            frontLicencePhoto: event.target.files[0]
                          },()=>this.frontImageApi())
                        }
                      /> 
                      <label htmlFor="frontReadEID" style={{width:"100%",margin:"0px"}}>
                        <EIDButton>
                          <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <div>
                              <div style={{textAlign:"start"}}>Read Frontside EID</div>
                              <div style={{color:"#BDBFBE",textAlign:"start", fontWeight:"normal",fontSize:"12px"}}>Upload from your Computer</div>
                            </div>
                            <MdOutlineDriveFolderUpload style={{width:"30px",height:"30px"}}/>
                          </div>
                        </EIDButton>
                      </label>
                    </Box>
                    <Box mb={2}>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        id="backReadEID"
                        onChange={(event:React.ChangeEvent<any>) =>
                          this.setState({
                            backLicencePhoto: event?.target?.files[0]
                          },()=>this.backImageApi())
                        }
                      />
                      <label htmlFor="backReadEID" style={{width:"100%",margin:"0px"}}>
                        <EIDButton>
                          <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <div>
                              <div style={{textAlign:"start"}}>Read Backside EID</div>
                              <div style={{color:"#BDBFBE",textAlign:"start", fontWeight:"normal",fontSize:"12px"}}>Upload from your Computer</div>
                            </div>
                            <MdOutlineDriveFolderUpload style={{width:"30px",height:"30px"}}/>
                          </div>
                        </EIDButton>
                      </label>
                    </Box>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        isSearchable={false}
                        placeholder="Vehicle Type"
                        options={this.state.vehicleTypeData}
                        value={
                        this.state.vehicleTypeData.filter(
                            (option: any) =>
                              option?.value === this.state.vehicleType
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("vehicleType", event.value);
                          this.setState({ vehicleType: event.value });
                          if(this.state.vehicleGear && event.value !== "Car"){
                            this.setState({ vehicleGear: "Manual"});
                            setFieldValue("vehicleGear", "Manual");
                          }
                        }}
                      />
                      {touched.vehicleType && errors.vehicleType && 
                        <div className="text-danger">{errors.vehicleType}</div>
                      }
                    </Box>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Vehicle Gear"
                        isSearchable={false}
                        options={this.state.vehicleType === "Car" ? vehicleGearDataForCar : vehicleGearData}
                        value={
                          vehicleGearDataForCar.filter(
                            (option: any) =>
                              option.value === this.state.vehicleGear
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("vehicleGear", event?.value);
                          this.setState({ vehicleGear: event?.value })
                        }}
                      />
                      {touched.vehicleGear &&errors.vehicleGear && 
                        <div className="text-danger">{errors.vehicleGear}</div>
                      }
                    </Box>
                    <Box mb={3}>
                      <div>
                        <CustomInputBase
                          fullWidth
                          placeholder="Full Name*"
                          aria-label="empty textarea"
                          value={this.state.fullName}
                          onChange={(event: any) =>{
                            setFieldValue("fullName", event.target.value);
                            this.setState({ fullName: event.target.value })
                          }}
                        />
                      </div>
                      {touched.fullName && errors.fullName && 
                        <div className="text-danger">{errors.fullName}</div>
                      }
                    </Box>
                    <Box mb={2}>
                      <Typography style={{fontFamily: 'ITC Avant Garde Gothic Std'}}>
                        Gender
                      </Typography>
                     <div>
                      <RadioGroup
                        row
                        aria-labelledby="gender"
                        name="gender"
                        className="radio-btn"
                        value={this.state.gender}
                        onChange={(event: any) => {
                          setFieldValue("gender", event.target.value);
                          this.setState({ gender:event.target.value });
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        <FormControlLabel
                          style={{marginRight:"45px"}}
                          value={"0"}
                          control={<Radio style={{ color: "black" }} />}
                          label="Male"
                        />
                        <FormControlLabel
                          value={"1"}
                          control={<Radio style={{ color: "black" }} />}
                          label="Female"
                        />
                      </RadioGroup>
                     </div>
                      {touched.gender && errors.gender && 
                        <div className="text-danger">{errors.gender}</div>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <Box mb={2}>
                      <CustomInputBase
                        fullWidth
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(event: any) =>{
                          setFieldValue("email", event.target.value);
                          this.setState({ email: event.target.value, emailErr: false });
                        }}
                      />
                      {touched.email && errors.email && 
                        <div className="text-danger">{errors.email}</div>
                      }
                      {this.state.emailErr && (
                      <div className="text-danger">
                        *Email is already exists
                      </div>
                    )}
                    </Box>
                  <Box mb={2}>
                      <div className="box-field">
                        <Select
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={phoneCodeData}
                          value={
                            phoneCodeData.filter(
                              (option: any) =>
                                option.value === this.state.phoneCode
                            )[0]
                          }
                          onChange={(event: any) => this.setState({ phoneCode: event.value,phoneErr: "" })}
                        />
                        <div style={{ marginLeft: "10px",width:"100%" }}>
                          <CustomInputBase
                            fullWidth
                            placeholder="Phone Number*"
                            value={this.state.phoneNumber}
                            onChange={(event: any) =>{
                              const re = /^[0-9\b]+$/;
                              if ((event.target.value === '' || re.test(event.target.value)) && event.target.value.length <=10) {
                                setFieldValue("phoneNumber", event.target.value);
                                this.setState({ phoneNumber: event.target.value, phoneErr: "" });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {touched.phoneNumber && errors.phoneNumber && 
                        <div className="text-danger">{errors.phoneNumber}</div>
                      }
                      {this.state.phoneErr &&
                        <div className="text-danger">
                          *{this.state.phoneErr}
                        </div>
                      }
                    </Box>
                    <Box mb={2} mt="22px">
                      <div style={dobStyle}>
                      <span>Date of Birth</span>
                      <div className="dropdown-date">
                        <DayPicker
                          defaultValue={'Day'}
                          year={this.state.year === "-" ? "" : this.state.year}
                          month={this.state.month === "-" ? "" : this.state.month}
                          required={true}
                          value={this.state.day}
                          onChange={(day:any) => {
                            this.setState({ day });
                            setFieldValue("dob",new Date(this.state.year,this.state.month,day));
                          }}
                        />
                        <MonthPicker
                          defaultValue={'Month'}
                          classes="month"
                          short
                          year={this.state.year}
                          required={true}
                          value={this.state.month}
                          onChange={(month:any) => {
                            const today = new Date();
                            let day = this.state.day;
                            if(this.state.year === today.getFullYear() && this.state.day > today.getDate())
                              day= "-";

                            this.setState({ day:day, month: parseInt(month) });
                            setFieldValue("dob",new Date(this.state.year,parseInt(month)+1,day));
                          }}
                        />
                        <YearPicker
                          defaultValue={'Year'}
                          reverse
                          required={true}
                          value={this.state.year}
                          onChange={(years:any) => {
                            const today = new Date();
                            let month:any = parseInt(this.state.month)+1;
                            let year = parseInt(years);
                            if(year === today.getFullYear() && this.state.month > today.getMonth())
                              month="-";
                            
                            this.setState({ year });
                            setFieldValue("dob",new Date(year,month,this.state.day));
                          }}
                         />
                        </div>
                      </div>
                      {touched.dob && errors.dob && 
                        <div className="text-danger">{errors.dob}</div>
                      }
                    </Box>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Language"
                        isSearchable={false}
                        options={languageData}
                        value={
                          languageData.filter(
                            (option: any) =>
                              option.value === this.state.language
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("language", event.value);
                          this.setState({ language: event.value })
                        }}
                      />
                      {touched.language && errors.language && 
                        <div className="text-danger">{errors.language}</div>
                      }
                    </Box>
                    <Box mb={3}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Nationality"
                        options={this.state.dataSource}
                        value={
                          this.state.dataSource.filter(
                            (option: any) => option.label === this.state.nationality
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("nationality", event.label);
                          this.setState({ nationality: event.label })
                        }}
                      />
                      {touched.nationality && errors.nationality && 
                        <div className="text-danger">{errors.nationality}</div>
                      }
                    </Box>
                    <Box>
                      <Typography style={{fontFamily: 'ITC Avant Garde Gothic Std'}}>
                        Do you have a residency visa in the UAE?
                      </Typography>
                     <div>
                      <RadioGroup
                        row
                        aria-labelledby="residencyVisa"
                        name="residencyVisa"
                        className="radio-btn"
                        value={this.state.residencyVisa}
                        onChange={(event: any) => {
                          setFieldValue("residencyVisa", event.target.value);
                          this.setState({ residencyVisa:event.target.value });
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        <FormControlLabel
                        style={{marginRight:"45px",fontFamily: 'ITC Avant Garde Gothic Std'}}
                          value={"true"}
                          control={<Radio style={{ color: "black" }} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={"false"}
                          control={<Radio style={{ color: "black" }} />}
                          label="No"
                        />
                      </RadioGroup>
                     </div>
                      {touched.residencyVisa && errors.residencyVisa && 
                        <div className="text-danger">{errors.residencyVisa}</div>
                      }
                    </Box>
                    <SubmitButton type="submit" fullWidth>
                      Submit
                    </SubmitButton>
                    <Box textAlign="end">
                      <span style={{color:"#B0B0B0",borderBottom:"1px solid #B0B0B0"}}>Already member?</span> 
                      <CustomLink onClick={()=>this.props.navigation.navigate("EmailAccountLoginBlock")}> Login</CustomLink>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Box position="absolute" right={{xs:"20px",sm:"25px",md:"30px"}} bottom={{xs:"20px",sm:"30px",md:"25px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
