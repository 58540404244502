import React from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import { Box, CardMedia } from "@material-ui/core";
import styled from "styled-components";
import Spinner from "../../../components/src/Spinner.web";
const videoImg = require("../assets/video-img.png");
// Customizable Area End

import NewsAndEventController, { Props, Data } from "./NewsAndEventController.web";

class NewsAndEvent extends NewsAndEventController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCmsApi();
    this.fetchNewsEventData();
    window.scrollTo(0, 0);
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CommonWrapper className="document common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div
          className="first-section"
          style={{
            backgroundImage: this.state.CmsData.image
              ? `url(${this.state.CmsData.image})`
              : "url('assets/images/news_events.png')",
          }}
        >
          <div>
            <div className="content">
              <h2 className="title">
                {this.state.CmsData.title
                  ? this.state.CmsData.title
                  : "News and Events"}
              </h2>
              <h5 className="sub-title">
                {this.state.CmsData.sub_title
                  ? this.state.CmsData.sub_title
                  : "Browse Through our Latest News & Events"}
              </h5>
            </div>
          </div>
          <Box
            position="absolute"
            right={{ xs: "20px", sm: "30px", md: "50px" }}
            bottom={{ xs: "20px", sm: "30px", md: "50px" }}
          >
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="pb30" style={{ backgroundColor: "#f2f2f2", paddingTop: "80px" }}>
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <ul
                  className="news-navs nav nav-pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="pills-news-tab"
                      data-toggle="pill"
                      href="#pills-news"
                      role="tab"
                      aria-controls="pills-news"
                      aria-selected="true"
                    >
                      News
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="pills-events-tab"
                      data-toggle="pill"
                      href="#pills-events"
                      role="tab"
                      aria-controls="pills-events"
                      aria-selected="false"
                    >
                      Events
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="pills-videos-tab"
                      data-toggle="pill"
                      href="#pills-videos"
                      role="tab"
                      aria-controls="pills-videos"
                      aria-selected="false"
                    >
                      Videos
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-news"
                    role="tabpanel"
                    aria-labelledby="pills-news-tab"
                  >
                    <div className="row justify-content-center">
                      {!this.state.loading &&
                        <>
                          {this.state.news.length > 0 ?
                            this.state.news.map((item: Data) => (
                              <div className="col-sm-6 col-lg-4" key={item.id}>
                                <div className="news-events-card">
                                  <DateDiv>{item.attributes.posted_on}</DateDiv>
                                  <img src={item.attributes.image_or_video} />
                                  <div className="news-events-text">
                                    <h4>{item.attributes.title}</h4>
                                    <p>{item.attributes.description}</p>
                                    <button onClick={() => this.redirectToDetailPage(item.id, "news", this.state.news)}>Read More</button>
                                  </div>
                                </div>
                              </div>
                            ))
                            :
                            <div>
                              News not available!
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-events"
                    role="tabpanel"
                    aria-labelledby="pills-events-tab"
                  >
                    <div className="row justify-content-center">
                      {!this.state.loading &&
                        <>
                          {this.state.events.length > 0 ?
                            this.state.events.map((item: Data) => (
                              <div className="col-sm-6 col-lg-4" key={item.id}>
                                <div className="news-events-card">
                                  <DateDiv>{item.attributes.posted_on}</DateDiv>
                                  <img src={item.attributes.image_or_video} />
                                  <div className="news-events-text">
                                    <h4>{item.attributes.title}</h4>
                                    <p>{item.attributes.description}</p>
                                    <button onClick={() => this.redirectToDetailPage(item.id, "event", this.state.events)}>Read More</button>
                                  </div>
                                </div>
                              </div>
                            ))
                            :
                            <div>
                              Events not available!
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-videos"
                    role="tabpanel"
                    aria-labelledby="pills-videos-tab"
                  >
                    <div className="row justify-content-center">
                      {!this.state.loading &&
                        <>
                          {this.state.videos.length > 0 ?
                            this.state.videos.map((item: Data) => (
                              <div className="col-sm-6 col-lg-4" key={item.id}>
                                <div className="news-events-card video" onClick={() => this.redirectToDetailPage(item.id, "video", this.state.videos)}>
                                  <img src={videoImg} className="video-img" />
                                  <CardMedia
                                    component='video'
                                    image={item.attributes.image_or_video}
                                  />
                                </div>
                              </div>
                            ))
                            :
                            <div>
                              Videos not available!
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withRouter(NewsAndEvent);

const CommonWrapper = styled.main`
  .nav-item {
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  width: 80%;
  margin: auto;
`;

const DateDiv = styled.div`
  background-color: rgb(21, 24, 23);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 5px 20px;
  border-bottom-right-radius: 20px;
`;

// Customizable Area End
