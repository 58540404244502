import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  history: any;
  match: any;
}

export interface S {
  // Customizable Area Start
  detail:any;
  specialOfferArr:any[];
  offerDetail:any;
  loading:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SpecialOfferController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  specialOfferApiCallId:string = "";
  getDetailApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      detail:"",
      specialOfferArr:[],
      offerDetail:null,
      loading:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson && responseJson.data) {
        if (apiRequestCallId === this.specialOfferApiCallId) {
          this.setState({ specialOfferArr: responseJson.data, loading: false });
        } else if(apiRequestCallId === this.getDetailApiCallId){
          this.setState({ detail: responseJson?.data?.attributes });
        }
      } else {
        this.setState({loading: false});
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0,0);
    this.getDetails();
    this.getSpecialOffer();
  }

  onButtonClick = (data:any) => {
    this.props.history.push({
      pathname: "/SpecialOfferDetail",
      state: { data }
    });
  }

  getSpecialOffer = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentTypeApiSpecialOffer,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.specialOfferApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_special_offer/special_offers"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeSpecialOffer
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getDetails = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_dashboard/page_titles/:id?name=Special_offer_1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
