import React from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
// Customizable Area End

import NewsAndEventDetailController, {
  Props,
  configJSON,
} from "./NewsAndEventDetailController.web";

class NewsAndEventDetail extends NewsAndEventDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getCmsApi();
    window.scrollTo(0, 0);
    let state = this.props.location?.state;
    if (state) {
      this.setState({ data: state.data, currentIndex: state.id, type: state.type });
    }
    // Customizable Area End
  }

  onChange = (num: number) => {
    this.setState((prevState) => ({ currentIndex: prevState.currentIndex + num }));
  }

  goBack = () => {
    this.props.history.push("/NewsAndEvent");
  }
  // Customizable Area End

  render() {
    const { data, currentIndex } = this.state;

    return (
      // Customizable Area Start
      <CommonWrapper className="document common">
        <div
          className="first-section"
          style={{
            backgroundImage: this.state.CmsData?.image
              ? `url(${this.state.CmsData?.image})`
              : "url('assets/images/news_events.png')",
          }}
        >
          <div>
            <div className="content">
              <h2 className="title">
                {this.state.CmsData?.title
                  ? this.state.CmsData?.title
                  : "News and Events"}
              </h2>
              <h5 className="sub-title">
                {this.state.CmsData?.sub_title
                  ? this.state.CmsData?.sub_title
                  : "Browse Through our Latest News & Events"}
              </h5>
            </div>
          </div>
          <Box
            position="absolute"
            right={{ xs: "20px", sm: "30px", md: "50px" }}
            bottom={{ xs: "20px", sm: "30px", md: "50px" }}
          >
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="pb30" style={{ backgroundColor: "#f2f2f2", paddingTop: "80px" }}>
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>{data[currentIndex]?.attributes?.title}</h2>
                    <span>Posted: {data[currentIndex]?.attributes?.posted_on}</span>
                  </div>
                  <div className="light-btn">
                    <button onClick={this.goBack}> <MdKeyboardArrowLeft /> Go Back</button>
                  </div>
                </div>
                <div className="news-detail-banner mb30">
                  {this.state.type === "video"
                    ?
                    <video controls key={currentIndex}>
                      <source src={data[currentIndex]?.attributes?.image_or_video} />
                      Your browser does not support the video tag.
                    </video>
                    :
                    <img src={data[currentIndex]?.attributes?.image_or_video} alt="" />
                  }
                </div>
                <p className="pera-news-detail mb30">{data[currentIndex]?.attributes?.description}</p>
                <div className="stretch-box">
                  <div className="light-btn">
                    {currentIndex > 0 &&
                      <button onClick={() => this.onChange(-1)}> <MdKeyboardArrowLeft /> Previous</button>
                    }
                  </div>
                  <div className="light-btn">
                    {currentIndex < (data.length - 1) &&
                      <button onClick={() => this.onChange(+1)}> Next <MdKeyboardArrowRight /></button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withRouter(NewsAndEventDetail);

const CommonWrapper = styled.main`
`;

const Container = styled.div`
width: 80%;
margin: auto;
`;

// Customizable Area End
