import React, { Component } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import styled from "styled-components";
import { Button, Radio, RadioGroup, FormControlLabel, Link, Modal,Typography,Box,Backdrop,Grid } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withRouter } from "react-router";
import MockTestController, { Props } from "./MockTestController";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
import { Divider } from "@material-ui/core";
const  { baseURL } = require("../../../framework/src/config");
import moment from "moment";
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js"

// const stripePromis = loadStripe(
//     'pk_test_51KyFpyHdR4l61Wz0Chmlm4Q80ZG7tPcn7AU9OlFR3eG1MR0TotsDVtqUUEr3XZxMR2lE1vmApPCFGNTq9IP2dHqA00jJApUKvv'
// );

class MockTest extends MockTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {

    return (
      <CommonWrapper className="dashboard common">
        <div >
        <div className="first-section pb70">
          <div className="content">
            <h2 className="title">Mock Test</h2>
            <h5 className="sub-title">20 Questions</h5>
          </div>
        </div>
        </div>
        <section className="second-section mockTestSection">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="mainBlock">
                        <div className="blackBox">
                          <div>
                            <div>
                              <h2 className="title">20 Questions </h2>
                            </div>
                            <span className="DivderDiv">
                              <span>{moment().format("D MMM YYYY")}</span>
                              <Divider orientation="vertical" flexItem />
                              <span>{moment().format('LT')}</span>
                            </span>
                          </div>
                          <div>
                           {this.state.showResult === false ?  (<div className="timer">
                              {this.state.minutes < 10 ? `0 ${this.state.minutes}` : this.state.minutes} : 
                              {this.state.seconds < 10 ? `0 ${this.state.seconds}` : this.state.seconds} 
                            </div>) : null}
                          </div>
                        </div>
                        {this.state.showResult ? (
                            <div className="Que">
                            <h6>Question {parseInt(this.state.currentQue) + 1}</h6> 
                              <h5 style={{ fontWeight: "bold" }}>
                                {this?.state?.resultArr[this.state.currentQue]?.attributes?.question?.que_title}
                              </h5>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                style={{
                                  flexDirection:this.state?.questionsArr[this.state.currentQue]?.option?.map((option:any)=>
                                    option.que_option === ""  ? "row" : "unset")
                                }}
                              >
                                {this.state?.resultArr[this.state.currentQue]?.attributes?.question?.options?.map(
                                    (option:any)=>(
                                <FormControlLabel
                                    value={option.que_option}
                                    control={<Radio disabled checked={option.id === this.state?.resultArr[this.state.currentQue]?.attributes?.selected_option[0].id ? true : false}/>}
                                    label={option.que_option}
                                />
                             ))}
                                
                              </RadioGroup>
                              <Box className="ansBlock">
                                <Box className={this?.state?.resultArr[this.state.currentQue]?.attributes?.selected_option?.is_correct ? "greenBlock" : "redBlock"}>
                                {
                                  this?.state?.resultArr[this.state.currentQue]?.attributes?.selected_option?.is_correct ?
                                  
                                    ( <Typography variant="body1" className="green">
                                              Your Answer Is "Correct"
                                          </Typography>) :
                                     <Typography variant="body1" className="red">
                                      {`The Correct Answer Is "${this.state.correctAns}"`}
                                          </Typography>
                                }
                                <hr/>
                               <p> <strong>Reason -</strong></p>
                                {this?.state?.resultArr[this.state.currentQue]?.attributes?.correct_ans[0].reason}
                                </Box>
                              </Box>

                            </div>
                        ) :
                        (
                          <div className="Que">
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                        <h6>Question {this.state?.questionsArr[this.state.currentQue]?.index}</h6> 
                          <Link style={{color:"red",cursor:"pointer"}} onClick={()=>this.onSkipQuestion(this?.state?.questionsArr[this.state.currentQue])}>Review Later</Link>
                        </div>
                          <h5 style={{ fontWeight: "bold" }}>
                            {this?.state?.questionsArr[this.state.currentQue]?.que_title}
                          </h5>
                          {
                            this?.state?.questionsArr[this.state.currentQue]?.que_title_image ?
                            (<img src= {`${baseURL}${this?.state?.questionsArr[this.state.currentQue]?.que_title_image}`}
                            style={{height:"100px",width:"100px"}}/>) : null
                          }
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{
                              flexDirection:this.state?.questionsArr[this.state.currentQue]?.option?.map((option:any)=>
                              option.que_option_image !== null &&  option.que_option === ""  ? "row" : "unset")
                            }}
                          >
                            {this.state?.questionsArr[this.state.currentQue]?.options?.map(
                                (option:any)=>(
                                  option.que_option_image === null ?
                                  <>
                                    <FormControlLabel
                                        onClick={()=>this.selectAnswer(this.state?.questionsArr[this.state.currentQue],option)}
                                        value={option.que_option}
                                        control={<Radio />}
                                        label={option.que_option}
                                    />
                                  </>
                              :
                              <>
                                    <Box className="imgContainer" onClick={()=>this.selectAnswer(this.state?.questionsArr[this.state.currentQue],option)}>
                                          <Box className="imgOption">
                                            <input type="radio"/>
                                            <img src={`${baseURL}${option.que_option_image}`} height="100px" width="100px"/>
                                          </Box>
                                    </Box>
                               </>
                         ))}
                            
                          </RadioGroup>
                        </div>
                        )}

                        <div className="buttons">
                          {parseInt(this.state.currentQue) !== 0 && (<Button variant="outlined" className="btn" startIcon={<ArrowBackIcon/>} onClick={()=>this.handleClick("prev")}>Previous</Button>)}
                          {parseInt(this.state.currentQue) === parseInt(this.state.lastQue) ? 
                          <Button variant="outlined"  className="btn nextBtn" endIcon={<ArrowForwardIcon/>} onClick={() => this.setState({ open: true })}>Finish</Button> :
                          <Button variant="contained" className="btn nextBtn"  endIcon={<ArrowForwardIcon/>} onClick={()=>this.handleClick("next")}>Next</Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
         <Modal
          open={this.state.open}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          {
            !this.state.isTestCompleted ? (
              <Box textAlign="center" style={modalStyle} className="mockTestModal">
                 <h2  style={{ fontWeight: "bold"}}>End Test</h2>
                <Typography variant="body2" id="modal-description">
                  Are you sure you want to end the test? 
                </Typography>
                <Box mt={2} className="btnContainer">
                  <Button variant="outlined" onClick={() => this.setState({ open: false })}>Review</Button>
                  <Button variant="contained" className="btnOk" onClick={this.endTest}>End Test</Button>
                 </Box>
              </Box>
            ) :
            (
              <Box textAlign="center" style={modalStyle} className="mockTestModal">
                <Typography variant="body1" style={{ fontWeight: "bold"}} id="modal-description">
                  Congratulation!!
                </Typography>
                <Typography variant="body2" id="modal-description">
                  You have completed the Test 
                </Typography>
                <Box display="flex" justifyContent="center">
                  <Box className="chip green-chip"><span className="green">Correct</span>{` ${this.state.total_correct_ans}/${this.state.total_que_asked}`}</Box>
                  <Box className="chip red-chip"><span className="red">Wrong</span>{` ${this.state.total_wrong_ans}/${this.state.total_que_asked}`}</Box>
                </Box>
                <Box mt={2} className="btnContainer">
                  <Button variant="outlined"  onClick={() => this.showResult()}>View Result</Button>
                  <Button variant="contained" className="btnOk"  onClick={() =>
                      this.props.navigation.navigate("Dashboard")
                    }>Dashboard</Button>
                </Box>
            </Box>
            )
          }
        </Modal>
      </CommonWrapper>
    );
  }
}

export default MockTest;

const CommonWrapper = styled.main({
  "& .mainBlock": {
    borderRadius: "15px",
    marginBottom: "30px",
    padding: "20px 15px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 1px 0px #bbb6b7",
  },
  "& .FristDiv": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
  },
  "& .blackBox": {
    padding: " 30px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#161616",
    borderRadius: "15px",
    color: "#fff",
    marginBottom: "18px",
  },
  "& .Paid": {
    display: "flex",
    alignItems: "center",
    svg: {
      fontSize: "28px",
    },
  },
  "& .Divider": {
    height: "100%",
    width: "1px",
    minHeight: "10px",
    backgroundColor: "#fffcfc",
  },
  "& .PaidText": {
    paddingLeft: "5px",
  },
  "& .MuiDivider-root": {
    backgroundColor: "lightgrey",
  },
  "& .DivderDiv": {
    display: "flex",
    alignItems: "center",
    maxHeight: "12px",
    "& :first-child": {
      padding: "0 10px 0px 0",
    },
    "& :last-child": {
      padding: "0 0 0px 10px",
    },
    span: {
      fontSize: "14px",
      color: "lightgrey",
    },
  },
});
const BannerSection = styled.section`
  background-image: url("assets/special-offer.png");
  background-size: cover;
  width: 100%;
  min-height: 650px;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Container = styled.div`
  width: 80%;
  margin: auto;
  .title {
    font-size: 20px;
    color: white;
    font-weight: "500";
  }
  .PayBtn {
    width: 30%;
    background-color: #161616;
    border-radius: 25px;
    color: #fff;
    padding: 10px;
  }
  .PayBtn:hover {
    background-color: white;
    color: #161616;
    box-shadow: 0px 0px 0px 1px #161616;
    span {
      color: "#161616";
    }
  }
  @media only screen and (max-width: 430px) {
    .PayBtn {
      width: 100%;
    }
  }
`;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "24",
} as const;

const CustomButton = styled(Button)({
  backgroundColor: "black",
  color: "white",
  textTransform: "none",
  border: "1px solid black",
  borderRadius: "5px",
  width: "70px",
  "&:hover": {
    backgroundColor: "black",
  },
});

const CancelButton = styled(Button)({
  textTransform: "none",
  borderRadius: "5px",
  color: "black",
  border: "1px solid black",
  marginRight: "10px",
  width: "70px",
  "&:hover": {
    backgroundColor: "white",
  },
  "& span.MuiButton-label:hover": {
    color: "black",
  },
});




