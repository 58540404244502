import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Container,
    Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import { BsCheckCircleFill } from "react-icons/bs";
import Spinner from "../../../components/src/Spinner.web";
import TestDetailsController, { Props } from "./TestDetailsController.web";
const failed = require("../assets/failed.png");
// Customizable Area End

export default class TestDetails extends TestDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            //Merge Engine Start DefaultContainer
            <div className="dashboard common">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <div className="first-section">
                    <div className="content">
                        <h2 className="title">Test Details</h2>
                        <h5 className="sub-title">Reference site about Lorem Ipsum</h5>
                    </div>
                </div>
                <div className="second-section" style={{ backgroundColor: "#fff" }}>
                    <Box width="80%" margin="auto">
                        <Box width="100%">
                            <Grid container spacing={5} style={{ display: "flex", justifyContent: "center" }}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Box sx={webStyle.card}>
                                        <Box display="flex">
                                            <Box marginTop="6px"><BsCheckCircleFill style={{ height: "30px", width: "30px" }} /></Box>
                                            <Box ml={2}>
                                                <Box fontWeight="bold" fontSize="21px">Theory Test</Box>
                                                <Box mt="4px" mb="7px" fontSize="13px" color="#919191">Location: ECO Drive Qusais UAE</Box>
                                                <Box fontSize="13px">26 Feb 2022, Tuesday at 2:45 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box margin="25px 0px">
                                            <Box fontWeight="bold" mb="5px">Passed (3 attempts)</Box>
                                            <Box fontSize="13px" color="#809a9a">26 Feb 2022, Tuesday at 2:45 PM</Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><img src={failed} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 1 - Failed</Box>
                                                <Box fontSize="13px" color="#809a9a">21 Feb 2022, Tuesday | 3:40 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><img src={failed} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 2 - Failed</Box>
                                                <Box fontSize="13px" color="#809a9a">24 Feb 2022, Tuesday | 5:50 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><BsCheckCircleFill style={{ height: "18px", width: "18px" }} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 3 - Passed</Box>
                                                <Box fontSize="13px" color="#809a9a">26 Feb 2022, Tuesday | 2:42 PM</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Box sx={webStyle.card}>
                                        <Box display="flex">
                                            <Box marginTop="6px"><BsCheckCircleFill style={{ height: "30px", width: "30px" }} /></Box>
                                            <Box ml={2}>
                                                <Box fontWeight="bold" fontSize="21px">Road Assessment</Box>
                                                <Box mt="4px" mb="7px" fontSize="13px" color="#919191">Location: ECO Drive Qusais UAE</Box>
                                                <Box fontSize="13px">26 Feb 2022, Tuesday at 2:45 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box margin="25px 0px">
                                            <Box fontWeight="bold" mb="5px">Passed (2 attempts)</Box>
                                            <Box fontSize="13px" color="#809a9a">26 Feb 2022, Tuesday at 2:45 PM</Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><img src={failed} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 1 - Failed</Box>
                                                <Box fontSize="13px" color="#809a9a">21 Feb 2022, Tuesday | 3:40 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><BsCheckCircleFill style={{ height: "18px", width: "18px" }} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 2 - Passed</Box>
                                                <Box fontSize="13px" color="#809a9a">26 Feb 2022, Tuesday | 2:45 PM</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Box sx={webStyle.card}>
                                        <Box display="flex">
                                            <Box marginTop="6px"><BsCheckCircleFill style={{ height: "30px", width: "30px" }} /></Box>
                                            <Box ml={2}>
                                                <Box fontWeight="bold" fontSize="21px">Smart Yard Assessment</Box>
                                                <Box mt="4px" mb="7px" fontSize="13px" color="#919191">Location: ECO Drive Qusais UAE</Box>
                                                <Box fontSize="13px">26 Feb 2022, Tuesday at 2:45 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box margin="25px 0px">
                                            <Box fontWeight="bold" mb="5px">Passed (2 attempts)</Box>
                                            <Box fontSize="13px" color="#809a9a">10 Mar 2022, Tuesday at 4:00 PM</Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><img src={failed} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 1 - Failed</Box>
                                                <Box fontSize="13px" color="#809a9a">21 Feb 2022, Tuesday | 3:40 PM</Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" mb="15px">
                                            <Box><BsCheckCircleFill style={{ height: "18px", width: "18px" }} /></Box>
                                            <Box ml="10px">
                                                <Box fontWeight="bold" mb="5px">Attempt 2 - Passed</Box>
                                                <Box fontSize="13px" color="#809a9a">10 Mar 2022, Tuesday | 4:00 PM</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={5} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Box sx={webStyle.blackCard}>
                                        <Box>RTA Yard Test</Box>
                                        <WaitingButton>waiting</WaitingButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Box sx={webStyle.blackCard}>
                                        <Box>Road Test</Box>
                                        <WaitingButton>waiting</WaitingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </div>
            </div >
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const WaitingButton = styled(Button)({
    fontFamily: 'ITC Avant Garde Gothic Std',
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "28px",
    marginTop: "20px",
    fontSize: "18px",
    letterSpacing: "0px",
    width: "100%",
    "&:hover": {
        backgroundColor: "white"
    }
});

const webStyle = {
    card: {
        boxShadow: "0px 2px 4px 0px rgb(0 0 0 / 50%)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        borderRadius: "20px",
        padding: "25px 30px 15px 25px",
        backgroundColor: "#FFF",
        height: "100%"
    },
    blackCard: {
        backgroundColor: "black",
        color: "white",
        padding: "37px 30px 50px 30px",
        textAlign: "center",
        fontSize: "22px",
        borderRadius: "20px",
    }
}

// Customizable Area End
