import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, Box } from "@material-ui/core";
import { styled } from "@material-ui/styles";
const errorImg = require('../../../web/public/assets/ErrorImg.png');

export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  history: any;
  match: any;
}

class ErrorPage extends Component<Props> {
  constructor(props:Props) {
    super(props);
  }

  onButtonClick = () => {
    this.props.history.push("/");
  }

  render() {
    return (
      <Box p="15px" display="flex" justifyContent="center" textAlign="center">
        <Box maxWidth={{xs:"100%",sm:"500px", md:"992px", lg:"1500px"}}>
          <Box>
            <img src={errorImg} className="img-fluid" />
          </Box>
          <Box mt="25px" mb="10px">
            <CustomButton variant="contained" onClick={this.onButtonClick}>
              Back to Home
            </CustomButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withRouter(ErrorPage);

const CustomButton = styled(Button)({
  textTransform:"none",
  color: "white",
  padding:"10px 15px 8px 15px",
  backgroundColor: "#003e3b",
  "&:hover": {
    backgroundColor: "#003e3b",
  },
});
