import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { Console } from "console";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}


export interface S {
    // Customizable Area Start
    loading: boolean;
    data: string;
    documentArr: any[];
    questionsArr: any[];
    currentQue: any;
    lastQue: any;
    RemainingTime : String;
    minutes: number,
    seconds: number,
    interval: any,
    open: boolean,
    isTestCompleted: boolean,
    total_correct_ans: number,
    total_que_asked: number,
    total_wrong_ans: number,
    selectedOption: any,
    showResult: boolean,
    resultArr:any,
    correctAns:any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class MockTestController extends BlockComponent<Props, S, SS> {
    interval:any = null
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            data: "",
            documentArr: [],
            questionsArr: [],
            currentQue: 0,
            lastQue : "",
            RemainingTime : "15:00",
            minutes: 15,
            seconds: 0,
            interval: null,
            open: false,
            isTestCompleted: false,
            total_correct_ans: 0,
            total_que_asked: 0,
            total_wrong_ans: 0,
            selectedOption: {},
            showResult: false,
            resultArr: [],
            correctAns: "",

            // Customizable Area End
        };
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getQuestions();
        this.interval = setInterval(() => {
            this.Timer()
          }, 1000)
        // Customizable Area End
      }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.mockTestQuestionApiCallID) {
              console.log("responseJson",responseJson)
                this.setState({ questionsArr : responseJson?.questions?.data ,
                  minutes : responseJson?.timer?.minutes,
                  seconds : responseJson?.timer?.seconds
                });
                this.displayQuestions()
            }
        }
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.showResultApiCallID) {
                this.setState({ resultArr : responseJson?.data });
                console.log(this.state.resultArr)
                this.displayQuestions()
            }
        }
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.endTestApiCallID) {
                let res = responseJson?.result?.data?.attributes
               this.setState({
                total_que_asked : res?.total_que_asked,
                total_correct_ans : res?.total_correct_ans,
                total_wrong_ans : res?.total_wrong_ans,
               })
            }
            
        }
         else {
            this.parseApiErrorResponse(responseJson);
        }
        
       
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            // const apiRequestCallId = message.getData(
            //     getName(MessageEnum.RestAPIResponceDataMessage)
            // );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson && responseJson.data) {
                if (apiRequestCallId === this.paymentMethodApiCallId) {
                    window.open(responseJson.data.attributes.sand_box_url)
                }
                
            } else {
                this.parseApiErrorResponse(responseJson);
            }
            this.setState({ loading: false });
        }
        this.parseApiCatchErrorResponse(errorReponse);
        // Customizable Area End
    }

    // Customizable Area Start
    paymentMethodApiCallId: string = "";
    successPaymentAPiCallId: string = ""
    mockTestQuestionApiCallID: string = ""
    endTestApiCallID: string = ""
    submitAnswerApiCallID: string = ""
    showResultApiCallID: string = ""


    BtnClikc = async (data: string) => {
        if (this.state.data === "card") {
            this.getDocument()
        }
        else if (this.state.data === "center") {
            toast.success("Go To Nearest Eco Drive Center");
        }
        else {
            toast.error("Select Any One Payment Method");
        }
    }

    getDocument = () => {
        this.setState({ loading: true });
        const localToken = localStorage.getItem("authToken") as string
        const header = {
            "Content-Type": configJSON.contentTypeApiDocument,
            token: localToken
        };
        const amout = localStorage.getItem("amount") as any
        const id = localStorage.getItem("id") as any
        const httpBody = {
            "amount": amout,
            "course_id": id,
            "currency": "AED",
            "is_full_payment": true,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.paymentMethodApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_payments/payments"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    downloadDocument = (e: any, name: string, url: string) => {
        e.stopPropagation();
        let link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    apiCall = async (data: any) => {
        const token = localStorage.getItem("authToken") as string

        const { contentType, method, endPoint, body, type } = data
        const header = {
          'Content-Type': contentType,
          token: token
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        )
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        )
        body && type != 'formData' ?
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
    
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    console.log("requestMessage", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
      }
    
      getQuestions = async () => {
        // this.setState({isLoading:true})
        this.mockTestQuestionApiCallID = await this.apiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/bx_block_assessmenttest/questions",
        });
       
       }
       displayQuestions = ()=>{
        let questionArray = this.state.questionsArr.map((item: any, index: number) => {
            return{
                ...item.attributes,
                selectedOption: "",
                isSkipped: false,
                selectedOptionID: null,
                isAnswered: false,
                index: index + 1
              }
              })
              this.setState({questionsArr : questionArray})
 
       }
    
      endTest = async () => {
        this.setState({isTestCompleted:true})
        this.endTestApiCallID = await this.apiCall({
          contentType: "application/json",
          method: "POST",
          endPoint: "/bx_block_assessmenttest/end_test",
        });
       }

       onSkipQuestion = (que:any)=> {
            if(que?.isSkipped === false)
            {
                let newArr = this.state.questionsArr
                newArr.push(que)
                this.setState({ questionsArr: newArr });
                this.setState({ currentQue: parseInt(this.state.currentQue) + 1 })}
                const temp = this.state.questionsArr.map(obj => {
                    if (obj.index === que.index) {
                      return {...obj, isSkipped: true};
                    }
                    return obj;
                  })
                this.setState({questionsArr : temp})
       }
    
      selectAnswer = (que:any,option:any) =>{
        let temp = {...option , que_id : que.id}
        this.setState({selectedOption : temp})
      }
      
      submitAnswer = async (option:any) => {
        // this.setState({isTestCompleted:true})
        let skipped = option ? false : true
        let question_id = option?.que_id
        let option_id = option?.id
        this.submitAnswerApiCallID = await this.apiCall({
          contentType: "application/json",
          method: "POST",
          endPoint: `/bx_block_assessmenttest/correct_ans?question_id=${question_id}&option_id=${option_id}&skip=${skipped}`,
        });
       }

      handleClick= (btnValue:String) =>{
        if(this.state.showResult){
          this.setState({ lastQue : this.state.resultArr.length - 1});
          let temp = this.state.resultArr[this.state.currentQue].attributes.question.options.filter((option:any) => option.id === this.state.resultArr[this.state.currentQue].attributes.correct_ans[0].id)
          if(temp[0].que_option_image === null){
            this.setState({correctAns : temp[0].que_option})
          }
          else{
             this.setState({correctAns : temp[0].que_option_image})
          }
          if(btnValue === "prev"){
            let prevQue = parseInt(this.state.currentQue) - 1
            this.setState({ currentQue:prevQue });
          }
          if(btnValue === "next"){
            let nextQue = parseInt(this.state.currentQue) + 1
            this.setState({ currentQue: nextQue });
          }
        }
        else{
          this.setState({ lastQue : this.state.questionsArr.length - 1});
          if(this.state.selectedOption){
            this.submitAnswer(this.state.selectedOption)
            this.setState({ selectedOption:null });
          }
          if(btnValue === "prev"){
            let prevQue = parseInt(this.state.currentQue) - 1
            this.setState({ currentQue:prevQue });
          }
          if(btnValue === "next"){
            let nextQue = parseInt(this.state.currentQue) + 1
            this.setState({ currentQue: nextQue });
          }
        }
      }

      Timer = () => {
        let time = this.state.RemainingTime
        let minutes = this.state.minutes
        let seconds = this.state.seconds
    
        if (minutes <= 0 && seconds <= 1) {
            clearInterval(this.interval)
        }
        seconds = seconds - 1
    
        if (seconds <= 0 && minutes > 0) {
          minutes = minutes - 1
          seconds += 59
        }
        this.setState(
          {
            // RemainingTime: newTime
            minutes: minutes,
            seconds: seconds,
            RemainingTime: (minutes < 10 ? "0" + minutes.toString() : minutes.toString()) + ":" + (seconds < 10 ? "0" + seconds.toString() : seconds.toString())
          }
        )
    
      }

      showResult = async () => {
        this.setState({showResult:true})
        this.setState({open:false})
        this.setState({currentQue:0})
        
        this.showResultApiCallID = await this.apiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: "/bx_block_assessmenttest/get_correct_wrong_ans",
        });
       }
    
    // Customizable Area End
}