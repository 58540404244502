import React from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import moment from "moment";
import {
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
    Typography,
    Avatar,
    Modal,
    Backdrop,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DirectionsIcon from '@material-ui/icons/Directions';
import { RiCalendar2Line } from "react-icons/ri";
import MyBookController, { Props, TrainingData, TabPanelProps } from "./MyBookingsController.web";
import Spinner from "../../../components/src/Spinner.web";
const threeDot = require("../assets/three-dot.png");
// Customizable Area End

const TabPanel = (props: TabPanelProps) => {
    const { children, currentValue, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={currentValue !== id}
            id={`simple-tabpanel-${id}`}
            aria-labelledby={`simple-tab-${id}`}
            {...other}
        >
            {currentValue === id && (
                <div>{children}</div>
            )}
        </div>
    );
}

class MyBookings extends MyBookController {
    constructor(props: Props) {
        super(props);
        // Customizable Area 
        // Customizable Area End
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getTrainingStageDetail();
    }

    renderScheduleTabData = (trainingStage: TrainingData[]) => {
        if (trainingStage.length === 0) {
            return <div>No Record Found!</div>
        }
        return <Grid container spacing={5}>
            {trainingStage.map((item: TrainingData) => {
                return <Grid item xs={12} sm={6} lg={4} key={item.id}>
                    <div
                        className="tab-detail-items"
                        style={{ backgroundColor: item.isCurrent ? "white" : "#E7E7E7" }}
                    >
                        <Box padding="30px 41px 30px 25px" position="relative"
                            style={{ color: item.isCurrent ? "black" : "#757575" }}
                        >
                            {(item.isCompleted || item.isCurrent) &&
                                <StatusBox bgcolor="text.primary" color="white">
                                    {item.status}
                                </StatusBox>
                            }
                            <Box fontSize={{ xs: "17px", xl: "20px" }} height={{ xs: "58px", lg: "70px" }} fontWeight="700" marginBottom="10px">
                                {item.name}
                            </Box>
                            <Box fontSize={13} fontWeight="bold">
                                Suggested Hour: {item.suggested_hour} Hrs
                            </Box>
                        </Box>
                        <ScheduleButton
                            onClick={() => item.shouldPay ? toast.info("Coming Soon!") : this.redirectToSchedulePage(item.suggested_hour)}
                            disabled={item.status === "Not Started"}
                            style={{
                                backgroundColor: item.isCurrent ? "black" : "#757575",
                            }}
                        >
                            {item.shouldPay ? "Pay and Schedule" : "Schedule Now"}
                            <RiCalendar2Line style={{ marginLeft: "15px" }} />
                        </ScheduleButton>
                    </div>
                </Grid>
            })}
        </Grid>
    }

    renderUpcomingTabData = (upcomingScheduleData: any[]) => {
        if (upcomingScheduleData.length === 0) {
            return <div>No Record Found!</div>
        }

        return <Grid container spacing={5}>
            {upcomingScheduleData.map((item: any, index: number) => {
                const dates = item?.attributes.date;
                const date = moment(dates).format("DD");
                const month = moment(dates).format('MMMM');
                const year = moment(dates).format('YYYY');
                const day = moment(dates).format('dddd');
                const timeSlot = `${moment.utc(item?.attributes?.scheduled_start_time).format('HH:mm')} - ${moment.utc(item?.attributes?.scheduled_end_time).format('HH:mm')}`
                const instructorDetails = item?.attributes?.instructor?.data?.attributes;

                return <Grid item xs={12} sm={6} lg={4} key={index}>
                    <div className="tab-detail-items" onClick={() => this.setState({ cancelScheduleId: null })}>
                        <Box padding="15px 20px 30px 25px">
                            <Box display="flex" justifyContent="space-between">
                                <Box mr={2}>
                                    <Box fontSize="25px" fontWeight="bold">{date}</Box>
                                    <Box fontSize="13px" fontWeight="bold" mb="2px">{month} {year}, {day}</Box>
                                </Box>
                                <Box textAlign="right" position="relative" display="flex" flexDirection="column" justifyContent="space-between">
                                    <Box
                                        style={{ cursor: "pointer" }}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            this.setState((prev) => ({
                                                cancelScheduleId: prev.cancelScheduleId === item?.attributes?.id ? null : item?.attributes?.id
                                            }))
                                        }}
                                    >
                                        <img src={threeDot} alt="not-found!" />
                                    </Box>
                                    {this.state.cancelScheduleId === item?.attributes?.id &&
                                        <CancelButton
                                            onClick={(e:any) => {
                                                e.stopPropagation();
                                                this.setState({ openModal: true })
                                            }}
                                        >
                                            Cancel Schedule
                                        </CancelButton>
                                    }
                                    <Box fontSize="15px" fontWeight="bold" mr="2px">{timeSlot}</Box>
                                </Box>
                            </Box>
                            <Box mt="30px">
                                <Box fontSize="13px" fontWeight="bold" mb="10px">Current Stage:</Box>
                                <Box fontSize={{ xs: "17px", xl: "20px" }} height={{ xs: "58px", lg: "70px" }} fontWeight="700" marginBottom="10px">
                                    {item.attributes?.training_stage?.title}
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Avatar src={instructorDetails?.avatar_url} alt="profile" variant="square" style={{ height: "35px", width: "35px", borderRadius: "5px" }} />
                                        <Box ml="10px">
                                            <Box fontSize="15px" fontWeight="bold">Instructor: {instructorDetails?.full_name}</Box>
                                            <Box fontSize="12px">Location: {instructorDetails?.location_address}</Box>
                                        </Box>
                                    </Box>
                                    <Box style={{ cursor: "pointer" }} onClick={this.redirectToMapPage}>
                                        <DirectionsIcon />
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                            </Box>
                        </Box>
                    </div>
                </Grid>
            })}
        </Grid>
    }

    renderCompletedTabData = (completedScheduleData: any[]) => {
        if (completedScheduleData.length === 0) {
            return <div>No Record Found!</div>
        }

        return <Grid container spacing={5}>
            {completedScheduleData.map((item: any, index: number) => {
                const dates = item?.attributes.date;
                const date = moment(dates).format("DD");
                const month = moment(dates).format('MMMM');
                const year = moment(dates).format('YYYY');
                const day = moment(dates).format('dddd');
                const timeSlot = `${moment.utc(item?.attributes?.scheduled_start_time).format('HH:mm')} - ${moment.utc(item?.attributes?.scheduled_end_time).format('HH:mm')}`
                const instructorDetails = item?.attributes?.instructor?.data?.attributes;

                return <Grid item xs={12} sm={6} lg={4} key={index}>
                    <div className="tab-detail-items completed">
                        <Box padding="15px 41px 30px 25px">
                            <Box>
                                <StatusBox bgcolor="background.paper" color="black">
                                    {item?.attributes?.status ?
                                        item?.attributes?.status
                                        : "Completed"
                                    }
                                </StatusBox>
                                <Box display="flex" alignItems="center" mb="60px">
                                    <Box fontSize="45px" fontWeight="bold">{date}</Box>
                                    <Box ml="15px">
                                        <Box>{month} {year}, {day}</Box>
                                        <Box>{timeSlot}</Box>
                                    </Box>
                                </Box>
                                <Box fontSize={{ xs: "17px", xl: "20px" }} height={{ xs: "58px", lg: "70px" }} fontWeight="700">
                                    {item.attributes?.training_stage?.title}
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Avatar src={instructorDetails?.avatar_url} alt="profile" variant="square" style={{ height: "35px", width: "35px", borderRadius: "5px" }} />
                                        <Box ml="10px">
                                            <Box fontSize="15px" fontWeight="bold">Instructor: {instructorDetails?.full_name}</Box>
                                            <Box fontSize="12px">Location: {instructorDetails?.location_address}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </Grid>
            })}
        </Grid>
    }

    render() {
        const { activeTab, trainingStage, upcomingScheduleData, completedScheduleData } = this.state;
        return (
            //Merge Engine Start DefaultContainer
            <Box>
                <section className="bookings common">
                    {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                    <div className="first-section">
                        <div className="content">
                            <h2 className="title">My Bookings</h2>
                            <h5 className="sub-title">Reference site about Lorem Ipsum</h5>
                        </div>
                    </div>
                    <div className="second-section">
                        <Box width="80%" margin="auto">
                            <Box>
                                <Tabs
                                    aria-label="tabs"
                                    value={activeTab}
                                    TabIndicatorProps={{
                                        style: { display: 'none' }
                                    }}
                                    onChange={this.handleTabChange}
                                >
                                    <CustomTab value="schedule" label="Schedule" />
                                    <CustomTab value="upcoming" label="Upcoming" />
                                    <CustomTab value="completed" label="Completed" />
                                </Tabs>
                            </Box>
                            <Box width="100%" mt="40px">
                                <TabPanel currentValue={activeTab} id="schedule">
                                    {this.renderScheduleTabData(trainingStage)}
                                </TabPanel>

                                <TabPanel currentValue={activeTab} id="upcoming">
                                    {this.renderUpcomingTabData(upcomingScheduleData)}
                                </TabPanel>

                                <TabPanel currentValue={activeTab} id="completed">
                                    {this.renderCompletedTabData(completedScheduleData)}
                                </TabPanel>
                            </Box>
                        </Box>
                    </div>

                    <Modal
                        open={this.state.openModal}
                        onClose={() => this.setState({ openModal: false })}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                    >
                        <ModalWrapper>
                            <Box
                                id="modal-title"
                                fontWeight="bolder"
                                fontSize={{ xs: "20px", sm: "27px" }}
                            >
                                Are You Sure?
                            </Box>
                            <Typography variant="body2" id="modal-description">
                                This action cannot be undone once it is performed.
                            </Typography>
                            <Box mt={2}>
                                <ModalCancelButton onClick={() => this.setState({ openModal: false })}>
                                    Cancel
                                </ModalCancelButton>
                                <ModalConfirmButton
                                    onClick={() => this.onCancelScheduling()}
                                >
                                    Confirm
                                </ModalConfirmButton>
                            </Box>
                        </ModalWrapper>
                    </Modal>
                </section>
            </Box>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start

export default withRouter(MyBookings);

const StatusBox = styled(Box)({
    position: "absolute",
    bottom: "0px",
    top: "0px",
    right: "0px",
    marginTop: "auto",
    marginBottom: "auto",
    padding: "12px 6px",
    height: "fit-content",
    writingMode: "vertical-rl",
    transform: "rotate(-180deg)",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
});

const ScheduleButton = styled(Button)({
    fontFamily: 'ITC Avant Garde Gothic Std',
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    fontSize: "17px",
    fontWeight: "bold",
    borderRadius: "0px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    padding: "10px",
    "&:hover": {
        backgroundColor: "black",
    },
    "&:disabled": {
        color: "white"
    }
});

const CustomTab = styled(Tab)({
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "8px",
    padding: "10px 20px",
    minHeight: "fit-content",
    minWidth: "fit-content",
    '&.Mui-selected': {
        backgroundColor: "black",
        color: "white",
        border: "none"
    },
    "@media(max-width:576px)": {
        padding: "6px 10px",
    }
});

const CancelButton = styled(Button)({
    fontFamily: 'ITC Avant Garde Gothic Std',
    boxShadow: "0px 2px 4px 0px rgb(0 0 0 / 50%)",
    position: "absolute",
    top: "24px",
    right: "2px",
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    whiteSpace: "nowrap",
    padding: "6px 9px",
    fontSize: "13px",
    borderRadius: "5px",
    "&:hover": {
        backgroundColor: "black",
    },
});

const ModalWrapper = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    width: 400,
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "40px 15px",
    borderRadius: "10px",
    boxShadow: "24",
    "@media(max-width:576px)": {
        width: "85%",
        padding: "25px 15px",
    },
});

const ModalCancelButton = styled(Button)({
    fontFamily: 'ITC Avant Garde Gothic Std',
    textTransform: "none",
    borderRadius: "5px",
    color: "black",
    border: "1px solid black",
    marginRight: "10px",
    width: "70px",
    padding: "3px 8px",
    "&:hover": {
        backgroundColor: "white",
    },
    "& span.MuiButton-label:hover": {
        color: "black",
    },
});

const ModalConfirmButton = styled(Button)({
    fontFamily: 'ITC Avant Garde Gothic Std',
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
    border: "1px solid black",
    borderRadius: "5px",
    width: "70px",
    padding: "3px 8px",
    "&:hover": {
        backgroundColor: "black",
    },
});
// Customizable Area End
