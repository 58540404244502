import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}

export interface S {
    // Customizable Area Start
    detail: any;
    testDetail: any[];
    loading: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TestDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    testDetailApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            detail: "",
            testDetail: [],
            loading: false,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson && responseJson.data) {
                if (apiRequestCallId === this.testDetailApiCallId) {
                    this.setState({ testDetail:[] });
                }
            } else {
                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getTestDetail();
    }

    getTestDetail = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.testDetailApiContentType,
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.testDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.testDetailApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.testDetailApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Customizable Area End
}
