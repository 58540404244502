import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Avatar,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/styles";
import Spinner from "../../../components/src/Spinner.web";
import DashboardController, { Props } from "./DashboardController";
import Test from "./OnlineLecture.web";
const image = require("../assets/Group 237.png");
const profile = require("../assets/profile.png");
const { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    let pendingLecture = localStorage.getItem("pending_lecture");
    if (pendingLecture) {
      this.setState({ pendingLecture });
    }
    // Customizable Area End
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getDashboardData();
    this.getCourseStatus();
  }

  modalClose = () => {
    this.setState({ open: false });
  };

  render() {
    const optionArray = [
      {
        image: image,
        path: "/MockTest",
        title: "Mock test",
        subTitle: "Practice tests based on latest exam & syllabus",
      },
      {
        image: image,
        path: "/CustomerPayment",
        title: "Payment",
        subTitle: "You can pay for multiple stage",
      },
      {
        image: image,
        path: "/MyBookings",
        title: "Schedules",
        subTitle: "Custom training schedule",
      },
      {
        image: image,
        path: "/UserProfileBasicBlock",
        title: "My account",
        subTitle: "Manage your profile, sessions & history",
      },
      {
        image: image,
        path: "/TestDetails",
        title: "Test detail",
        subTitle: "View results for your all test",
      },
      {
        image: image,
        path: "/DocumentsRequired",
        title: "My documents",
        subTitle: "To upload document click here",
      },
    ];

    return (
      //Merge Engine Start DefaultContainer
      <div className="dashboard common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section pb70">
          <div className="content">
            <h2 className="title">Hello, {this.state.userName}!</h2>
            <h5 className="sub-title">Student</h5>
          </div>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%">
              <Grid
                container
                spacing={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "50px",
                }}
              >
                <Grid item xs={12} sm={6} lg={4}>
                  <Box style={profileBox}>
                    <Avatar
                      src={
                        this.state.userPicture
                          ? baseURL + this.state.userPicture
                          : profile
                      }
                      alt="profile"
                      variant="square"
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "15px 15px 0px 0px",
                      }}
                    />
                    <Box padding="30px 30px 15px 30px">
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bold", marginBottom: "20px" }}
                      >
                        Hello, {this.state.userName}!
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        marginBottom="30px"
                      >
                        <Box>
                          <Typography style={{ fontWeight: "bold" }}>
                            LPN:
                          </Typography>
                          <Typography>{this.state.LPNNumber}</Typography>
                        </Box>
                        <Box textAlign="right">
                          <Typography style={{ fontWeight: "bold" }}>
                            Category:
                          </Typography>
                          <Typography>{this.state.category}</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Box>
                          <Typography style={{ fontWeight: "bold" }}>
                            Course type:
                          </Typography>
                          <Typography>{this.state.classType}</Typography>
                        </Box>
                        <Box textAlign="right">
                          <Typography style={{ fontWeight: "bold" }}>
                            Practical training:
                          </Typography>
                          <Typography>{this.state.hours} hours</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Box style={onlineLectureBox} className="lecture-img">
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      Online lectures
                    </Typography>
                    <Typography style={{ margin: "5px 0px" }}>
                      Live video session takes a turn
                    </Typography>
                    {this.state.isOnlineLecturesCompleted && (
                      <Typography
                        style={{ margin: "5px 0px", fontWeight: "bold" }}
                      >
                        You completed online lectures
                      </Typography>
                    )}
                    <CustomButton
                      onClick={() =>
                        this.props.navigation.navigate("OnlineLecture")
                      }
                    >
                      Join
                    </CustomButton>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Link to="#" className="item-link">
                    <Box style={theoryLectureBox}>
                      <Box style={theoryLectureContent}>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "bold", fontSize: "17px" }}
                        >
                          My theory lectures:
                          {this.state.theoryLecture !== "optional"
                            ? this.state.theoryLecture
                            : `${this.state.pendingLecture} / ${this.state.theoryLecture}`
                          }
                        </Typography>
                        {!this.state.isOnlineLecturesCompleted &&
                          <Box m="20px 0px" borderRadius="8px" padding="10px" bgcolor="background.paper" color="black">
                            *Note: To continue with theory lectures, kindly finish the
                            online courses.
                          </Box>
                        }
                        <Box mb={2} display="flex" justifyContent="space-between">
                          <Box mr={1}>
                            <Typography>Theory lectures</Typography>
                            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                              {this.state.theoryLecture === "optional"
                                ? "Optional"
                                : this.state.theoryLecture
                              }
                            </Typography>
                          </Box>
                          <Box textAlign="right">
                            <Typography>Yard test</Typography>
                            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                              {this.state.yardTest === "NO"
                                ? "No"
                                : this.state.yardTest
                              }
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box mr={1}>
                            <Typography>Knowledge test</Typography>
                            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                              {this.state.knowledgeTest === "NO"
                                ? "No"
                                : this.state.knowledgeTest
                              }
                            </Typography>
                          </Box>
                          <Box textAlign="right">
                            <Typography>Road test</Typography>
                            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                              {this.state.roadTest === "NO"
                                ? "No"
                                : this.state.roadTest
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {optionArray.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                      <Link to={{ pathname: item.path, state: { name: this.state.userName, quantity:this.state.quantity, courseName: this.state.classType } }} className="item-link">
                        <Box style={itemBox}>
                          <Avatar
                            src={item.image}
                            alt="notfound"
                            variant="square"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginBottom: "30px",
                            }}
                          />
                          <Typography
                            variant="h5"
                            style={{
                              fontWeight: "bold",
                              marginBottom: "12px",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Box component="p" style={subTitle}>
                            {item.subTitle}
                          </Box>
                        </Box>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </div>
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <Box textAlign="center" style={modalStyle}>
            <Typography
              id="modal-title"
              variant="h5"
              style={{ fontWeight: "bold", fontSize: "27px" }}
            >
              Let's get you verified
            </Typography>
            <Typography variant="body2" id="modal-description">
              we help you to complete the verification
            </Typography>
            <Box mt={2}>
              <CancelButton onClick={() => this.setState({ open: false })}>
                Cancel
              </CancelButton>
              <CustomButton
                onClick={() =>
                  this.props.navigation.navigate("OnlineLectureOTP")
                }
              >
                Ok
              </CustomButton>
            </Box>
          </Box>
        </Modal>
        {/* <Test /> */}
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const CustomButton = styled(Button)({
  fontFamily: 'ITC Avant Garde Gothic Std',
  backgroundColor: "black",
  color: "white",
  textTransform: "none",
  border: "1px solid black",
  borderRadius: "5px",
  width: "70px",
  "&:hover": {
    backgroundColor: "black",
  },
});

const CancelButton = styled(Button)({
  fontFamily: 'ITC Avant Garde Gothic Std',
  textTransform: "none",
  borderRadius: "5px",
  color: "black",
  border: "1px solid black",
  marginRight: "10px",
  width: "70px",
  "&:hover": {
    backgroundColor: "white",
  },
  "& span.MuiButton-label:hover": {
    color: "black",
  },
});

const itemBox = {
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  borderRadius: "15px",
  padding: "35px 21px 0px",
  backgroundColor: "#FFF",
  display: "flex",
  flexDirection: "column",
} as const;

const profileBox = {
  ...itemBox,
  padding: "0px",
} as const;

const onlineLectureBox = {
  ...itemBox,
  backgroundImage: "url('../assets/images/lecture.png')",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "30px",
  height: "100%",
} as const;

const subTitle = {
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  height: "48px",
  textOverflow: "ellipsis",
} as const;

const theoryLectureBox = {
  ...itemBox,
  padding: "20px 30px",
  backgroundColor: "black",
  color: "white",
  height: "100%",
} as const;

const theoryLectureContent = {
  borderRadius: "10px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
} as const;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  padding: "30px 10px",
  borderRadius: "10px",
  boxShadow: "24",
} as const;

// Customizable Area End
