import React from "react";
// Customizable Area Start
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import Select from "react-select";
import HomeController, { Props } from "./HomeController.web";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
import Spinner from "../../../components/src/Spinner.web";
export const baseurl = require('../../../../packages/framework/src/config');
export const configJSONUrl = require("../../../framework/src/config");
// Customizable Area End


class Home extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchSpecialOffers();
    this.fetchVehiclesData();
    this.getCmsApi();
    this.getCmsApiDoorStep();
  }

  fetchSpecialOffers = () => {
    this.setState({ isLoading: true });
    fetch(configJSONUrl.baseURL + "/bx_block_special_offer/special_offers")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong, Please try again');
      })
      .then((json) => {
        if (json.data.length === 0) {
          this.setState({
            isSpecialOffers: false,
          });
        }

        this.setState({
          specialOffers: json.data,
          isLoading: false
        });
      }).catch((error) => {
        this.setState({
          err2: error,
          isLoading: false
        })
      })
  };

  fetchVehiclesData = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
      }
    }

    fetch(configJSONUrl.baseURL + "/vehicle_types", requestOptions)
      .then((res => {

        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong, Please try again.');
      }))
      .then((json) => {
        this.setState({
          vehicleTypes: json.data,
          isLoading: false
        })

      }).catch((error) => {
        this.setState({
          err: error,
          isLoading: false
        })
      })
  }

  handleChange = (event: any) => {
    let val = event.target.value.replace(/[^0-9]+/g, "");
    this.setState({ phoneNumber: val });

    let length = val.split('').length
    if (length === 9 || length === 10) {
      this.setState({ enableSave: true })
    }
    else {
      this.setState({ enableSave: false })
    }
  };

  navigate = (name: string, id: number) => {
    this.props.history.push({
      pathname: '/CourseDetail',
      state: { name, id }
    })
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    let { err, isLoading } = this.state;
    let countryCode = [
      { label: "+971", value: "+971" },
      { label: "+91", value: "+91" },
    ];

    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <CommonWrapper className="home common">
        <div className="first-section" style={{ backgroundImage: this.state.CmsData.image ? `url(${this.state.CmsData.image})` : `url("assets/images/home-bg.png")` }}>
          <div className="content">
            <h2 className="title">{this.state.CmsData.title ?? " Learn to Drive with Confidence"}</h2>
            <h5 className="sub-title">{this.state.CmsData.sub_title ?? "Eco drive - The driving institute."}</h5>
          </div>
          <WhatsappIcon />
        </div>
        <section className="mt65">
          <Container>
            {isLoading && <Spinner spinnerModal={isLoading} />}
            {err ? <h1 style={{ textAlign: "center", fontSize: "22px" }}>{err.message}</h1> :
              <div className="row" >
                <div className="col-sm-12">
                  <div className="vehicle-cards-wrapper">
                    {this.state.vehicleTypes?.map((data: any, index: any) => (
                      <div className="vehicle-cards" key={index} style={{ backgroundImage: `url(${baseurl.baseURL + data?.attributes?.vehicle_type_background_image})` }}>
                        <div className="vehicle-contents">
                          <img className="" src={baseurl.baseURL + data?.attributes?.vehicle_type_icon} alt="" />
                          <h4 style={{ color: "#FFF" }}>{data?.attributes?.name}</h4>
                          <button className="vehicalBtn" style={{ background: "#FFFFFF", color: "rgb(2, 2, 2)" }} onClick={() => this.navigate(data?.attributes?.name, data?.id)}>LEARN MORE</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          </Container>
        </section>
        <section className="mt65">
          <Container className="bg-white">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <img
                  className="register-image img-fluid"
                  src={this.state.CmsDataDoorStep.image ? this.state.CmsDataDoorStep.image : "assets/images/register.png"}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-md-6 m-auto text-center">
                <div className="register-wrapper">
                  <Select
                    classNamePrefix="react-select"
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        width: "68px !important",
                        height: "43px",
                        borderBottom: "2px solid black !important"
                      }),
                    }}
                    isSearchable={false}
                    options={countryCode}
                    value={
                      countryCode.filter(
                        (option: any) =>
                          option.value === this.state.countryCodeWeb
                      )[0]
                    }
                    onChange={(event: any) => {
                      this.setState({ countryCodeWeb: event.value })
                    }}
                  />
                  <div>
                    <input type="text" value={this.state.phoneNumber} onChange={this.handleChange} maxLength={10} placeholder="Phone Number*" style={{ height: "100%" }} />
                  </div>
                </div>

                <Link to={{
                  pathname: "/Appointments",
                  state: { code: this.state.countryCodeWeb, phoneNumber: this.state.phoneNumber }
                }}><button style={{ opacity: this.state.enableSave ? '1' : '0.5' }} className="register-wrapper-btn" disabled={this.state.enableSave ? false : true}>  Register at Your Doorstep</button></Link>
              </div>
            </div>
          </Container>
        </section>
        <section className="mt65">
          <div className="commonHead">
            <h3>SPECIAL OFFERS</h3>
          </div>
          {this.state.isSpecialOffers ?
            <Container>
              {this.state.isLoading && <Spinner spinnerModal={this.state.isLoading} />}
              {this.state.err2 ? <h1 style={{ textAlign: "center", fontSize: "20px" }}>{err.message}</h1> :

                <div className="row">
                  {this.state.specialOffers.map((item: any, index: any) => (
                    <div className="col-sm-6 col-lg-4" key={index}>
                      <Link className="special-offer-link" to={{ pathname: `/SpecialOfferDetail`, state: { data: item.attributes } }}>
                        <div className="special-offers-card">
                          <img src={baseurl.baseURL + item.attributes.special_offer_image} alt="" />
                          <span>Valid Until {moment(item.attributes.valid_upto).format("MMMM DD")}</span>
                          <div className="offer-titles-card">
                            <h4>{item.attributes.title} </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              }
            </Container>
            : <h1 style={{ textAlign: "center", fontSize: "18px" }}>No Special Offer Found!</h1>
          }
        </section>
        <section className="mt65">
          <div className="commonHead">
            <h3>WHY CHOOSE ECO DRIVE?</h3>
          </div>
          <div className="imageBg">
            <img src="assets/images/whyEcoDrive.png" alt="" />
          </div>
        </section>
      </CommonWrapper>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

export default withRouter(Home);

const CommonWrapper = styled.main``;

const Container = styled.div`
  width: 80%;
  margin: auto;
  .vehicalBtn{
    font-size: 13px;
    font-weight: bold;
    background: #151817;
    text-decoration: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
  }
`;
// Customizable Area End
