import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import { withRouter } from "react-router";
import styled from "styled-components";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End

import AllCoursesController, {
  Props, Course
} from "./AllCoursesController.web";

class AllCourses extends AllCoursesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.getCmsApi();
    this.getCoursesDetail();
    // Customizable Area End
  }

  onNavigate = (name: string) => {
    this.props.history.push({
      pathname: '/CourseDetail',
      state: { name }
    })
  }

  onNavigateToRegister = () => {
    this.props.history.push("/EmailAccountRegistration");
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <CommonWrapper className="allCourse common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section" style={{ backgroundImage: this.state.CmsData?.image ? `url(${this.state.CmsData.image})` : `url('assets/images/allcourses.png')` }}>
          <div className="content">
            <h2 className="title">{this.state.CmsData?.heading ?? "All Courses"}</h2>
            <h5 className="sub-title">{this.state.CmsData?.sub_heading ?? "Detailed information of motor car driving course"}</h5>
          </div>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="second-section">
          <Container>
            {this.state.coursesDetail &&
              Object.entries(this.state.coursesDetail).map(([key, item]) => {
                return <div className="row" key={key}>
                  <div className="col-sm-12">
                    <div className="stretch-box mb30">
                      <div className="news-detail-box">
                        <h2>{item.vehicle_type} Course</h2>
                      </div>
                      <div className="light-btn">
                        <button className="more-detail-btn" onClick={() => this.onNavigate(item.vehicle_type)}>More Details</button>
                      </div>
                    </div>
                    {item.description.map((description: string, index: number) => {
                      return <p className="pera-news-detail mb30" key={index}>{description}</p>
                    })}
                  </div>
                  {item.courses.map((course: Course, index: number) => {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
                        <div className="all-courses-cards">
                          <div className="course-rating-star">
                            <AiOutlineStar />
                          </div>
                          <div className="all-courses-header">
                            <h3>{course.course_name}</h3>
                            <p>AED {course.total_fees}</p>
                            <span>(5% VAT applicable on Excellence Fees)</span>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Training Days * </h5>
                              <span>{course.training_days}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Training Hours per week *</h5>
                              <span>{course.training_hours}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Cancellation/Re-scheduling</h5>
                              <span>{course.cancel_hours}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Class Timings</h5>
                              <span>{course.class_timings}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Training fees per hour</h5>
                              <span>{course.training_fees_per_hour}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Fee Details</h5>
                              <div>RTA Fees - {course.rta_fees}</div>
                              <div>EDC Fees - {course.edc_fees} + 5% VAT</div>
                              <div>Total Fees - {course.total_fees}</div>
                            </div>
                          </div>
                          <button onClick={this.onNavigateToRegister}>Choose {course.course_name}</button>
                        </div>
                      </div>
                    )
                  }
                  )}
                </div>
              })}
          </Container>
        </section>
      </CommonWrapper>
      //Merge Engine End DefaultContainer
    );
  }
}

export default withRouter(AllCourses);
// Customizable Area Start

const CommonWrapper = styled.main``;

const Container = styled.div`
  width: 80%;
  margin: auto;
`;

// Customizable Area End
