import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export const baseurl = require('../../../../packages/framework/src/config');

export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  history: any;
  match: any;
}

export interface Data {
  id: number;
  type: string;
  attributes: {
    title: string;
    description: string;
    doc_type: string;
    image_or_video: string;
    posted_on: string;
  }
}

interface S {
  // Customizable Area Start
  CmsData: any;
  currentIndex: number;
  type: string;
  data: Data[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoyaltySystem2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      CmsData: "",
      data: [],
      currentIndex: 0,
      type: "news"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson && responseJson.data) {
      }
    }
    // Customizable Area End
  }

  getCmsApi() {
    const fetchUrl: string = `${baseurl.baseURL}/bx_block_dashboard/page_titles/:id?name=News`
    fetch(`${fetchUrl}`)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          CmsData: ResponseJson.data.attributes
        })
      }).catch((error) =>
        console.log(error, "News page")
      );
  }
  // Customizable Area End
}
