import React from 'react';
import moment from "moment";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { withRouter } from 'react-router';
import ReviewPaymentController, { Props } from './ReviewPaymentController.web';
import Spinner from "../../../components/src/Spinner.web";

class ReviewPayment extends ReviewPaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <CommonWrapper className="dashboard common">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <div className="first-section" style={{ backgroundImage: this.state.cmsDetail?.image ? `url(${this.state.cmsDetail?.image})` : `url('/assets/images/vehicle_detail.png')` }}>
                    <div>
                        <div className="content">
                        <h2 className="title">{this.state.cmsDetail?.title ?? "Review"}</h2>
                            <h5 className="sub-title">Student</h5>
                        </div>
                    </div>
                </div>
                <section className="second-section" style={{ backgroundColor: "#FFF" }}>
                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading">
                                    <h2>{this.state.title}</h2>
                                    <span>{this.state.subTitle}</span>
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                <div className='item-detail'>
                                                    <h5>Transaction type:</h5>
                                                    <h5>{localStorage.getItem("paidBy") ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Amount paid:</h5>
                                                    <h5>AED {this.state.totalAmount.toFixed(2) ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Licence type:</h5>
                                                    <h5>{localStorage.getItem("licenceType") ?? JSON.parse(localStorage.getItem("sendItem") as any)?.licence_type}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Course type:</h5>
                                                    <h5>{this.state.courseType ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Date &#38; time:</h5>
                                                    <h5>
                                                        {moment().format("DD-MMM-YYYY h:mm A")}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                <div className='item-detail'>
                                                    <h5>S.no</h5>
                                                    <h5>01</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Stage</h5>
                                                    <h5>Registration</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Qty</h5>
                                                    <h5>{this.state.quantity ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Price</h5>
                                                    <h5>AED {this.state.totalAmount.toFixed(2) ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Amount</h5>
                                                    <h5>AED {this.state.totalAmount.toFixed(2) ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Discount</h5>
                                                    <h5>{this.state.discount.toFixed(2) ?? "0.00"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Net amount</h5>
                                                    <h5>{this.state.netAmount.toFixed(2) ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Vat @ 5%</h5>
                                                    <h5>{this.state.vatAmount.toFixed(2) ?? "-"}</h5>
                                                </div>
                                            </div>
                                            <div className='BtnContainer'>
                                                <button
                                                    className="PayBtn"
                                                    onClick={() => this.redirectToPaymentGetaway()}
                                                >
                                                    Confirm and pay
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </CommonWrapper>
        )
    }
}

export default withRouter(ReviewPayment);

const CommonWrapper = styled.main({
    '& .heading': {
        marginBottom: "45px",
        '& h2': {
            fontWeight: "600",
            fontSize: "22px",
        },
        '& span': {
            fontSize: "15px",
            fontWeight: "400"
        },
    },
    '& .mainBlock': {
        overflow: "hidden",
        borderRadius: '15px',
        color: '#fff',
        border: "2px solid #C7C7C7",
        'div:nth-child(even)': {
            background: '#F1F1F1',
            borderRadius: "5px"
        }
    },
    '& .BtnContainer': {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
    '& .item-detail': {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        padding: '10px 35px',
        h5: {
            color: "grey",
            fontSize: "14px",
            marginBottom: 0
        },
        'h5:nth-child(2)': {
            textAlign: "right",
        },
        'h5:nth-child(even)': {
            fontWeight: "600",
            color: "black"
        },
        '@media only screen and (max-width: 992px)': {
            padding: '10px 20px',
        },
        '@media only screen and (max-width: 576px)': {
            padding: '10px 20px',
        }
    },
    '@media only screen and (max-width: 576px)': {
        '& .col-sm-6': {
            paddingBottom: '20px !important'
        }
    },
    '& .blackBox': {
        padding: '20px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        background: '#161616',
        borderRadius: '15px',
        color: '#fff',
        marginBottom: '18px',
    },
    '& .Paid': {
        display: 'flex',
        alignItems: "center"
    },
    '& .PaidText': {
        paddingLeft: "5px"
    }
});

const Container = styled.div`
width: 80%;
margin: auto;
.title{
    font-size: 16px;
    color:white;
    font-weight: "500";
}
.PayBtn{
    width: 60%;
    font-weight: bold;
    background-color: #161616;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
    border: 0px;
}
.OkBtn{
    background-color: #161616 !important;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
}
@media only screen and (max-width: 992px) {
  .PayBtn{
      width: 100%;
  }
}
`;
