import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    cmsDetail: any;
    title: string;
    subTitle: string;
    courseType: string;
    netAmount: number;
    vatAmount: number;
    discount: number;
    quantity: number;
    totalAmount: number;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ReviewPaymentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    paymentApiCallID: string = "";
    getCmsDetailApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            cmsDetail: null,
            title: "",
            subTitle: "",
            courseType: "",
            netAmount: 0,
            vatAmount: 0,
            discount: 0,
            quantity: 0,
            totalAmount: 0,
            // Customizable Area End
        };
    }

    async componentDidMount() {
        // Customizable Area Start
        window.scrollTo(0, 0);
        this.getCmsDetails();

        const data = this.props.location.state;
        if (data) {
            this.setState({
                title: data.title,
                subTitle: data.subTitle,
                courseType: data.courseType,
                netAmount: data.netAmount,
                vatAmount: data.vatAmount,
                discount: data.discount,
                quantity: data.quantity,
                totalAmount: data.totalAmount
            });
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.paymentApiCallID) {
                    if (responseJson.status === 500) {
                        toast.error("Something went wrong!");
                    } else if (responseJson.data) {
                        this.handlePaymentApiSuccessResponse(responseJson.data);
                    }
                } else if (apiRequestCallId === this.getCmsDetailApiCallID) {
                    this.setState({ cmsDetail: responseJson.data?.attributes });
                }
            }
            this.setState({ loading: false });
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handlePaymentApiSuccessResponse = (data: any) => {
        localStorage.setItem("discount", this.state.discount.toString());
        localStorage.setItem("count", this.state.quantity.toString());
        localStorage.setItem("name", this.state.courseType);
        localStorage.setItem("net", this.state.netAmount.toString());
        localStorage.setItem("vat", this.state.vatAmount.toString());
        localStorage.setItem("amount", this.state.totalAmount.toString());

        window.open(data.attributes?.sand_box_url, "_self");
    }

    redirectToPaymentGetaway = async () => {
        this.setState({ loading: true });

        const id = localStorage.getItem("id");

        const header = {
            "Content-Type": configJSON.paymentContentType,
            token: localStorage.getItem("authToken")
        };

        const httpBody = {
            "course_id": id,
            "paid_by": "card",
            "currency": "AED",
            "is_full_payment": false,
            "quantity": this.state.quantity,
            "amount": this.state.totalAmount,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.paymentApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.paymentApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.paymentApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getCmsDetails = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.reviewCmsApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCmsDetailApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reviewCmsApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.reviewCmsApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}