import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SiTwitter } from "react-icons/si";
import { SiInstagram } from "react-icons/si";
import { SiFacebook } from "react-icons/si";
import { SiLinkedin } from "react-icons/si";
import { MdPhoneInTalk } from "react-icons/md";
import { GrChatOption } from "react-icons/gr";
import { AiOutlineMenu } from "react-icons/ai";
import { VscChromeClose } from "react-icons/vsc";
import moment from "moment";
import { CgClose } from "react-icons/cg";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  Badge,
  Avatar,
  MenuItem,
  Menu,
  ListItemIcon,
  Divider,
  Tooltip,
  MenuList,
} from "@material-ui/core";
import RightNabar from "./RightNav";

function TopNav() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const isLogin = localStorage.getItem("isLogin") === "true";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fullName = localStorage.getItem("fullName");

  return (
    <>
      <HeaderWrapper className="header-wrapper">
        <HeaderTop>
          <div className="custom-container">
            <div className="row">
              <div className="col-12">
                <div className="headertop">
                  <div className="left-content">
                    <ul className="nav">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiTwitter />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiLinkedin />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiFacebook />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiInstagram />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="right-content">
                    <ul className="nav">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <GrChatOption />
                          <span className="text">LIVE CHAT</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <MdPhoneInTalk />
                          <span className="text">600 595 959</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderTop>
        <HeaderBottom>
          <div className="custom-container">
            <div className="row">
              <div className="col-12">
                <div className="bottomHeader">
                  <div className="bottomHeaderLeft">
                    <div className="center">
                      <Link to="/" className="no-underline">
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                          className="logoSection"
                        >
                          <img
                            src="assets/logo/logo.svg"
                            className="Ecologo"
                            alt=""
                          />
                          {/* <h4>eco drive</h4> */}
                        </div>
                      </Link>
                      <div className="navs">
                        <ul style={{ marginLeft: isLogin ? "20px" : "0px" }}>
                          <li>
                            <Link to="/AllCourses">All courses</Link>
                          </li>
                          <li style={{ marginLeft: isLogin ? "20px" : "0px" }}>
                            <Link to="/EmailAccountLoginBlock">
                              Student Login
                            </Link>
                          </li>
                          {!isLogin && (
                            <li>
                              <Link to="/SpecialOffer">Special Offers</Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {isLogin && (
                      <div className="center">
                        <div className="badgeAndIcon">
                          <div className="listCOntainer">
                            <NotificationsStyle>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                className="listCOntainer"
                                open={open}
                                onClose={handleClose}
                                noStyle
                                style={{
                                  width: "600px",
                                  top: "20px",
                                }}
                                PaperProps={{
                                  elevation: 4,
                                  sx: {
                                    overflow: "hidden",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <NotificationsStyle>
                                  <MenuList>
                                    <MenuItem className="menuitemNotification">
                                      <div className="notificationContainer">
                                        <div className="dateAndCloseIcons">
                                          <div className="day">today</div>
                                          <div
                                            className="closeIcon"
                                            onClick={handleClose}
                                          >
                                            <CgClose />
                                          </div>
                                        </div>
                                        <div className="NotificationDetails">
                                          <div className="nameAndTimeContainer">
                                            <div className="IconAndName">
                                              <div className="blackBox" />
                                              <div className="name">name</div>
                                            </div>
                                            <div className="Time">
                                              {moment(new Date()).format(
                                                "h:mm a"
                                              )}
                                            </div>
                                          </div>
                                          <div className="message">
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore lorem lorem lorem lore
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem className="menuitemNotification">
                                      <div className="notificationContainer">
                                        <div className="dateAndCloseIcons">
                                          <div className="day">today</div>
                                          <div
                                            className="closeIcon"
                                            onClick={handleClose}
                                          >
                                            <CgClose />
                                          </div>
                                        </div>
                                        <div className="NotificationDetails">
                                          <div className="nameAndTimeContainer">
                                            <div className="IconAndName">
                                              <div className="blackBox" />
                                              <div className="name">name</div>
                                            </div>
                                            <div className="Time">
                                              {moment(new Date()).format(
                                                "h:mm a"
                                              )}
                                            </div>
                                          </div>
                                          <div className="message">
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore lorem lorem lorem lore
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem className="menuitemNotification">
                                      <div className="notificationContainer">
                                        <div className="dateAndCloseIcons">
                                          <div className="day">today</div>
                                          <div
                                            className="closeIcon"
                                            onClick={handleClose}
                                          >
                                            <CgClose />
                                          </div>
                                        </div>
                                        <div className="NotificationDetails">
                                          <div className="nameAndTimeContainer">
                                            <div className="IconAndName">
                                              <div className="blackBox" />
                                              <div className="name">name</div>
                                            </div>
                                            <div className="Time">
                                              {moment(new Date()).format(
                                                "h:mm a"
                                              )}
                                            </div>
                                          </div>
                                          <div className="message">
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore lorem lorem lorem lore
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem className="menuitemNotification">
                                      <div className="notificationContainer">
                                        <div className="dateAndCloseIcons">
                                          <div className="day">today</div>
                                          <div
                                            className="closeIcon"
                                            onClick={handleClose}
                                          >
                                            <CgClose />
                                          </div>
                                        </div>
                                        <div className="NotificationDetails">
                                          <div className="nameAndTimeContainer">
                                            <div className="IconAndName">
                                              <div className="blackBox" />
                                              <div className="name">name</div>
                                            </div>
                                            <div className="Time">
                                              {moment(new Date()).format(
                                                "h:mm a"
                                              )}
                                            </div>
                                          </div>
                                          <div className="message">
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore lorem lorem lorem lore
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem className="menuitemNotification">
                                      <div className="notificationContainer">
                                        <div className="dateAndCloseIcons">
                                          <div className="day">today</div>
                                          <div
                                            className="closeIcon"
                                            onClick={handleClose}
                                          >
                                            <CgClose />
                                          </div>
                                        </div>
                                        <div className="NotificationDetails">
                                          <div className="nameAndTimeContainer">
                                            <div className="IconAndName">
                                              <div className="blackBox" />
                                              <div className="name">name</div>
                                            </div>
                                            <div className="Time">
                                              {moment(new Date()).format(
                                                "h:mm a"
                                              )}
                                            </div>
                                          </div>
                                          <div className="message">
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore lorem lorem lorem lore
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                    <MenuItem className="menuitemNotification">
                                      <div className="notificationContainer">
                                        <div className="dateAndCloseIcons">
                                          <div className="day">today</div>
                                          <div
                                            className="closeIcon"
                                            onClick={handleClose}
                                          >
                                            <CgClose />
                                          </div>
                                        </div>
                                        <div className="NotificationDetails">
                                          <div className="nameAndTimeContainer">
                                            <div className="IconAndName">
                                              <div className="blackBox" />
                                              <div className="name">name</div>
                                            </div>
                                            <div className="Time">
                                              {moment(new Date()).format(
                                                "h:mm a"
                                              )}
                                            </div>
                                          </div>
                                          <div className="message">
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore lorem lorem lorem lore
                                            lorem lorem lorem lore lorem lorem
                                            lorem lore
                                          </div>
                                        </div>
                                      </div>
                                    </MenuItem>
                                  </MenuList>
                                  <div>
                                    <Link
                                      className="SeeAll"
                                      to="/UserNotification"
                                    >
                                      See All
                                    </Link>
                                  </div>
                                </NotificationsStyle>
                              </Menu>
                            </NotificationsStyle>
                          </div>

                          <Badge
                            badgeContent={10}
                            max={999}
                            onClick={handleClick}
                            color="secondary"
                            style={{
                              marginRight: "35px",
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <NotificationsIcon />
                          </Badge>
                        </div>
                        <div className="center">
                          <Avatar className="avatar">
                            {fullName
                              .split(" ")
                              .map((word) => word[0])
                              .join("")
                              .slice(0, 2)
                              .toUpperCase()}
                          </Avatar>
                          <span>{fullName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isLogin && <RightNabar />}
                </div>
              </div>
            </div>
          </div>
        </HeaderBottom>
      </HeaderWrapper>
      <MobileMenu className="mobile-menu">
        <MobileHeader className="mobile-header">
          <Link to="/" onClick={() => setMobileMenu(false)}>
            {" "}
            <img src="assets/logo/logo.svg" alt="" />
          </Link>
          {!mobileMenu && (
            <AiOutlineMenu
              onClick={() => setMobileMenu(true)}
              style={{ cursor: "pointer" }}
            />
          )}
        </MobileHeader>
        <MobilePanel
          className="side-panel"
          style={{ width: mobileMenu ? "215px" : "0px" }}
        >
          <div className="close-panel-wrapper">
            <VscChromeClose
              className="close-panel"
              style={{ display: mobileMenu ? "block" : "none" }}
              onClick={() => setMobileMenu(false)}
            />
          </div>
          <ul className="nav-panel-items">
            <li>
              <Link onClick={() => setMobileMenu(false)} to="/AllCourses">
                All courses
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMobileMenu(false)}
                to="/EmailAccountLoginBlock"
              >
                Student Login
              </Link>
            </li>
            {!isLogin && (
              <>
                <li>
                  <Link onClick={() => setMobileMenu(false)} to="/SpecialOffer">
                    Special Offers
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setMobileMenu(false)}
                    to="/RequestCallback"
                  >
                    Request Callback
                  </Link>
                </li>
              </>
            )}
            {isLogin && (
              <li className="center">
                <Avatar className="mobile-view-avatar">
                  {fullName
                    .split(" ")
                    .map((word) => word[0])
                    .join("")
                    .slice(0, 2)
                    .toUpperCase()}
                </Avatar>
                <span className="name">{fullName}</span>
              </li>
            )}
          </ul>
        </MobilePanel>
      </MobileMenu>
    </>
  );
}

// styles

const HeaderWrapper = styled.div``;
const MobileMenu = styled.div``;
const MobileHeader = styled.div``;

const HeaderTop = styled.section`
  height: 60px;
`;

const HeaderBottom = styled.header({
  position: "absolute",
  top: "100px",
  width: "100%",
  zIndex: 999,
  "& .Ecologo": {
    height: "60px",
    maxWidth: "196px",
    width: "100%",
    maxHeight: "80px",
  },
  "& .center": {
    position: "relative",
  },
  "& .badgeAndIcon": {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  "& .listCOntainer": {
    marginTop: "20px",
    "& .MuiPopover-paper": {
      top: "100px",
    },
  },
});

const NotificationsStyle = styled.span({
  "& .notificationList": {
    width: "150px",
    backgroundColor: "wheat",
    color: "black",
  },
  "& .StaticHeight": {
    height: "200px",
  },
  "& .badgeAndIcon": {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  "& .notificationContainer": {
    width: "100%",
  },
  "& .dateAndCloseIcons": {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
    "& .day": {
      textTransform: "capitalize",
    },
    "& .closeIcon": {
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ":hover": {
        svg: {
          transform: "scale(1.1,1.1)",
        },
        backgroundColor: "black",
        color: "white",
      },
    },
  },
  "& .nameAndTimeContainer": {
    display: "flex",
    justifyContent: "space-between",
    "& .Time": {
      fontWeight: "600",
    },
  },
  "& .message": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .IconAndName": {
    display: "flex",
    alignItems: "center",
    "& .name": {
      fontSize: "20px",
      fontWeight: "600",
    },
  },
  "& .blackBox": {
    width: "22px",
    height: "22px",
    borderRadius: "4px",
    marginRight: "10px",
    backgroundColor: "black",
  },
  "& .NotificationDetails": {
    padding: "8px 16px",
    backgroundColor: "#cfcfcf",
    borderRadius: "8px",
  },
  "& .FixHeight": {
    maxHeight: "100%",
    height: "300px",
    minHeight: "300p",
  },
  "& .SeeAll": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    textDecoration: "none",
    color: "black",
    fontSize: "18px",
    cursor: "pointer",
  },
  "& .MuiList-padding": {
    padding: "0",
  },
});

const MobilePanel = styled.div``;

const RightSection = styled.div`
  display: flex;
  alignitems: center;
`;
export default TopNav;
