import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  InputBase
} from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Select from "react-select";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { styled } from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from '@material-ui/icons/Save';
import { FiRefreshCw } from "react-icons/fi";
import Spinner from "../../../components/src/Spinner.web";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
const waitingEmojiImg = require("../assets/waitingEmoji.png");
// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCmsDetails();
    this.getAccountDetail();
  }

  userDetailSchema = () => {
    return Yup.object().shape({
      phoneNumber: Yup.string().required("*Phone Number is required")
        .min(12, "*Enter valid phone number"),
    })
  }

  updateLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + latitude + ',' + longitude + '&key=AIzaSyCOVqy-64H25R4DVOFOdi1JBRafsgEpQNA')
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            this.setState({ location: data.results[0].formatted_address });
          })
          .catch((error) => {
            console.log(error);
            toast.error("Something went wrong!");
          });
      },
      (error) => {
        console.log(error);
        toast.error("Something went wrong!");
      }
    );
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="user-profile common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section pb70" style={{ backgroundImage: this.state.cmsDetail?.image ? `url(${this.state.cmsDetail?.image})` : `url('./assets/dashboard.png')` }}>
          <div className="content">
            <h2 className="title">Hello, {this.state.name}!</h2>
            <h5 className="sub-title">{this.state.email}</h5>
          </div>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%">
              <Formik
                enableReinitialize={this.state.isInitialize}
                initialValues={{
                  phoneNumber: this.state.phoneNumber
                }}
                validationSchema={this.userDetailSchema}
                onSubmit={(values) => {
                  this.updateAccountDetail();
                }}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form
                    onSubmit={() => { }}
                    noValidate
                    autoComplete="off"
                    className="wrapper"
                  >
                    {this.state.isInitialize && this.setState({ isInitialize: false })}
                    <Box bgcolor="black" borderRadius="15px" padding={{ xs: "30px", sm: "50px 30px" }} mb={7}>
                      <Box display="flex" justifyContent="space-between" flexDirection={{ xs: "column", sm: "row" }}>
                        <Box display="flex" alignItems="center" flexDirection={{ xs: "column", sm: "row" }} mb={{ xs: "25px", sm: "0px" }} textAlign={{ xs: "center", sm: "left" }}>
                          <Avatar src={""} alt="profile" variant="square" />
                          <Box display="flex" flexDirection="column" maxWidth="100%" ml={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                            <Typography variant="h5" style={{ color: "#fff", fontWeight: "bold" }}>{this.state.name}</Typography>
                            <Typography variant="body2" style={{ color: "#fff" }}>{this.state.email}</Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent={{ xs: "center", sm: "left" }}>
                          {this.state.isEdit ?
                            <CustomButton
                              onClick={() => handleSubmit()}
                            >
                              <SaveIcon style={{ height: "18px", width: "auto", marginRight: "5px" }} />{" "}
                              Save
                            </CustomButton>
                            :
                            <CustomButton type="button" onClick={() => this.setState({ isEdit: true })}>
                              <EditIcon style={{ height: "18px", width: "auto", marginRight: "5px" }} />{" "}
                              Edit profile
                            </CustomButton>
                          }
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={3} className="grid-padding">
                      <Grid item xs={12} md={6}>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Employee name:</Typography>
                          <CustomInputBase
                            fullWidth
                            disabled
                            value={this.state.name}
                            onChange={(event: any) => { }}
                          />
                        </Box>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Gender:</Typography>
                          <CustomInputBase
                            fullWidth
                            disabled
                            value={this.state.gender}
                            onChange={(event: any) => { }}
                          />
                        </Box>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Nationality:</Typography>
                          <CustomInputBase
                            fullWidth
                            disabled
                            value={this.state.nationality}
                            onChange={(event: any) => { }}
                          />
                        </Box>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Phone:</Typography>
                          <CustomInputBase
                            fullWidth
                            disabled={!this.state.isEdit}
                            value={this.state.phoneNumber}
                            onChange={(event: any) => {
                              const re = /^[0-9\b]+$/;
                              if ((event.target.value === '' || re.test(event.target.value)) && event.target.value.length <= 12) {
                                setFieldValue("phoneNumber", event.target.value);
                                this.setState({ phoneNumber: event.target.value });
                              }
                            }}
                          />
                          {touched.phoneNumber && errors.phoneNumber &&
                            <div className="text-danger">{errors.phoneNumber}</div>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Date of birth:</Typography>
                          <CustomInputBase
                            fullWidth
                            disabled
                            value={this.state.dob?.split("-").join("/")}
                            onChange={(event: any) => { }}
                          />
                        </Box>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Licence type:</Typography>
                          <CustomInputBase
                            fullWidth
                            disabled
                            value={this.state.licenceType}
                            onChange={(event: any) => { }}
                          />
                        </Box>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Language:</Typography>
                          <Select
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isDisabled={!this.state.isEdit}
                            placeholder="Language"
                            options={languageData}
                            value={
                              languageData.filter(
                                (option: any) =>
                                  option?.value === this.state.language
                              )[0]
                            }
                            onChange={(event: any) => {
                              this.setState({ language: event.value });
                            }}
                          />
                        </Box>
                        <Box mb={2} width={{ xs: "100%", md: "88%" }}>
                          <Typography style={{ marginBottom: "10px" }}>Location information</Typography>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyAI-3_mUD0DZbUP4NHw_dbMtwK-Rv6vyhE"
                            debounce={500}
                            selectProps={{
                              isClearable: true,
                              value: this.state.location ? [{label:this.state.location,value:this.state.location}] : undefined,
                              classNamePrefix: "react-select",
                              isDisabled: !this.state.isEdit,
                              placeholder: "Pick up location",
                              noOptionsMessage: () => null,
                              onChange: async (event: any) => {
                                this.setState({ location: event?.value?.description ?? "" });
                              },
                            }}
                          />
                        </Box>
                        <LocationUpdateButton disabled={!this.state.isEdit} onClick={this.updateLocation}>
                          Location update
                          <FiRefreshCw />
                        </LocationUpdateButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
              <Box className="wrapper" mt={5} p="50px 20px">
                <Box mb={4}>
                  <Typography style={{ fontWeight: "bold" }}>Test results</Typography>
                  <Typography variant="body2">Browse and upload</Typography>
                </Box>
                <Box bgcolor="#F5F5F5" borderRadius="10px" p="30px 20px" display="flex" flexWrap="wrap" justifyContent={{ xs: "center", md: "start" }}>
                  <ResultBox>
                    <Avatar src={waitingEmojiImg} alt="emoji" />
                    <Typography>Passed</Typography>
                    <Typography>Theory test</Typography>
                  </ResultBox>
                  <ResultBox>
                    <Avatar src={waitingEmojiImg} alt="emoji" />
                    <Typography>Failed</Typography>
                    <Typography style={{ textAlign: "center" }}>Initial driving assessment</Typography>
                  </ResultBox>
                  <ResultBox>
                    <Avatar src={waitingEmojiImg} alt="emoji" />
                    <Typography>Failed</Typography>
                    <Typography style={{ textAlign: "center" }}>Smart yard assessment</Typography>
                  </ResultBox>
                  <ResultBox>
                    <Avatar src={waitingEmojiImg} alt="emoji" />
                    <Typography>Waiting</Typography>
                    <Typography style={{ textAlign: "center" }}>RTA smart yard test</Typography>
                  </ResultBox>
                  <ResultBox>
                    <Avatar src={waitingEmojiImg} alt="emoji" />
                    <Typography>Waiting</Typography>
                    <Typography>Road test</Typography>
                  </ResultBox>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    )
  }
}

// Customizable Area Start
const languageData = [
  { label: "Arabic", value: "Arabic" },
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Urdu", value: "Urdu" },
];

const CustomButton = styled(Button)({
  fontFamily: 'ITC Avant Garde Gothic Std',
  height: "100%",
  maxHeight: "60px",
  width: "150px",
  marginLeft: "5px",
  fontSize: "18px",
  fontWeight: "bold",
  borderRadius: "5px",
  backgroundColor: "#fff",
  textTransform: "none",
  letterSpacing: "0px",
  "&:hover": {
    backgroundColor: "white",
  },
  "& span.MuiButton-label:hover": {
    color: "black"
  },
  "@media(max-width:576px)": {
    width: "125px",
    fontSize: "15px"
  }
});

const LocationUpdateButton = styled(Button)({
  fontFamily: 'ITC Avant Garde Gothic Std',
  width: "233px",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "5px",
  padding: "8px 15px",
  backgroundColor: "black",
  color: "white",
  textTransform: "none",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "black",
  },
  "& span.MuiButton-label:hover": {
    color: "white"
  },
  "&.MuiButton-root.Mui-disabled": {
    color: "hsl(0,0%,60%)",
    backgroundColor: "hsl(0,0%,95%)"
  },
  "@media(max-width:576px)": {
    width: "100%"
  }
});

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: 'ITC Avant Garde Gothic Std'
});

const ResultBox = styled(Box)({
  width: "120px",
  height: "198px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "15px",
  margin: "10px",
  border: "1px solid #CFCCCC",
  borderRadius: "7px",
  "& .MuiAvatar-root": {
    height: "60px",
    width: "60px",
    marginBottom: "10px"
  }
});

// Customizable Area End