Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";

exports.trainingStageApiEndPoint = "/bx_block_scheduling/schedules/get_stages";
exports.upcomingScheduleApiEndPoint = "/bx_block_scheduling/schedules/customer_upcoming_schedules";
exports.completedScheduleApiEndPoint = "/bx_block_scheduling/schedules/customer_past_schedules";
exports.cancelScheduleApiEndPoint = "/bx_block_scheduling/schedules";
// Customizable Area End