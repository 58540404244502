import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
export const configJSON = require("./config");
export const baseurl = require('../../../../packages/framework/src/config');
// Customizable Area End

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}

export interface PendingPaymentOption {
    id: number,
    name: string,
    subTitle: string,
    amount: number,
    vat: number,
    rta: number,
    total: number,
    isChecked: boolean,
}

export interface PaidPaymentOption {
    id: number,
    name: string,
    subTitle: string,
    total: number,
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    cmsDetail: any;
    userName: string;
    courseName: string;
    quantity: number;
    paidAmount: number;
    pendingAmount: number;
    totalAmount: number;
    partialNetAmount: number;
    partialVatAmount: number;
    partialRTAAmount: number;
    partialDiscount: number;
    totalPayableAmount: number;
    pendingPaymentStage: PendingPaymentOption[];
    paidPaymentStage: PaidPaymentOption[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

const subTitleArray = [
    "Learning permit, RTA handbook, knowledge fees, pre-evaluation test, theory training, mock test, theory test",
    "Stage - 1, stage - 2 to 3, night, initial driving test",
    "Stage - 4, assessment yard test, RTA yard test, highway, independent",
    ""
];

export default class CustomerPaymentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    partialPaymentApiCallID: string = "";
    pendingAmountApiCallID: string = "";
    amountPayableApiCallID: string = "";
    getCmsDetailApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            cmsDetail: "",
            userName: "",
            courseName: "",
            quantity: 0,
            pendingAmount: 0,
            paidAmount: 0,
            totalAmount: 0,
            partialNetAmount: 0,
            partialVatAmount: 0,
            partialRTAAmount: 0,
            partialDiscount: 0,
            totalPayableAmount: 0,
            pendingPaymentStage: [],
            paidPaymentStage: [],
            // Customizable Area End
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let data = this.props.location.state;
        if (data) {
            this.setState({
                userName: data.name,
                quantity: data.quantity,
                courseName: data.courseName
            });
        }
        this.getCmsDetails();
        this.fetchPartialPaymentDetail();
        this.fetchPendingAmountDetail();
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    toast.error("Something went wrong!");
                } else if (apiRequestCallId === this.partialPaymentApiCallID) {
                    this.handlePartialPaymentApiResponse(responseJson);
                } else if (apiRequestCallId === this.pendingAmountApiCallID) {
                    this.setState({
                        pendingAmount: Number(responseJson?.pending_amount?.pending_amount),
                        paidAmount: Number(responseJson?.paid_amount?.paid_amount),
                        totalAmount: Number(responseJson?.total_amount?.total_amount)
                    });
                }else if(apiRequestCallId === this.getCmsDetailApiCallID){
                    this.setState({ cmsDetail: responseJson?.data?.attributes });
                }
            }
            this.setState({ loading: false });
        }
        // Customizable Area End
    }

    // Customizable Area Start

    onPayNowButtonClick = () => {
        const { pendingPaymentStage, courseName, partialNetAmount, partialVatAmount, partialDiscount, totalPayableAmount } = this.state;

        let count = 0;
        this.state.pendingPaymentStage.map((item: any) => {
            if (item.isChecked) {
                count++;
            }
        });

        this.props.history.push({
            pathname: "/ReviewPayment",
            state: {
                title: pendingPaymentStage[count - 1]?.name,
                subTitle: pendingPaymentStage[count - 1]?.subTitle,
                quantity: count,
                courseType: courseName,
                netAmount: partialNetAmount,
                vatAmount: partialVatAmount,
                discount: partialDiscount,
                totalAmount: totalPayableAmount
            }
        });
    }

    partialCheckBoxChange = (data: PendingPaymentOption) => {
        let stage = [...this.state.pendingPaymentStage];
        let courseName = this.state.courseName;
        let netAmount = this.state.partialNetAmount;
        let vatAmount = this.state.partialVatAmount;
        let rtaAmount = this.state.partialRTAAmount;

        if (data.isChecked) {
            stage = stage.map((item: PendingPaymentOption) => {
                if (item.id >= data.id && item.isChecked) {
                    netAmount -= item.amount;
                    vatAmount -= item.vat;

                    if (courseName === "Regular" || courseName === "Flexi") {
                        rtaAmount -= item.rta;
                    }
                    return { ...item, isChecked: false };
                }
                return item;
            });
        } else {
            for (let i = 0; i < stage.length; i++) {
                if (stage[i].id < data.id && !stage[i].isChecked) {
                    return;
                }
            }
            stage[data.id].isChecked = true;
            netAmount += stage[data.id].amount;
            vatAmount += stage[data.id].vat;

            if (courseName === "Regular" || courseName === "Flexi") {
                rtaAmount += stage[data.id].rta;
            }
        }

        this.setState({
            pendingPaymentStage: stage,
            partialNetAmount: Number((netAmount).toFixed(2)),
            partialVatAmount: Number((vatAmount).toFixed(2)),
            partialRTAAmount: Number((rtaAmount).toFixed(2)),
            totalPayableAmount: Number((netAmount + vatAmount + rtaAmount).toFixed(2))
        });
    }

    handlePartialPaymentApiResponse = (responseJson: any) => {
        this.setState({ loading: false });

        let pendingStage: PendingPaymentOption[] = [];
        let paidStage: PaidPaymentOption[] = [];

        Object.entries(responseJson).forEach((item: any, index: number) => {
            const optionName = item[0].replace(/_/g, " ");
            const amount = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].reg_total_amount : Number(item[1]);
            const vat = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].reg_vat_on_eco : Number(item[1]) * 0.05;
            const rta = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].rta_share : item[1];
            const total = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].total_amount_after_vat : Number(item[1]) + Number(item[1]) * 0.05;
            const subtitle = subTitleArray[index];

            if (optionName === "quantity")
                return;

            if (index < this.state.quantity) {
                paidStage.push({
                    id: paidStage.length,
                    name: optionName,
                    subTitle: subtitle,
                    total: Number(total),
                });

            } else {
                pendingStage.push({
                    id: pendingStage.length,
                    name: optionName,
                    subTitle: subtitle,
                    amount: Number(amount),
                    vat: Number(vat),
                    rta: Number(rta),
                    total: Number(total),
                    isChecked: false,
                });
            }
        });

        this.setState({
            pendingPaymentStage: pendingStage,
            paidPaymentStage: paidStage
        });
    }

    fetchPartialPaymentDetail = () => {
        this.setState({ loading: true });

        const courseId = localStorage.getItem("id");

        const header = {
            "Content-Type": configJSON.partialPaymentContentType,
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.partialPaymentApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.partialPaymentApiEndPoint}=${courseId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.partialPaymentApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    fetchPendingAmountDetail = () => {
        this.setState({ loading: true });
        const courseId = localStorage.getItem('id');

        const header = {
            "Content-Type": configJSON.pendingAmountContentType,
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.pendingAmountApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.pendingAmountApiEndPoint}=${courseId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.pendingAmountApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    fetchPayableAmountDetail = () => {
        const { pendingPaymentStage, paidPaymentStage } = this.state;

        const courseId = localStorage.getItem("id");
        let checkedCount = 0;
        let url = `${configJSON.amountPayableApiEndPoint}=${courseId}`;

        pendingPaymentStage.map((item: PendingPaymentOption) => {
            if (item.isChecked) {
                checkedCount += 1;
                url += `&program_category_id[]=${item.id + paidPaymentStage.length + 1}`;
            }
        });

        if (checkedCount === 0) {
            this.setState({
                partialNetAmount: 0,
                partialVatAmount: 0,
                totalPayableAmount: 0,
            });
            return;
        }

        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.partialPaymentContentType,
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.amountPayableApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.amountPayableApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getCmsDetails = () => {
        this.setState({ loading: true });
    
        const header = {
          "Content-Type": configJSON.cmsApiContentType,
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCmsDetailApiCallID = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.cmsApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.cmsApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }
    // Customizable Area End
}