import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Container,
    Grid,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogActions,
    DialogTitle,
    Slide
} from "@material-ui/core";
import styled from "styled-components";
import { MdDelete } from "react-icons/md"
import { RiFolderUploadLine } from "react-icons/ri";
//@ts-ignore
import FileViewer from "react-file-viewer";
import moment from "moment";
import Spinner from "../../../components/src/Spinner.web";
const fileIcon = require('../assets/Group 237.png');

import DocumentsRequiredController, { Props } from "./DocumentsRequiredController.web";
const { baseURL } = require("../../../framework/src/config");
// Customizable Area End

const documentBox = {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "15px",
    backgroundColor: "#FFF",
} as const;

const CustomButton = styled.button({
    backgroundColor: "white",
    color: "black",
    border: "2px solid grey",
    padding: "5px 12px",
    fontSize: "14px",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontFamily: 'ITC Avant Garde Gothic Std',
    "&:hover": {
        backgroundColor: "black",
        color: "white"
    }
});

const btnTextArray = [
    "Eye Test Document",
    "Learing permit",
    "NOC",
    "Passport",
    "Photo",
    "Residence Visa Copy",
    "Emirate ID"
]

const titleStyle = {
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis"
} as const;

export default class DocumentsRequired extends DocumentsRequiredController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            //Merge Engine Start DefaultContainer
            <DocumentsRequiredStyle className="dashboard common">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <div className="first-section">
                    <div className="content">
                        <h2 className="title">Documents Required</h2>
                        <h5 className="sub-title">Lorem Ipsum, giving information</h5>
                    </div>
                </div>
                <div className="second-section">
                    <Box width="80%" margin="auto">
                        <Box width="100%">
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Box style={documentBox} padding={{ xs: "30px", lg: "35px" }}>
                                        <Box fontWeight="700" marginBottom="15px" fontSize="x-large" style={titleStyle}>
                                            Documents Required
                                            <div>
                                                <span className="smallText">Browse and Upload</span>
                                            </div>
                                        </Box>
                                        <Box fontWeight="600" marginBottom="15px" style={titleStyle}>
                                            Please submit the following documents
                                        </Box>
                                        <Box className="btnContainer">
                                            {btnTextArray.map((item) => {
                                                return (
                                                    <CustomButton key={item} style={{ backgroundColor: item === this.state.btnText ? "black" : "", color: item === this.state.btnText ? "white" : "" }} onClick={() => this.StateChange(item)} >{item}</CustomButton>
                                                )
                                            })}
                                        </Box>
                                        <Box className="uploadContainer">
                                            <Box className="uploadPlace">
                                                <input
                                                    accept="image/*, .pdf, .doc,"
                                                    id="UploadFile"
                                                    onChange={(e) => { this.UploadDocument(e); this.DocumentShow(e, this.state.btnText) }}
                                                    className="InputUpload"
                                                    type="file"
                                                />
                                                <label htmlFor="UploadFile">
                                                    <Box>
                                                        <div className="UploadIconAndText">
                                                            <div className="details">
                                                                <div className="Icons">
                                                                    <RiFolderUploadLine />
                                                                </div>
                                                            </div>
                                                            <div className="details">
                                                                <span className="smallText">
                                                                    Upload Documents
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="UploadIconAndText">
                                                            <div className="Heading">
                                                                <span>
                                                                    {this.state.btnText}
                                                                </span>
                                                            </div>
                                                            <div className="details">
                                                                <span className="smallText">Upload your learning {this.state.btnText} file </span>
                                                            </div>
                                                        </div>
                                                        <div className="UploadIconAndText">
                                                            <div className="Heading">
                                                                {this.state.btnText === "Eye Test Document" && this.state.eye_test_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                                {this.state.btnText === "Learing permit" && this.state.learing_permit_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                                {this.state.btnText === "NOC" && this.state.noc_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                                {this.state.btnText === "Passport" && this.state.passport_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                                {this.state.btnText === "Photo" && this.state.photo_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                                {this.state.btnText === "Residence Visa Copy" && this.state.residency_visa_copy_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                                {this.state.btnText === "Emirate ID" && this.state.emirate_id_avatar_url !== null ? <div className="ShowMessage">
                                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                                        ...e, preview: true
                                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                                </div> : false
                                                                }
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </label>
                                            </Box>
                                        </Box>
                                        {/* <Box className="SubmitBtn">
                                            <div className="previewBtnAndText">
                                                {this.state.btnText === "Eye Test Document" && this.state.eye_test_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                                {this.state.btnText === "Learing permit" && this.state.learing_permit_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                                {this.state.btnText === "NOC" && this.state.noc_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                                {this.state.btnText === "Passport" && this.state.passport_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                                {this.state.btnText === "Photo" && this.state.photo_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                                {this.state.btnText === "Residence Visa Copy" && this.state.residency_visa_copy_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                                {this.state.btnText === "Emirate ID" && this.state.emirate_id_avatar_url !== null ? <div className="ShowMessage">
                                                    <CustomButton id="Priview" className="preview" onClick={() => this.setState((e) => ({
                                                        ...e, preview: true
                                                    }))} >Preview {this.state.btnText}</CustomButton>
                                                </div> : false
                                                }
                                            </div>
                                        </Box> */}
                                    </Box>
                                </Grid>
                                {this.state.preview ?
                                    <Dialog scroll="paper" fullWidth open={this.state.preview} onClose={() => this.setState((e) => ({
                                        ...e, preview: false
                                    }))}>
                                        <DialogTitle>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    Preview Your {this.state.btnText} {`${this.state.btnText === "Eye Test Document" ? "" : "Document"}`}
                                                </div>
                                                <div>
                                                    <CustomButton style={{ fontSize: "12px" }} onClick={() => this.setState((e) => ({
                                                        ...e, preview: false
                                                    }))} >Close</CustomButton>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <CustomButton style={{ backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", color: "white", textTransform: "capitalize" }} onClick={() => {
                                                    this.DeletDocument()
                                                }} >{`Delete Your ${this.state.btnText} ${this.state.btnText === "Eye Test Document" ? "" : "Document"}`}
                                                    <MdDelete />
                                                </CustomButton>
                                            </div>
                                        </DialogTitle>

                                        <DocumentsRequiredStyle style={{ height: "100%", padding: "0 30px" }}>
                                            {this.state.btnText === "Eye Test Document" && this.state.eye_test_avatar_url !== null ?

                                                this.state.eye_test_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.eye_test_avatar_url} /> : <img src={baseURL + this.state.eye_test_avatar_url} style={{ width: "100%", height: "100%" }} />
                                                : false
                                            }
                                            {this.state.btnText === "Learing permit" && this.state.learing_permit_avatar_url !== null ?
                                                this.state.learing_permit_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.learing_permit_avatar_url} /> :
                                                    <img src={baseURL + this.state.learing_permit_avatar_url} style={{ width: "100%", height: "100%" }} />
                                                : false
                                            }
                                            {this.state.btnText === "NOC" && this.state.noc_avatar_url !== null ?
                                                this.state.noc_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.noc_avatar_url} /> :
                                                    <img src={baseURL + this.state.noc_avatar_url} style={{ width: "100%", height: "100%" }} />
                                                : false
                                            }
                                            {this.state.btnText === "Passport" && this.state.passport_avatar_url !== null ?
                                                this.state.passport_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.passport_avatar_url} /> :
                                                    <img src={baseURL + this.state.passport_avatar_url} style={{ width: "100%", height: "100%" }} />
                                                : false
                                            }
                                            {this.state.btnText === "Photo" && this.state.photo_avatar_url !== null ?
                                                this.state.photo_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.photo_avatar_url} /> :
                                                    <img src={baseURL + this.state.photo_avatar_url} style={{ width: "100%", height: "100%" }} />
                                                : false
                                            }
                                            {this.state.btnText === "Residence Visa Copy" && this.state.residency_visa_copy_avatar_url !== null ?
                                                this.state.residency_visa_copy_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.residency_visa_copy_avatar_url} /> :
                                                    <img src={baseURL + this.state.residency_visa_copy_avatar_url} style={{ width: "100%", height: "100%" }} /> : false
                                            }
                                            {this.state.btnText === "Emirate ID" && this.state.emirate_id_avatar_url !== null ?
                                                this.state.emirate_id_avatar_url.includes(".pdf") ?
                                                    <FileViewer fileType="pdf" filePath={baseURL + this.state.emirate_id_avatar_url} /> :
                                                    <img src={baseURL + this.state.emirate_id_avatar_url} style={{ width: "100%", height: "100%" }} /> : false
                                            }
                                        </DocumentsRequiredStyle>

                                        <DialogActions>
                                            <CustomButton style={{ fontSize: "14px" }} onClick={() => this.setState((e) => ({
                                                ...e, preview: false
                                            }))} >Close</CustomButton>
                                        </DialogActions>
                                    </Dialog> : false
                                }
                            </Grid>
                        </Box>
                    </Box>
                </div>
            </DocumentsRequiredStyle >
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const DocumentsRequiredStyle = styled.div({
    '& .smallText': {
        color: "grey",
        fontSize: "12px",
        fontWeight: "100",
    },
    '& .InputUpload': {
        display: "none"
    },

    '& .pg-viewer-wrapper': {
        overflowY: 'auto',
        display: 'flex',
        "& .photo-viewer-container": {
            height: '100% !important',
            width: "100% !important",
            img: {
                width: '100%',
                height: "100%",
            }
        },
    },
    '& .btnContainer': {
        display: "flex",
        '> button': {
            margin: "0 8px"
        },
        '& :first-child': {
            marginLeft: "0"
        },
        '& :last-child': {
            marginRight: "0px"
        },
        '@media only screen and (max-width: 768px)': {
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            columnGap: "4px",
            rowGap: "6px",
            '> button': {
                margin: "0",
                fontSize: "12px",
                padding: "4px"
            }
        },
        '@media only screen and (max-width: 430px)': {
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: "4px",
            rowGap: "6px",
            '> button': {
                margin: "0",
                fontSize: "12px",
                padding: "4px"
            }
        },
    },
    '& .SubmitBtn': {
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
    },
    '& .uploadContainer': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        backgroundColor: "#f5f5f5",
        padding: '50px',
        borderRadius: "8px",
        marginTop: "30px",
        '@media only screen and (max-width: 430px)': {
            padding: "0px"
        }
    },
    '& .uploadPlace': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "260px",
        borderRadius: "8px",
        backgroundColor: "#161616",
        cursor: "pointer",
        '& .UploadIconAndText': {
            margin: "10px 0",
            cursor: "pointer"
        },
        '& .Heading': {
            color: "white",
            cursor: "pointer",
            textAlign: "center",
            textTransform: 'capitalize',
        },
        '& .details': {
            textAlign: "center",
            color: "gray",
            cursor: "pointer",
            svg: {
                fontSize: "30px",
                color: "white",
                cursor: "pointer",
            }
        },
        '& .Icons': {
            display: 'flex',
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "center"
        }
    }
})
// Customizable Area End
