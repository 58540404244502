import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Button,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  Modal,
  Backdrop,
  Typography
} from "@material-ui/core";
import { styled as styles } from "@material-ui/styles";
import Rating from '@material-ui/lab/Rating';
import { withRouter } from "react-router";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styled from "styled-components";
import moment from "moment";
import TurnSlightRightIcon from "@material-ui/icons/Directions";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ScheduleController, { Props } from "./SchedulingController.web";
import Spinner from "../../../components/src/Spinner.web";
const congratulationImg = require("../assets/congratulation.png");
// Customizable Area End

class Schedule extends ScheduleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
      //Merge Engine Start DefaultContainer
      <ScheduleWrapper className="privacy-policy scheduling common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section">
          <div className="content">
            <h2 className="title">Select Date</h2>
            <h5 className="sub-title">
              Based on the your course selection you can only select max 5 day
            </h5>
          </div>
        </div>
        <div className="second-section">
          <Container maxWidth="xl">
            <Box display="flex" justifyContent="center">
              <Box
                width={{ xs: "400px", sm: "650px", md: "900px", lg: "1400px" }}
              >
                <Grid container spacing={3} style={{ marginBottom: "30px" }}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box
                      style={{
                        backgroundColor: "black",
                        color: "#fff",
                        borderRadius: "20px",
                        padding: "20px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Box>
                          <span className="smallText">Location</span>
                          <h6>ECO Drive Qusais UAE</h6>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="instructorContainer">
                          <div>
                            <span className="smallText">Instructor</span>
                            <h6>Fazil Ahmad</h6>
                          </div>
                          <div>
                            <TurnSlightRightIcon />
                          </div>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <Box
                      style={{
                        border: "2px solid #CCCCCC",
                        borderRadius: "20px",
                      }}
                    >
                      <Box display="flex" padding="15px 10px">
                        <select
                          value={this.state.month}
                          style={{
                            backgroundColor: "black",
                            border: "none",
                            color: "white",
                            borderRadius: "8px",
                            padding: "10px 15px",
                          }}
                          onChange={(e: any) => {
                            this.setState(
                              { month: parseInt(e.target.value) },
                              () =>
                                this.getDaysInMonth(
                                  this.state.month,
                                  this.state.year
                                )
                            );
                          }}
                        >
                          {this.state.monthArr.map((month, index: number) => {
                            return (
                              <option value={`${month}`} key={month}>
                                {months[month]}
                              </option>
                            );
                          })}
                        </select>
                        <select
                          value={this.state.year}
                          className="yearOption"
                          style={{ border: "none" }}
                          onChange={(e: any) => {
                            this.setState(
                              { year: parseInt(e.target.value) },
                              () =>
                                this.getDaysInMonth(
                                  this.state.month,
                                  this.state.year
                                )
                            );
                          }}
                        >
                          {this.state.yearArr.map((year) => {
                            return <option key={year} value={`${year}`}>{year}</option>;
                          })}
                        </select>
                      </Box>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        padding="0px 5px"
                        className="date"
                        marginBottom="20px"
                      >
                        {this.state.days.map((day, index: number) => {
                          let date = moment(day.date).format("DD");
                          let style = {
                            backgroundColor: "white",
                            color: "black",
                            cursor: "pointer",
                            border: "2px solid #DDDDDD",
                          };
                          if (!day.isAvailable)
                            style = {
                              backgroundColor: "#E1E1E1",
                              cursor: "default",
                              color: "black",
                              border: "2px solid #DDDDDD",
                            };
                          if (day.isSelected)
                            style = {
                              backgroundColor: "black",
                              color: "white",
                              cursor: "pointer",
                              border: "2px solid black",
                            };

                          return (
                            <Box
                              key={`${index},${day}`}
                              onClick={() => this.dateHandler(day, index)}
                              style={{
                                ...style,
                                padding: "3px 10px",
                                height: "fit-content",
                                width: "45px",
                                margin: "4px",
                                borderRadius: "3px",
                                textAlign: "center",
                              }}
                            >
                              <p style={{ fontWeight: "bold" }}>{date}</p>
                              <p
                                style={{
                                  color: day.isSelected
                                    ? "white"
                                    : "rgb(179 170 170)",
                                }}
                              >
                                {moment(day.date).format("dd")}
                              </p>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {this.state.slotDetail.map((item, index: number) => {
                    return (
                      <Grid item xs={12} sm={6} key={item.date + index}>
                        <Cards>
                          <p>
                            <strong>
                              Date:{" "}
                              {moment(item.date).format("Do MMMM YYYY") + " | " + moment(item.date).format("dddd")}
                            </strong>
                          </p>
                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                              <Box marginBottom="20px" marginTop="20px">
                                <p className="bold">Slot Hours</p>
                                <RadioGroup
                                  row
                                  aria-labelledby="slot"
                                  name="slot"
                                  className="radio-btn"
                                  value={this.state.slotDetail[index].selectedHour}
                                  onChange={(event: any) => this.changeHourHandler(event, index)}
                                  style={{ fontWeight: "bold" }}
                                >
                                  <FormControlLabel
                                    style={{ marginRight: "45px" }}
                                    value={"1"}
                                    control={
                                      <Radio style={{ color: "black" }} />
                                    }
                                    label="1 Hour"
                                  />
                                  <FormControlLabel
                                    value={"2"}
                                    control={
                                      <Radio style={{ color: "black" }} />
                                    }
                                    label="2 Hours"
                                  />
                                </RadioGroup>
                              </Box>
                              <Box>
                                <p className="bold" style={{ marginBottom: "1rem" }}>Available Slots</p>
                                {this.state.slotDetail[index]?.selectedHour === "1" ?
                                  this.state.slotDetail[index]?.available_slot_for_one_hr.map(
                                    (time: string, timeIndex: number) => {
                                      let isSelected = this.state.slotDetail[index].selectedSlots.includes(time);
                                      return (
                                        <Button
                                          key={time + timeIndex}
                                          onClick={() =>
                                            this.slotTimeHandler(item.date, time, index)
                                          }
                                          style={{
                                            color: isSelected ? "white" : "black",
                                            backgroundColor: isSelected
                                              ? "black"
                                              : "white",
                                            border: "1px solid black",
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {time}
                                        </Button>
                                      );
                                    }
                                  ) :
                                  this.state.slotDetail[index]?.available_slot_for_two_hr.map(
                                    (time: string, timeIndex: number) => {
                                      let isSelected = this.state.slotDetail[index].selectedSlots.includes(time);
                                      return (
                                        <Button
                                          key={time + timeIndex}
                                          onClick={() =>
                                            this.slotTimeHandler(item.date, time, index)
                                          }
                                          style={{
                                            color: isSelected ? "white" : "black",
                                            backgroundColor: isSelected
                                              ? "black"
                                              : "white",
                                            border: "1px solid black",
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {time}
                                        </Button>
                                      );
                                    }
                                  )
                                }
                              </Box>
                            </Grid>
                            <Grid item xs={12} lg={6} style={{ marginTop: "20px" }}>
                              {this.state.slotDetail[index].instructors.length > 0 && <p className="bold">Choose Instructor</p>}
                              {this.state.slotDetail[index].instructors?.map(
                                (instructor: any) => {
                                  return (
                                    <Box
                                      display="flex"
                                      key={instructor.full_name + index}
                                      justifyContent="space-between"
                                      marginBottom="15px"
                                    >
                                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Avatar src={instructor.image}
                                          style={{ height: "35px", width: "35px", borderRadius: "5px", marginRight: "5px" }} />
                                        <div>
                                          <div> <p>{instructor.full_name}</p></div>
                                          <div>
                                            <div style={{ fontSize: "10px", display: "flex", alignItems: "center" }}>
                                              Exp: {instructor.experience} |{" "}
                                              <Rating
                                                name="simple-controlled"
                                                precision={0.1}
                                                value={instructor.rating}
                                                readOnly
                                                style={{ color: "black", fontSize: "14px" }}
                                              />
                                              ({instructor.rating})
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Button
                                        onClick={() => this.selectInstructor(instructor.id, index)}
                                        style={{
                                          color:
                                            item.selectedInstructorID ===
                                              instructor.id
                                              ? "white"
                                              : "black",
                                          backgroundColor:
                                            item.selectedInstructorID ===
                                              instructor.id
                                              ? "black"
                                              : "white",
                                          border: "1px solid black",
                                          borderRadius: "5px",
                                          textTransform: "none",
                                        }}
                                      >
                                        {item.selectedInstructorID ===
                                          instructor.id
                                          ? <CheckOutlinedIcon />
                                          : "Select"}
                                      </Button>
                                    </Box>
                                  );
                                }
                              )}
                            </Grid>
                          </Grid>
                        </Cards>
                      </Grid>
                    );
                  })}
                </Grid>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  {this.state.slotDetail.length > 0 &&
                    <Button variant="contained" style={{ backgroundColor: "black", color: "flex-white" }}
                      onClick={() => this.verifySubmission()}> Schedule &nbsp; <ArrowForwardIcon /></Button>
                  }
                </Box>
              </Box>
            </Box>
            <Modal
              open={this.state.openModal}
              onClose={() => this.setState({ openModal: false })}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
            >
              <ModalWrapper>
                <img src={congratulationImg} style={{ height: "50px" }} />
                <Box
                  id="modal-title"
                  fontWeight="bolder"
                  mb={1}
                  fontSize={{ xs: "20px", sm: "27px" }}
                >
                  Congratulations !!
                </Box>
                <Typography id="modal-description">
                  Your Training Sessions ({this.state.slotDetail.length} Classes) has been booked Successfully !!
                </Typography>
                <Box mt={2}>
                  <DashboardButton
                    onClick={() => this.props.history.push("/Dashboard")}
                  >
                    Go to Dashboard
                  </DashboardButton>
                </Box>
              </ModalWrapper>
            </Modal>
          </Container>
        </div>
      </ScheduleWrapper>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

export default withRouter(Schedule);

const ScheduleWrapper = styled.div({
  '& .instructorContainer': {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  '& .smallText': {
    color: "grey",
    fontSize: "12px"
  },
  '& .yearOption': {
    marginLeft: "10px"
  }
});

const Cards = styles(Box)({
  border: "2px solid #CCCCCC",
  borderRadius: "20px",
  height: "100%",
  padding: "15px",
  "&:hover": {
    border: "2px solid black",
  }
});

const ModalWrapper = styles(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  textAlign: "center",
  width: 400,
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "40px 15px",
  borderRadius: "10px",
  boxShadow: "24",
  "@media(max-width:576px)": {
    width: "85%",
    padding: "25px 15px",
  },
});

const DashboardButton = styles(Button)({
  fontFamily: 'ITC Avant Garde Gothic Std',
  backgroundColor: "black",
  textTransform: "none",
  color: "white",
  border: "1px solid black",
  borderRadius: "5px",
  padding: "5px 10px",
  "&:hover": {
    backgroundColor: "black",
  },
});

// Customizable Area End