import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  InputBase,
  Link,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import Spinner from "../../../components/src/Spinner.web";
import EmailAccountLoginController, { Props } from "./EmailAccountLoginController";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
const { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSubmit = () => {
    if (this.state.loginError) {
      console.log("Akanxa", this.state.loginError)
      return;
    }

    this.setState({ loading: true });

    const attr = {
      full_phone_number: this.state.phoneCode + this.state.phoneNumber,
      password: this.state.password,
    };
    const jsonPayload = {
      data: {
        type: "email_account",
        attributes: attr,
      },
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonPayload),
    };

    fetch(baseURL + "/bx_block_login/logins", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loading: false });
        if (data?.errors && data?.errors[0]) {
          this.setState({ loginError: data.errors[0].failed_login });
        } else {
          this.successResponse(data);
        }
      });
  };

  successResponse = (data: any) => {
    localStorage.clear();
    localStorage.setItem("authToken", data?.meta?.token);
    switch (data.meta.account.registration_step) {
      case STEPS.OTP_VERIFY:
        localStorage.setItem("full_phone_number", data?.meta?.account?.full_phone_number);
        this.props.navigation.navigate('OTPInputAuth');
        break;
      case STEPS.CONTACT_DETAIL:
        this.props.navigation.navigate('AddAddress');
        break;
      case STEPS.COURSE:
        this.props.navigation.navigate('ItemAvailability');
        break;
      default:
        this.manageAccount(data);
    }
  }

  manageAccount = (data: any) => {
    const sendData = {
      licence_type: data?.meta?.account?.req_driving_licence_type,
      gear_type: data?.meta?.account?.gear_type
    };
    
    localStorage.setItem("sendItem", JSON.stringify(sendData));
    localStorage.setItem("full_phone_number", data?.meta?.account?.full_phone_number);
    localStorage.setItem("fullName", data?.meta?.account?.full_name);
    localStorage.setItem("id", data?.meta?.course_type?.id);
    localStorage.setItem("paidBy", data?.meta?.paid_by);
    localStorage.setItem("isLogin", "true");
    this.props.navigation.navigate("Dashboard");
  }

  loginSchema = () => {
    return Yup.object().shape({
      phoneNumber: Yup.string().required("*Phone Number is required")
        .min(9, "*Enter valid phone number"),
      password: Yup.string()
        .required("*Password is required")
        .min(8, "*Password must be greater then 8 character"),
    })
  }

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <div className="login common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url("assets/images/home-bg.png")` }} >
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Login"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Eco Drive - The driving institute."}</h5>
          </div>
          <Formik
            initialValues={{
              phoneNumber: this.state.phoneNumber,
              password: this.state.password
            }}
            validationSchema={this.loginSchema}
            onSubmit={(values) => {
              this.handleSubmit();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >
                <Grid container className="center">
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <div className="box-field">
                        <Select
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={phoneCodeData}
                          value={
                            phoneCodeData.filter(
                              (option: any) =>
                                option.value === this.state.phoneCode
                            )[0]
                          }
                          onChange={(event: any) => {
                            this.setState({ phoneCode: event.value, loginError: "" })
                          }}
                        />
                        <div style={{ marginLeft: "20px", width: "100%" }}>
                          <CustomInputBase
                            fullWidth
                            placeholder="Phone Number*"
                            value={this.state.phoneNumber}
                            onChange={(event: any) => {
                              const re = /^[0-9\b]+$/;
                              if ((event.target.value === '' || re.test(event.target.value)) && event.target.value.length <= 10) {
                                setFieldValue("phoneNumber", event.target.value);
                                this.setState({ phoneNumber: event.target.value });
                              }
                              this.setState({ loginError: "" });
                            }}
                          />
                        </div>
                      </div>
                      {touched.phoneNumber && errors.phoneNumber &&
                        <div className="text-danger">{errors.phoneNumber}</div>
                      }
                    </Box>
                    <Box mb={1}>
                      <CustomInputBase
                        fullWidth
                        placeholder="Password"
                        value={this.state.password}
                        onChange={(event: any) => {
                          setFieldValue("password", event.target.value);
                          this.setState({ password: event.target.value, loginError: "" });
                        }}
                      />
                      {touched.password && errors.password &&
                        <div className="text-danger">{errors.password}</div>
                      }
                      {this.state.loginError &&
                        <div className="text-danger">*{this.state.loginError}</div>
                      }
                    </Box>
                    <Box textAlign="end">
                      <CustomLink onClick={() => this.props.navigation.navigate("ForgotPassword")} style={{ borderBottom: "1px solid black" }}>Forgot password?</CustomLink>
                    </Box>
                    <SubmitButton type="submit" fullWidth>
                      Login
                    </SubmitButton>
                    <Box textAlign="end">
                      <span style={{ color: "#B0B0B0", borderBottom: "1px solid #B0B0B0" }}>Don't have an account?</span>
                      <CustomLink onClick={() => this.props.navigation.navigate("EmailAccountRegistration")} style={{ textDecoration: "none" }}> Register</CustomLink>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const phoneCodeData = [
  { label: "+971", value: "+971" },
  { label: "+91", value: "+91" },
];

export enum STEPS {
  OTP_VERIFY = 'verify_otp',
  CONTACT_DETAIL = 'contact_details',
  COURSE = ' course',
}

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: 'ITC Avant Garde Gothic Std'
});

const SubmitButton = styled(Button)({
  backgroundColor: "black",
  margin: "50px 0px 20px 0px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  },
});

const CustomLink = styled(Link)({
  color: "black",
  cursor: "pointer",
  textDecoration: "none",
  fontWeight: "bold",
  "&:hover": {
    color: "black",
    textDecoration: "none",
  },
});
// Customizable Area End
