import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    trainingStageId: number | null;
    days: any[];
    month: string | number;
    year: string | number;
    selectedDate: any[];
    unAvailableDate: any[];
    monthArr: any[];
    yearArr: any[];
    slotDetail: any[];
    selectedSlotIndex: number | null;
    openModal: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SchedulingController extends BlockComponent<Props, S, SS>{

    // Customizable Area Start
    unavailable_days_ApiCallID: string = "";
    available_slots_ApiCallID: string = "";
    slot_wise_instructor_ApiCallID: string = "";
    book_schedule_ApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            trainingStageId: null,
            days: [],
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            monthArr: [],
            yearArr: [],
            selectedDate: [],
            unAvailableDate: [],
            slotDetail: [],
            selectedSlotIndex: null,
            openModal: false
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (responseJson.error === 500) {
                    toast.error("Something went wrong!");
                }
                if (apiRequestCallId === this.unavailable_days_ApiCallID) {
                    this.setState({ unAvailableDate: responseJson.date });

                } else if (apiRequestCallId === this.slot_wise_instructor_ApiCallID) {
                    const slotDetail = [...this.state.slotDetail];
                    const index = this.state.selectedSlotIndex ?? 0;
                    let obj = slotDetail[index];
                    obj.instructors = responseJson.available_instructors;
                    slotDetail[index] = obj;
                    this.setState({ slotDetail });

                } else if (apiRequestCallId === this.book_schedule_ApiCallID) {
                    if (responseJson.errors) {
                        toast.error(responseJson.errors);
                    } else if (responseJson.data) {
                        this.setState({ openModal: true });
                    } else {
                        toast.error("Something went wrong!");
                    }
                } else if (apiRequestCallId === this.available_slots_ApiCallID) {
                    let newSlot = {
                        ...responseJson.available_slots[0],
                        date: responseJson.available_slots[0].date,
                        instructors: [],
                        selectedSlots: [],
                        selectedInstructorID: null,
                        selectedHour: "1",
                        availableHour: responseJson.available_slots[0].available_slot_for_one_hr,
                    };

                    let slotDetail = [...this.state.slotDetail, newSlot];
                    this.setState({ slotDetail });
                }
            }
            this.setState({ loading: false });
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        const { month, year } = this.state;
        this.setYearAndMonth();
        this.getUnavailableDates();
        this.getDaysInMonth(month, year);

        let data = this.props.location.state;
        if (data) {
            this.setState({ trainingStageId: data.trainingDetails?.training_stage_id });
        }
    }

    getRange = (start: number, end: number) => {
        let arr = [];
        if (start > end) {
            arr.push(start);
            arr.push(end);
        }
        for (let i = start; i <= end; ++i) {
            arr.push(i);
        }
        return arr;
    };

    setYearAndMonth = () => {
        let today = moment();
        let date = moment().add(30, "days");
        let monthArr = this.getRange(
            parseInt(today.format("MM")) - 1,
            parseInt(date.format("MM")) - 1
        );
        let yearArr = this.getRange(
            parseInt(today.format("YYYY")),
            parseInt(date.format("YYYY"))
        );
        this.setState({ monthArr, yearArr });
    };

    dateHandler = async (day: any, index: number) => {
        if (!day.isAvailable) return;
        if (day.date < moment().format("YYYY-MM-DD")) return;

        let dates = [...this.state.days];
        let selectedDates = [...this.state.selectedDate];
        let selectedDay = dates[index];

        if (selectedDay.isSelected) {
            let index = selectedDates.indexOf(day.date);
            let obj = [...this.state.slotDetail];
            obj.splice(index, 1);

            selectedDay.isSelected = false;
            selectedDates = selectedDates.filter((date) => {
                return date !== selectedDay.date;
            });

            this.setState({ selectedDate: selectedDates, slotDetail: obj });

        } else if (this.state.selectedDate.length < 4) {
            selectedDay.isSelected = true;
            dates[index] = selectedDay;
            this.setState({
                days: dates,
                selectedDate: [...this.state.selectedDate, selectedDay.date],
            });

            //Api calling
            this.getAvailableSlots(day.date);
        }
    };

    getDaysInMonth = (month: any, year: any) => {
        let date = new Date(year, month, 1);
        let days: any[] = [];
        while (date.getMonth() === month) {
            let newDate = moment(new Date(date)).format("YYYY-MM-DD");
            let obj = {
                isAvailable:
                    !this.state.unAvailableDate.includes(newDate) &&
                    (newDate >= moment().format("YYYY-MM-DD") &&
                        newDate <=
                        moment()
                            .add(30, "days")
                            .format("YYYY-MM-DD")),
                isSelected: this.state.selectedDate.includes(newDate),
                date: newDate,
            };
            days.push(obj);
            date.setDate(date.getDate() + 1);
        }
        this.setState({ days });
    };

    changeHourHandler = (event: any, index: number) => {
        let slotDetail = [...this.state.slotDetail];
        let obj = slotDetail[index];
        obj.selectedHour = event.target.value;
        obj.selectedSlots = [];
        obj.instructors = [];
        slotDetail[index] = obj;
        this.setState({ slotDetail });
    }

    slotTimeHandler = async (date: string, time: string, index: number) => {
        let slotDetail = [...this.state.slotDetail];
        let obj = slotDetail[index];
        let hourIndex = obj.selectedSlots.indexOf(time);
        if (hourIndex !== -1) {
            obj.selectedSlots.splice(hourIndex, 1);
        } else {
            obj.selectedSlots.push(time);
        }

        obj.instructors = [];
        slotDetail[index] = obj;
        this.setState({ slotDetail, selectedSlotIndex: index });

        if (obj.selectedSlots.length > 0) {
            //Api calling
            this.getAvailableInstructorList(date, obj.selectedSlots);
        }
    };

    selectInstructor = (instructorId: number, index: number) => {
        let slotDetail = [...this.state.slotDetail];
        let obj = slotDetail[index];
        obj.selectedInstructorID = instructorId;
        slotDetail[index] = obj;
        this.setState({ slotDetail });
    };

    verifySubmission = async () => {
        const data = [...this.state.slotDetail];

        const faultyData = data.filter((item: any) => (
            item.selectedInstructorID == null || item.selectedSlots.length === 0
        ));

        if (faultyData.length > 0) {
            toast.error("Please look into bookings, please select the instructor or remove the slot");
            return;
        }

        let submitData: any = [];
        submitData = data.map((item: any) => {
            let newDate = item.date.split("-");
            newDate = newDate.join("/");
            return {
                date: newDate,
                slot_hrs: item.selectedHour == "1" ? "1 hours" : "2 hours",
                booked_slots: item.selectedSlots,
                instructor_id: item.selectedInstructorID,
                training_stage_id: this.state.trainingStageId
            };
        });

        //Api call
        this.bookSchedule(submitData);
    }

    apiCall = async (data: any) => {
        this.setState({ loading: true });

        const token = localStorage.getItem("authToken") as string

        const { contentType, method, endPoint, body, type } = data
        const header = {
            'Content-Type': contentType,
            token: token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )

            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    getUnavailableDates = async () => {
        this.unavailable_days_ApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_scheduling/schedules/unavailable_days`,
        });
    }

    getAvailableSlots = async (date: string) => {
        this.available_slots_ApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_scheduling/schedules/check_availability?date[]=${date}`,
        });
    }

    getAvailableInstructorList = async (date: string, selectedSlots: string[]) => {
        let url = `/bx_block_scheduling/schedules/slot_wise_instructor?date=${date}`;
        selectedSlots.map((slot: string) => url += `&slots[]=${slot}`);

        this.slot_wise_instructor_ApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: url
        });
    }

    bookSchedule = async (submitData: any[]) => {
        this.book_schedule_ApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `/bx_block_scheduling/schedules`,
            body: submitData
        });
    }
    // Customizable Area End
}
