import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}

export interface TabPanelProps {
    children?: React.ReactNode;
    id: string;
    currentValue: string;
}

export interface TrainingStageResponse {
    training_stages: Array<TrainingData>;
    current_stage: CurrentStageData;
}
export interface TrainingData {
    id: number;
    name: string;
    suggested_hour: number;
    isCompleted?: boolean;
    isCurrent?: boolean;
    shouldPay?: boolean;
    status?: string;
}
export interface CurrentStageData {
    id: number;
    account_id: number,
    course_type: string;
    status: string;
    training_stage_id: number;
    training_stage_vehicle_type_id: number;
    unpaid_hours: number;
}
interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    loading: boolean;
    activeTab: string;
    trainingStage: TrainingData[];
    currentStage: CurrentStageData | null;
    upcomingScheduleData: any[];
    completedScheduleData: any[];
    cancelScheduleId: number | null;
    isUpcomingTabVisited: boolean;
    isCompletedTabVisited: boolean;
    openModal: boolean;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class MyBookController extends BlockComponent<Props, S, SS> {

    getTrainingStageApiCallID: string = "";
    getUpcomingScheduleApiCallID: string = "";
    getCompletedScheduleApiCallID: string = "";
    cancelScheduleApiCallID: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            loading: false,
            activeTab: "schedule",
            trainingStage: [],
            currentStage: null,
            upcomingScheduleData: [],
            completedScheduleData: [],
            cancelScheduleId: null,
            isUpcomingTabVisited: false,
            isCompletedTabVisited: false,
            openModal: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (responseJson.errors) {
                    if (Array.isArray(responseJson.errors)) {
                        toast.error(responseJson.errors[0]?.token);
                    } else {
                        toast.error(responseJson.errors);
                    }
                } else if (responseJson.error) {
                    toast.error("Something went wrong!");
                } else if (apiRequestCallId === this.getTrainingStageApiCallID) {
                    this.handleTrainingStageResponse(responseJson);
                } else if (apiRequestCallId === this.getUpcomingScheduleApiCallID) {
                    this.setState({
                        upcomingScheduleData: responseJson.data ? responseJson.data : []
                    });
                } else if (apiRequestCallId === this.getCompletedScheduleApiCallID) {
                    this.setState({
                        completedScheduleData: responseJson.data ? responseJson.data : []
                    });
                } else if (apiRequestCallId === this.cancelScheduleApiCallID) {
                    this.setState({ cancelScheduleId: null, loading: false },
                        () => this.getUpcomingScheduleDetail()
                    );
                    toast.success("Schedule canceled successfully!");
                }
            }
            this.setState({ loading: false });
        }
        // Customizable Area End
    }


    // Customizable Area Start

    handleTabChange = (event: React.ChangeEvent<any>, newValue: string) => {
        if (newValue === "upcoming") {
            this.getUpcomingScheduleDetail();
        } else if (newValue === "completed") {
            this.getCompletedScheduleDetail();
        }
        this.setState({ activeTab: newValue });
    }

    redirectToSchedulePage = (suggestedHour: number) => {
        this.props.history.push({
            pathname: "/scheduling",
            state: {
                trainingDetails: this.state.currentStage,
                maxHrs: suggestedHour
            }
        });
    }

    redirectToMapPage = () => {
        toast.info("Coming soon!");
        // this.props.history.push({
        //     pathname: "/map"
        // });
    }

    handleTrainingStageResponse = (responseJson: TrainingStageResponse) => {
        let trainingStage = responseJson.training_stages;
        const currentStage = responseJson.current_stage;

        trainingStage = responseJson.training_stages?.map((item: TrainingData) => {
            let isCompleted = false;
            let isCurrent = false;
            let shouldPay = false;
            let status = "Not Started";

            if (item.id === currentStage.training_stage_id) {
                isCurrent = true;
                status = currentStage.status;
                shouldPay = currentStage.unpaid_hours > 0;
            }
            return {
                ...item, isCompleted, status, isCurrent, shouldPay
            }
        });

        this.setState({ trainingStage, currentStage });
    }

    getTrainingStageDetail = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTrainingStageApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.trainingStageApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getUpcomingScheduleDetail = () => {
        this.setState({ isUpcomingTabVisited: true, loading: true });

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUpcomingScheduleApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upcomingScheduleApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCompletedScheduleDetail = () => {
        this.setState({ isCompletedTabVisited: true, loading: true });

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCompletedScheduleApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.completedScheduleApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onCancelScheduling = () => {
        this.setState({ openModal: false, loading: true });

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.cancelScheduleApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.cancelScheduleApiEndPoint}/${this.state.cancelScheduleId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
