import React from 'react';
import moment from "moment";
import styled from "styled-components";
import { Button, Modal, Box, Backdrop, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { styled as styles } from "@material-ui/styles";
import { MdDownloadForOffline } from 'react-icons/md';
import { AiOutlineCheck } from "react-icons/ai";
import SuccessPaymentController, { Props } from './SuccessPaymentController.web';

class SuccessPayment extends SuccessPaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {

        return (
            <CommonWrapper className="dashboard common">
                <div className="first-section" style={{ backgroundImage: this.state.cmsDetail?.image ? `url(${this.state.cmsDetail?.image})` : `url('/assets/images/vehicle_detail.png')` }}>
                    <div>
                        <div className="content">
                            <h2 className="title">{this.state.cmsDetail?.title ?? "Invoice"}</h2>
                            <h5 className="sub-title">Student</h5>
                        </div>
                    </div>
                </div>
                <section className="second-section" style={{ backgroundColor: "#FFF" }}>
                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="blackBox">
                                                <div>
                                                    <div>
                                                        <span className="title">Invoice id: 153435</span>
                                                    </div>
                                                    <div>
                                                        <span className="cost-payment">{moment().format("DD-MMM-YYYY h:mm A")}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='Paid'><AiOutlineCheck /> <span className='PaidText'>Paid</span> </div>
                                                </div>
                                            </div>
                                            <div className="mainBlock">
                                                <div className='item-detail'>
                                                    <h5>Transaction type:</h5>
                                                    <h5>{this.state?.transactionType ? this.state?.transactionType : "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Amount paid:</h5>
                                                    <h5>AED {Number(localStorage.getItem("amount") ?? 0).toFixed(2)}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Licence type:</h5>
                                                    <h5>{JSON.parse(localStorage.getItem("sendItem") as any)?.licence_type ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Course type:</h5>
                                                    <h5>{localStorage.getItem("name") ?? "-"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Date &#38; time:</h5>
                                                    <h5>{moment(this.state.invoiceDate).format("DD-MMM-YYYY H:MM A")}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                <div className='item-detail'>
                                                    <h5>S.no</h5>
                                                    <h5>01</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Stage</h5>
                                                    <h5>Registration</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Qty</h5>
                                                    <h5>{localStorage.getItem("count") ?? "0"}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Price</h5>
                                                    <h5>{Number(localStorage.getItem("amount") ?? 0).toFixed(2)}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Amount</h5>
                                                    <h5>{Number(localStorage.getItem("amount") ?? 0).toFixed(2)}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Discount</h5>
                                                    <h5>{Number(localStorage.getItem("discount") ?? 0).toFixed(2)}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Net amount</h5>
                                                    <h5>{Number(localStorage.getItem("net") ?? 0).toFixed(2)}</h5>
                                                </div>
                                                <div className='item-detail'>
                                                    <h5>Vat @ 5%</h5>
                                                    <h5>{Number(localStorage.getItem("vat") ?? 0).toFixed(2)}</h5>
                                                </div>
                                            </div>
                                            <div className='BtnContainer'>
                                                <button
                                                    onClick={this.downloadInvoice}
                                                    className="PayBtn"
                                                >
                                                    Download invoice{" "}
                                                    <MdDownloadForOffline />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <Modal
                    open={this.state.modelState}
                    onClose={this.modelClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                >
                    <ModalWrapper>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "27px" }}
                        >
                            Download completed!
                        </Box>
                        <Box mt={2}>
                            <ModalOkButton
                                onClick={() => this.modelClose()}
                            >
                                Ok
                            </ModalOkButton>
                        </Box>
                    </ModalWrapper>
                </Modal>
            </CommonWrapper>
        )
    }
}

export default SuccessPayment;
const ModalWrapper = styles(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    width: 400,
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "40px 15px",
    borderRadius: "10px",
    boxShadow: "24",
    "@media(max-width:576px)": {
        width: "85%",
        padding: "25px 15px",
    },
});

const ModalOkButton = styles(Button)({
    fontFamily: 'ITC Avant Garde Gothic Std',
    color: "white",
    backgroundColor: "black",
    textTransform: "none",
    border: "1px solid black",
    borderRadius: "5px",
    width: "70px",
    padding: "3px 8px",
    "&:hover": {
        backgroundColor: "black",
    },
});

const CommonWrapper = styled.main({
    '& .mainBlock': {
        overflow: "hidden",
        borderRadius: '15px',
        color: '#fff',
        border: "2px solid #C7C7C7",
        'div:nth-child(even)': {
            background: '#F1F1F1',
            borderRadius: "8px"
        }
    },
    '& .BtnContainer': {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
    '& .item-detail': {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        padding: '10px 35px',
        h5: {
            color: "grey",
            fontSize: "14px",
            marginBottom: 0
        },
        'h5:nth-child(2)': {
            textAlign: "right",
        },
        'h5:nth-child(even)': {
            fontWeight: "600",
            color: "black"
        },
        '@media only screen and (max-width: 992px)': {
            padding: '10px 20px',
        },
        '@media only screen and (max-width: 576px)': {
            padding: '10px 20px',
        }
    },
    '@media only screen and (max-width: 576px)': {
        '& .col-sm-6': {
            paddingBottom: '20px !important'
        }
    },
    '& .blackBox': {
        padding: '20px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        background: '#161616',
        borderRadius: '15px',
        color: '#fff',
        marginBottom: '18px',
    },
    '& .Paid': {
        display: 'flex',
        alignItems: "center"
    },
    '& .PaidText': {
        paddingLeft: "5px"
    }
});

const Container = styled.div`
width: 80%;
margin: auto;
.title{
    font-size: 16px;
    color:white;
    font-weight: "500";
}
.PayBtn{
    width: 60%;
    font-weight: bold;
    background-color: #161616;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
    border: 0px;
}
.OkBtn{
    background-color: #161616 !important;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
}
@media only screen and (max-width: 992px) {
    .PayBtn{
        width: 100%;
    }
  }
`;
