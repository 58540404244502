import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  Grid,
} from "@material-ui/core";
const peopleImg = require("../assets/about-img.png");
const eyeImg = require("../assets/eye.png");
const rocketImg = require("../assets/rocket.png");
const plantImg = require("../assets/plant.png");
const earthImg = require("../assets/earth.png");

// Customizable Area End

import AboutController, { Props } from "./AboutController.web";
// Customizable Area End

export default class About extends AboutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="about common">
        <div className="first-section pb70" style={{ backgroundImage: this.state.CmsData.image ? `url(${this.state.CmsData.image})` : "url('./assets/about.png')" }}>
          <div className="content">
            <h2 className="title"> {this?.state?.CmsData?.title ? this?.state?.CmsData?.title : "About Eco Drive"}</h2>
            <h5 className="sub-title">{this?.state?.CmsData?.sub_title ? this?.state?.CmsData?.sub_title : "Help and Frequently Asked Questions"}</h5>
          </div>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <div className="second-section" style={{ backgroundColor: "#f2f2f2" }}>
          <Box width="80%" margin="auto">
            <Box width="100%">
              <Grid container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={12} lg={6}>
                  <Box paddingX={{ lg: "80px" }}>
                    <h2 style={{ fontWeight: "bold" }}> {this.state.CmsDataTwo.title ? this.state.CmsDataTwo.title : "Who We Are"}</h2>
                    <div>
                      {this.state.CmsDataTwo.description
                        ? this.state.CmsDataTwo.description.split("\n")
                          .map((paragraph: string, index: number) =>
                            <p key={index}>{paragraph}</p>
                          )
                        : <p>Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultrices in sodales quis, interdum eu justo. Curabitur pretium eleifend nisi vel auctor. Nam blandit dolor in sem gravida finibus. Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultrices in sodales quis, interdum eu justo. Curabitur pretium eleifend nisi vel auctor.</p>
                      }
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <img src={this.state.CmsDataTwo.image ? this.state.CmsDataTwo.image : peopleImg} style={{ width: "100%" }} />
                </Grid>
              </Grid>
              <Box paddingY="50px">
                <Grid container spacing={4} justifyContent="center">
                  <Grid item xs={12} sm={6} lg={4}>
                    <div style={{ backgroundColor: "black", color: "white", padding: "30px", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "7px", height: "100%" }}>
                      <img src={this.state.CmsDataTwoOur_vision.image ? this.state.CmsDataTwoOur_vision.image : eyeImg} style={{ height: "45px", margin: "40px 0px" }} />
                      <h4 style={{ fontWeight: "bold" }}>{this.state.CmsDataTwoOur_vision.title ? this.state.CmsDataTwoOur_vision.title : " Our Vision"}</h4>
                      <p style={{ textAlign: "center" }}>

                        {this.state.CmsDataTwoOur_vision.description ? this.state.CmsDataTwoOur_vision.description : "Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultricesMaecenas eu lectus id elit tincidunt rhoncus."}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <div style={{ backgroundColor: "black", color: "white", padding: "30px", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "7px", height: "100%" }}>
                      <img src={this.state.CmsDataTwoOurMission.image ? this.state.CmsDataTwoOurMission.image : rocketImg} style={{ height: "45px", margin: "40px 0px" }} />
                      <h4 style={{ fontWeight: "bold" }}>{this.state.CmsDataTwoOurMission.title ? this.state.CmsDataTwoOurMission.title : "Our Mission"}</h4>
                      <p style={{ textAlign: "center" }}>{this.state.CmsDataTwoOurMission.description ? this.state.CmsDataTwoOurMission.description : "Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultricesMaecenas eu lectus id elit tincidunt rhoncus."}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <div style={{ backgroundColor: "black", color: "white", padding: "30px", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "7px", height: "100%" }}>
                      <img src={this.state.CmsDataTwoOurValues.image ? this.state.CmsDataTwoOurValues.image : plantImg} style={{ height: "45px", margin: "40px 0px" }} />
                      <h4 style={{ fontWeight: "bold" }}>{this.state.CmsDataTwoOurValues.title ? this.state.CmsDataTwoOurValues.title : "Our Values"}</h4>
                      <p style={{ textAlign: "center" }}>{this.state.CmsDataTwoOurValues.description ? this.state.CmsDataTwoOurValues.description : "Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultricesMaecenas eu lectus id elit tincidunt rhoncus."}</p>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Grid container style={{ display: "flex", justifyContent: "center" }}>
                <Grid item xs={12} md={8}>
                  <Box textAlign="center">
                    <img src={this.state.CmsDataTwoWeCare.image ? this.state.CmsDataTwoWeCare.image : earthImg} style={{ marginBottom: "30px" }} />
                    <h2 style={{ fontWeight: "bold" }}>{this.state.CmsDataTwoWeCare.title ? this.state.CmsDataTwoWeCare.title : "We Care for the Environment"}</h2>
                    <p>{this.state.CmsDataTwoWeCare.description ? this.state.CmsDataTwoWeCare.description : "Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultrices in sodales quis, interdum eu justo. Curabitur pretium eleifend nisi vel auctor. Nam blandit dolor in sem gravida finibus. Maecenas eu lectus id elit tincidunt rhoncus. Vestibulum orci ipsum, ultrices in sodales quis, interdum eu justo. Curabitur pretium eleifend nisi vel auctor."}</p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
