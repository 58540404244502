Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";

exports.btnExampleTitle = "CLICK ME";

exports.partialPaymentContentType = "application/json";
exports.partialPaymentApiMethod = "GET";
exports.partialPaymentApiEndPoint = "/bx_block_splitpayments/partial_payment?subscription_plan_id";

exports.pendingAmountContentType = "application/json";
exports.pendingAmountApiMethod = "GET";
exports.pendingAmountApiEndPoint = "/bx_block_splitpayments/split_payments?subscription_plan_id";

exports.amountPayableContentType = "application/json";
exports.amountPayableApiMethod = "GET";
exports.amountPayableApiEndPoint="/bx_block_splitpayments/amount_payable?subscription_plan_id";

exports.paymentContentType = "application/json";
exports.paymentApiMethod = "POST";
exports.paymentApiEndPoint = "bx_block_payments/payments";

exports.cmsApiMethod = "GET";
exports.cmsApiContentType = "application/json";
exports.cmsApiEndPoint = "/bx_block_dashboard/page_titles/:id?name=Customer_payment";

exports.reviewCmsApiMethod = "GET";
exports.reviewCmsApiContentType = "application/json";
exports.reviewCmsApiEndPoint = "/bx_block_dashboard/page_titles/:id?name=Review_payment";
// Customizable Area End