import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loginURL : string ;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class TestControllerController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  theoryLectureUrlApiCallID: any ;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loginURL : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  // Customizable Area Start
  async componentDidMount() {
    // this.getTheoryLecture();
    this.getTheoryLectureURL()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("response : " + JSON.stringify(message), "meesage");
    runEngine.debugLog("Message Recived", message);

if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  console.log("line 321", apiRequestCallId);
  var responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  var errorReponse = message.getData(
    getName(MessageEnum.RestAPIResponceErrorMessage)
  );
  if (responseJson && !responseJson.errors) {
    if (apiRequestCallId === this.theoryLectureUrlApiCallID) {
      this.getTheoryLectUrlSuccessCallBack(responseJson)
    }


  } else if (responseJson && responseJson.errors) {
    if (apiRequestCallId === this.theoryLectureUrlApiCallID) {
      this.getTheoryLectUrlFailureCallBack(responseJson)
    }

  } else if (errorReponse) {
    console.log('errorReponse', errorReponse)
  }

}
// Customizable Area End
  }

  getTheoryLectUrlFailureCallBack(error: any) {
    console.log("@@@@@@ get failure respone", error)
  }
  getTheoryLectUrlSuccessCallBack(responseJson: any) {
    console.log("@@@@@@ get url success respone", responseJson)
    this.setState({loginURL:responseJson.login_url})

  }

  apiCall = async (data: any) => {
    // let token = await StorageProvider.get("TOKEN");
    let token = localStorage.getItem("authToken")
    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

  : requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;
  }

  getTheoryLectureURL = async () => {

    this.theoryLectureUrlApiCallID = await this.apiCall({
      contentType:"application/json",
      method: 'GET',
      endPoint: 'api/talent_lms/accounts/login_url',
    });
  }

  // Customizable Area End

}
