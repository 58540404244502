import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export const baseurl = require('../../../../packages/framework/src/config');

export interface Course {
  cancel_hours: string;
  class_timings: string;
  course_name: string;
  edc_fees: string;
  rta_fees: string;
  total_fees: string;
  training_days: string;
  training_fees_per_hour: string;
  training_hours: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  history: any;
  match: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any
  // Customizable Area Start
  course: any;
  loading: boolean;
  tab: any;
  courseInfo: any;
  courseFees: Course[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    let data = this.props.location;
    this.setState({
      course: data?.state
    });
    this.getCourseInformation();
  }

  async componentDidUpdate() {
    let data = this.props.location;
    if (data && data.state !== this.state.course) {
      window.scrollTo(0, 0);
      this.getCourseInformation();
      this.setState({
        course: data.state
      });
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      loading: true,
      data: "",
      // Customizable Area Start
      course: "",
      tab: "Course information",
      courseInfo: [],
      courseFees:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getCourseInformation = () => {
    fetch(`${baseurl.baseURL}/bx_block_user_profile/vehicle_types/vehicle_detail?vehicle_type=${this.props.location.state.name}`).then((data) => {
      data.json().then((res: any) =>
        this.setState({
          courseInfo: res.vehicle_details,
          courseFees: res.course_fees,
          loading: false
        }));
    }).catch((error) => {
      this.setState({ loading: false });
    });
  }

  // Customizable Area End
}
