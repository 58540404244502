import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
// Customizable Area End

import HelpAndFAQController, { Props } from "./HelpAndFAQController.web";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion {...props} />
))({
  paddingRight: "14px",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  borderRadius: "10px !important",
  padding: 10,
  marginBottom: 20,
  "& .MuiCollapse-root": {
    padding: "0px 15px !important"
  },
  '&:before': {
    backgroundColor: "white"
  },
});

export default class HelpAndFAQ extends HelpAndFAQController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="faq common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section pb70" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/faq.png')` }}>
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Help and FAQ"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Help and Frequently Asked Questions"}</h5>
          </div>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%">
              {!this.state.loading &&
                <>
                  {this.state.faqArr.length !== 0 ?
                    this.state.faqArr.map((item: any, index: number) => {
                      return <StyledAccordion expanded={this.state.current === index} onChange={(event: React.ChangeEvent<{}>, expanded: boolean) => this.handleChange(index, expanded)}>
                        <AccordionSummary style={{ fontSize: 18, fontWeight: 700 }}>
                          {item.question}
                        </AccordionSummary>
                        <AccordionDetails style={{ fontSize: 16, display: "block", padding: 0 }}>
                          <div>
                            {item.answers.map((ans: string, index: number) => {
                              return <p style={{ marginBottom: "10px" }}>{ans}</p>
                            })}
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                    })
                    : <Box textAlign="center">Data not found!</Box>
                  }
                </>
              }
            </Box>
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
