import React, { Component } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import styled from "styled-components";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiVisaLine } from "react-icons/ri";
import { VscCreditCard } from "react-icons/vsc";
import { AiOutlineStar } from "react-icons/ai";
import { Button, Box, Dialog, DialogActions, DialogTitle, } from "@material-ui/core";
import { withRouter } from "react-router";
import PaymentMethodController, { Props } from "./PaymentMethodController";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
import { toast } from "react-toastify";
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js"

// const stripePromis = loadStripe(
//     'pk_test_51KyFpyHdR4l61Wz0Chmlm4Q80ZG7tPcn7AU9OlFR3eG1MR0TotsDVtqUUEr3XZxMR2lE1vmApPCFGNTq9IP2dHqA00jJApUKvv'
// );
class FailedPayment extends PaymentMethodController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        toast.error("Payment is failed!");
    }

    onButtonClick = () => {
        this.setState({ open: false });
        this.props.navigation.navigate("Home");
        localStorage.clear();
    }

    render() {
        return (
            <CommonWrapper className="document payment-method common">
                <div
                    className="first-section"
                    style={{
                        backgroundImage: this.state.CmsData.image
                            ? `url(${this.state.CmsData.image})`
                            : `url('assets/payment-method.png')`,
                    }}
                >
                    <div>
                        <div className="content">
                            <h2 className="title">
                                {this.state.CmsData.title
                                    ? this.state.CmsData.title
                                    : "Payment method"}
                            </h2>
                            <h5 className="sub-title">
                                {this.state.CmsData.sub_title
                                    ? `${this.state.CmsData.sub_title}`
                                    : "Select any payment method from below"}
                            </h5>
                        </div>
                    </div>
                    <Box
                        position="absolute"
                        right={{ xs: "20px", sm: "30px", md: "50px" }}
                        bottom={{ xs: "20px", sm: "30px", md: "50px" }}
                    >
                        <img src={"assets/images/whatapp.png"} className="whatapp-img" />
                    </Box>
                </div>
                <section className="mt80 pb60">
                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="payment-block">
                                            <div>
                                                <div
                                                    className="payment-stretch-block"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        marginBottom: "50px",
                                                    }}
                                                >
                                                    <div>
                                                        <RiVisaLine style={{ fontSize: "70px" }} />
                                                    </div>
                                                    <div className="custom-checkbox">
                                                        <input
                                                            name="paybtcard"
                                                            id="paybtcard"
                                                            checked={this.state.data === "card"}
                                                            onChange={(e: any) => {
                                                                if (e.target.checked === false) {
                                                                    this.setState({ data: "" });
                                                                } else {
                                                                    this.setState({ data: e.target.value });
                                                                }
                                                            }}
                                                            type="checkbox"
                                                            value="card"
                                                        />
                                                        <label htmlFor="paybtcard" />
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="title">Pay by Card</h4>
                                            <h6 className="cost-payment">
                                                You can pay with credit or debit cards
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="payment-block">
                                            <div>
                                                <div
                                                    className="payment-stretch-block"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        marginBottom: "50px",
                                                    }}
                                                >
                                                    <VscCreditCard style={{ fontSize: "70px" }} />
                                                    <div className="custom-checkbox">
                                                        <input
                                                            id="payatcenter"
                                                            name="payatcenter"
                                                            checked={this.state.data === "center"}
                                                            onChange={(e: any) => {
                                                                if (e.target.checked === false) {
                                                                    this.setState({ data: "" });
                                                                } else {
                                                                    this.setState({ data: e.target.value });
                                                                }
                                                            }}
                                                            type="checkbox"
                                                            value="center"
                                                        />
                                                        <label htmlFor="payatcenter" />
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="title">Pay at Center</h4>
                                            <h6 className="cost-payment">Meet and Pay</h6>
                                        </div>
                                        <div className="paybutton_div">
                                            <button
                                                className="pay_button"
                                                style={{
                                                    opacity: this.state.data === "" ? '0.5' : '1',
                                                    cursor: this.state.data === "" ? "default" : "pointer"
                                                }}
                                                disabled={this.state.data === "" ? true : false}
                                                onClick={() => this.onPayButtonClick()}
                                            >
                                                Proceed to Pay
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <Dialog
                    open={this.state.open}
                    onClose={() => { }}
                    PaperProps={{ style: { borderRadius: 15, padding: 20 } }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Hello, You may proceed to your nearest branch to pay and complete the registration process. Thank You!
                    </DialogTitle>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button onClick={() => this.onButtonClick()} style={{ backgroundColor: "black", color: "white", padding: "7px 20px" }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </CommonWrapper>
        );
    }
}

export default FailedPayment;

const CommonWrapper = styled.main``;

const Container = styled.div`
  width: 80%;
  margin: auto;
  .title {
    font-size: 26px;
    color: white;
    font-weight: "500";
  }
`;
