import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
export const configJSON = require("./config");
export const baseurl = require("../../../../packages/framework/src/config");
// Customizable Area End
export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  history: any;
  match: any;
}
export interface Course {
  cancel_hours: string;
  class_timings: string;
  course_name: string;
  edc_fees: string;
  rta_fees: string;
  total_fees: string;
  training_days: string;
  training_fees_per_hour: string;
  training_hours: string;
}
export interface CourseDetail {
  vehicle_type: string;
  description: string[];
  courses: Course[];
}
export interface S {
  // Customizable Area Start
  CmsData: any;
  coursesDetail: CourseDetail[] | null;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllCourseController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  allCoursesDetailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      CmsData: "",
      coursesDetail: null,
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.allCoursesDetailApiCallId) {
          if (responseJson?.status === 500) {
            toast.error("Something went wrong");
          } else {
            this.handleSuccessResponse(responseJson);
          }
        }
      }
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSuccessResponse = (responseJson: { data: CourseDetail[] }) => {
    let data = responseJson.data;

    data = data.reduce((r: any, a: any) => {
      r[a.attributes.vehicle_type] = {
        vehicle_type: a.attributes.vehicle_type,
        description: a.attributes.description,
        courses: r[a.attributes.vehicle_type]?.courses || []
      };

      r[a.attributes.vehicle_type].courses.push({
        course_name: a.attributes.course_name,
        total_fees: a.attributes.total_fees,
        training_days: a.attributes.training_days,
        training_hours: a.attributes.training_hours,
        cancel_hours: a.attributes.cancel_hours,
        class_timings: a.attributes.class_timings,
        training_fees_per_hour: a.attributes.training_fees_per_hour,
        rta_fees: a.attributes.rta_fees,
        edc_fees: a.attributes.edc_fees
      });

      return r;
    }, Object.create(null));

    this.setState({ coursesDetail: data });
  }

  getCoursesDetail = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.allCourseContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allCoursesDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCourseDetailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allCourseApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCmsApi() {
    const fetchUrl: any = `${baseurl.baseURL}/bx_block_dashboard/page_titles/:id?name=All_courses`;
    fetch(`${fetchUrl}`)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          CmsData: ResponseJson?.data?.attributes,
        });
      })
      .catch((error) => console.log(error, "AllCourses page"));
  }
  // Customizable Area End
}
