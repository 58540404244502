import React from "react";

// Customizable Area Start
import { withRouter } from "react-router";
import styled from "styled-components";
import { Box, Divider, Grid, Button } from "@material-ui/core";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdOutlineMenuBook } from 'react-icons/md';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { RiFileList2Line, RiMotorbikeFill } from 'react-icons/ri';
import { AiOutlineStar, AiOutlineDollar, AiFillCheckCircle } from "react-icons/ai";

import Spinner from "../../../components/src/Spinner.web";
export const baseurl = require('../../../../packages/framework/src/config');
const MoterImg = require('../../../web/public/assets/images/vehicle_detail.png');
// Customizable Area End

import CourseDetailController, { Props, Course } from "./CourseDetailController.web";

class CourseDetailsNew extends CourseDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    onNavigateToRegister = () => {
        this.props.history.push("/EmailAccountRegistration");
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <CommonWrapper className="document common">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <div className="first-section" style={{ backgroundImage: `url("../assets/course/${this.state.course?.name}.jpg")` }}>
                    <div>
                        <div className="content">
                            <h2 className="title">{this.state.course?.name}</h2>
                            <h5 className="sub-title">Detailed information of {this.state.course?.name} driving course</h5>
                        </div>
                    </div>
                    <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
                        <img src={"assets/images/whatapp.png"} className="whatapp-img" />
                    </Box>
                </div>
                <section className="mt80 pb60">
                    <Container className="uparRow">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="TabsContainer">
                                    <div style={{ display: "flex" }}>
                                        <div
                                            onClick={() => this.setState({ tab: "Course information" })}
                                            className={this.state.tab === "Course information" ? "IconContainerActive" : "IconContainerNotActive"}
                                        >
                                            <MdOutlineMenuBook />
                                            <h6>Course information</h6>
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            onClick={() => this.setState({ tab: "Documents Required" })}
                                            className={this.state.tab === "Documents Required" ? "IconContainerActive" : "IconContainerNotActive"}
                                        >
                                            <IoDocumentTextOutline />
                                            <h6>Documents Required</h6>
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            onClick={() => this.setState({ tab: "Course Fees" })}
                                            className={this.state.tab === "Course Fees" ? "IconContainerActive" : "IconContainerNotActive"}
                                        >
                                            <AiOutlineDollar />
                                            <h6>Course Fees</h6>
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            onClick={() => this.setState({ tab: "Training Details" })}
                                            className={this.state.tab === "Training Details" ? "IconContainerActive" : "IconContainerNotActive"}
                                        >
                                            <RiFileList2Line />
                                            <h6>Training Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.tab === "Course information" &&
                            <>
                                <h1>{this.state.tab}</h1>
                                <>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} md={6}>
                                            <div className="coursePading">
                                                <div className="CourseIcon">
                                                    <RiMotorbikeFill />
                                                </div>
                                                <div>
                                                    <div className="CourseHeading">
                                                        <h6>{this.state?.courseInfo[0]?.heading}</h6>
                                                    </div>
                                                    <div className="CourseDetails">
                                                        {this.state.loading === false && this.state.courseInfo[0].points.map((item: any) => {
                                                            return (
                                                                <span>
                                                                    {item}
                                                                </span>
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid className="courseImg" item xs={12} md={6}>
                                            {this.state.loading === false &&
                                                <img
                                                    // @ts-ignore
                                                    src={this.state.courseInfo[0].image === null ? MoterImg : baseurl.baseURL + this.state.courseInfo[0].image} onError={(e: any) => {
                                                        e.target.src = MoterImg;
                                                    }} alt="img" />
                                            }
                                        </Grid>
                                    </Grid><Grid container alignItems="center" direction="row-reverse" justifyContent="center">
                                        <Grid item xs={12} md={6}>
                                            <div className="containerCOurs coursePading">
                                                <div className="points">
                                                    <div>
                                                        <div className="CourseHeading">
                                                            <h6>{this.state?.courseInfo[1]?.heading}</h6>
                                                        </div>
                                                        <div className="CourseDetails">
                                                            {this.state.loading === false && this.state.courseInfo[1].points.map((item: any) => {
                                                                return (
                                                                    <div className="iconAndDetails">
                                                                        <div>
                                                                            <AiFillCheckCircle />
                                                                        </div>
                                                                        <span>{item}</span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid className="courseImg" item xs={12} md={6}>
                                            {this.state.loading === false &&
                                                <img
                                                    // @ts-ignore
                                                    src={this.state.courseInfo[1].image === null ? MoterImg : baseurl.baseURL + this.state.courseInfo[1].image} onError={(e: any) => {
                                                        e.target.src = MoterImg;
                                                    }} alt="img" />
                                            }
                                        </Grid>
                                    </Grid><Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} md={6}>
                                            <div className="coursePading">
                                                {console.log(this.state, "this.state")}
                                                <div>
                                                    <div className="CourseHeading">
                                                        <h6>{this.state?.courseInfo[2]?.heading}</h6>
                                                    </div>
                                                    <div className="CourseDetails">
                                                        {this.state.loading === false && this.state.courseInfo[2].points.map((item: any) => {
                                                            return (
                                                                <div className="iconAndDetails">
                                                                    <div>
                                                                        <AiFillCheckCircle />
                                                                    </div>
                                                                    <span>{item}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid className="courseImg" item xs={12} md={6}>
                                            {this.state.loading === false &&
                                                <img
                                                    // @ts-ignore
                                                    src={this.state.courseInfo[2].image === null ? MoterImg : baseurl.baseURL + this.state.courseInfo[2].image} onError={(e: any) => {
                                                        e.target.src = MoterImg;
                                                    }} alt="img" />
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            </>
                        }
                        {this.state.tab === "Documents Required" &&
                            <>
                                <h1>{this.state.tab}</h1>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="coursePading">
                                            <div className="CourseIcon">
                                                <RiMotorbikeFill />
                                            </div>
                                            <div>
                                                <div className="CourseHeading">
                                                    <h6>Course Overview</h6>
                                                </div>
                                                <div className="CourseDetails">
                                                    <span>
                                                        Eco Drive provides quality motorcycle riding courses for learners looking to secure a motorcycle license. Eco Drive’s motorcycle riding courses provide you with the right knowledge and technical expertise to make your riding experience hassle-free and enjoyable.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid className="courseImg" item xs={12} md={6}>
                                        <img src={MoterImg} alt="img" />
                                    </Grid>
                                </Grid><Grid container alignItems="center" direction="row-reverse" justifyContent="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="containerCOurs coursePading">
                                            <div>
                                                <div>
                                                    <div className="CourseHeading">
                                                        <h6>What You Will Learn</h6>
                                                    </div>
                                                    <div className="CourseDetails">
                                                        <div className="iconAndDetails">
                                                            <AiFillCheckCircle />
                                                            <span>Balnace</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid className="courseImg" item xs={12} md={6}>
                                        <img src={MoterImg} alt="img" />
                                    </Grid>
                                </Grid><Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="coursePading">
                                            <div>
                                                <div className="CourseHeading">
                                                    <h6>Important Information</h6>
                                                </div>
                                                <div className="CourseDetails">
                                                    <span>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint porro officiis possimus deleniti, praesentium reprehenderit nisi cupiditate similique nobis totam nostrum! Odit, ipsa esse minus atque assumenda possimus ab veritatis.
                                                    </span>
                                                    <div className="iconAndDetails">
                                                        <AiFillCheckCircle />
                                                        <span>Balnace</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid className="courseImg" item xs={12} md={6}>
                                        <img src={MoterImg} alt="img" />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {this.state.tab === "Course Fees" &&
                            <>
                                <h1>{this.state.tab}</h1>
                                <div className="row">
                                    {this.state.courseFees.map((course: Course) => {
                                        return (
                                            <div className="col-sm-12 col-md-6 col-lg-4">
                                                <div className="all-courses-cards">
                                                    <div className="course-rating-star">
                                                        <AiOutlineStar />
                                                    </div>
                                                    <div className="all-courses-header">
                                                        <h3>{course.course_name}</h3>
                                                        <p>AED {course.total_fees}</p>
                                                        <span>(5% VAT applicable on Excellence Fees)</span>
                                                    </div>
                                                    <div className="discription-boxes">
                                                        <div className="discription-box1">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                        <div className="discription-box2">
                                                            <h5>Training Days * </h5>
                                                            <span>{course.training_days}</span>
                                                        </div>
                                                    </div>
                                                    <div className="discription-boxes">
                                                        <div className="discription-box1">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                        <div className="discription-box2">
                                                            <h5>Training Hours per week *</h5>
                                                            <span>{course.training_hours}</span>
                                                        </div>
                                                    </div>
                                                    <div className="discription-boxes">
                                                        <div className="discription-box1">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                        <div className="discription-box2">
                                                            <h5>Cancellation/Re-scheduling</h5>
                                                            <span>{course.cancel_hours}</span>
                                                        </div>
                                                    </div>
                                                    <div className="discription-boxes">
                                                        <div className="discription-box1">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                        <div className="discription-box2">
                                                            <h5>Class Timings</h5>
                                                            <span>{course.class_timings}</span>
                                                        </div>
                                                    </div>
                                                    <div className="discription-boxes">
                                                        <div className="discription-box1">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                        <div className="discription-box2">
                                                            <h5>Training fees per hour</h5>
                                                            <span>{course.training_fees_per_hour}</span>
                                                        </div>
                                                    </div>
                                                    <div className="discription-boxes">
                                                        <div className="discription-box1">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                        <div className="discription-box2">
                                                            <h5>Fee Details</h5>
                                                            <div>RTA Fees - {course.rta_fees}</div>
                                                            <div>EDC Fees - {course.edc_fees} + 5% VAT</div>
                                                            <div>Total Fees - {course.total_fees}</div>
                                                        </div>
                                                    </div>
                                                    <button onClick={this.onNavigateToRegister}>Choose {course.course_name}</button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        }
                        {this.state.tab === "Training Details" &&
                            <>
                                <h1>{this.state.tab}</h1>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="coursePading">
                                            <div className="CourseIcon">
                                                <RiMotorbikeFill />
                                            </div>
                                            <div>
                                                <div className="CourseHeading">
                                                    <h6>Course Overview</h6>
                                                </div>
                                                <div className="CourseDetails">
                                                    <span>
                                                        Eco Drive provides quality motorcycle riding courses for learners looking to secure a motorcycle license. Eco Drive’s motorcycle riding courses provide you with the right knowledge and technical expertise to make your riding experience hassle-free and enjoyable.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid className="courseImg" item xs={12} md={6}>
                                        <img src={MoterImg} alt="img" />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" direction="row-reverse" justifyContent="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="containerCOurs coursePading">
                                            <div>
                                                <div>
                                                    <div className="CourseHeading">
                                                        <h6>What You Will Learn</h6>
                                                    </div>
                                                    <div className="CourseDetails">
                                                        <div className="iconAndDetails">
                                                            <AiFillCheckCircle />
                                                            <span>Balnace</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid className="courseImg" item xs={12} md={6}>
                                        <img src={MoterImg} alt="img" />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="coursePading">
                                            <div>
                                                <div className="CourseHeading">
                                                    <h6>Important Information</h6>
                                                </div>
                                                <div className="CourseDetails">
                                                    <span>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint porro officiis possimus deleniti, praesentium reprehenderit nisi cupiditate similique nobis totam nostrum! Odit, ipsa esse minus atque assumenda possimus ab veritatis.
                                                    </span>
                                                    <div className="iconAndDetails">
                                                        <AiFillCheckCircle />
                                                        <span>Balance</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid className="courseImg" item xs={12} md={6}>
                                        <img src={MoterImg} alt="img" />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Container>
                    <Grid container className="Footerbanner">
                        <Grid item className="readyImgs" style={{ background: `url(${MoterImg})`, backgroundSize: "cover" }} xs={12} md={6} />
                        <Grid className="readyImgs" item xs={12} md={6} style={{ background: 'black', backgroundSize: "cover" }}>
                            <div className="readToGo">
                                <div className="readyBox">
                                    <div>
                                        <h2>Ready to go</h2>
                                    </div>
                                    <div className="readDec">
                                        <div>
                                            <span>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit consectetur adipisicing elit
                                            </span>
                                        </div>
                                    </div>
                                    <div className="regiterBtn">
                                        <Button className="Btn_Rigert" onClick={() => this.onNavigateToRegister()}>Register now</Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </section>
            </CommonWrapper>
            // Customizable Area End
        );
    }
}

export default withRouter(CourseDetailsNew);

// Customizable Area Start

const CommonWrapper = styled.main({
    '& .mt80': {
        marginTop: "20px !important"
    },

    '& .readyImgs': {
        position: 'relative',
        objectFit: "cover",
        width: '100%',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "350px",
    },
    '& .readyBox': {
        padding: '0 20px',
    },
    '& .readToGo': {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        width: '100%',
        alignItems: "center",
        color: "#ffff",
        h2: {
            textAlign: 'center'
        },
        '& .readDec': {
            textAlign: 'center',
            padding: '0 20px',
            span: {
                fontSize: "14px",
            }
        },
        '& .Btn_Rigert': {
            color: '#ffff',
            borderRadius: "20px",
            width: "50%",
            border: "1px solid white",
            span: {
                color: '#ffff'
            }
        },
        '& .regiterBtn': {
            display: 'flex',
            justifyContent: "center",
            padding: '20px 0',
        },
    },
    '@media only screen and (max-width: 768px)': {
        '& .uparRow': {
            width: "90%"
        }
    },
    '@media only screen and (max-width: 430px)': {
        '& .Footerbanner': {
            paddingTop: '20px',
        },
        '& .readyImgs': {
            minHeight: "180px"
        },
        '& .readyBox': {
            padding: 0
        }
    }
});

const Container = styled.div({
    width: '80%',
    margin: 'auto',
    '& .TabsContainer': {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
    },
    '& .IconContainerActive ': {
        padding: '10px 4px',
        textAlign: 'center',
        color: "black",
        fontWeight: "800",
        cursor: 'pointer',
        transition: 'ease-in 200ms',
        svg: {
            height: '30px',
            width: '30px',
        },
        h6: {
            padding: '4px 0',
            fontWeight: "800"
        }
    },
    '& .IconContainerNotActive ': {
        padding: '10px 4px',
        textAlign: 'center',
        color: "grey",
        fontWeight: "800",
        cursor: 'pointer',
        transition: 'ease-in 200ms',
        svg: {
            height: '30px',
            width: '30px',
        },
        h6: {
            padding: '4px 0',
        }
    },
    '& .CourseIcon': {
        padding: ' 0 0 10px 0',
        svg: {
            height: '60px',
            width: '60px',
        }
    },
    '& .CourseHeading': {
        h6: {
            fontWeight: 'bolder'
        }
    },
    '& .CourseDetails': {
        paddingRight: '20px',
        h6: {
            lineHeight: 1.4
        },
        span: {
            fontSize: '14px'
        }
    },
    '& .courseImg': {
        img: {
            width: '100%',
            minHeight: "300px"
        }
    },
    '& .containerCOurs': {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        '& .points': {
            width: "80%"
        },
    },
    '& .iconAndDetails': {
        display: 'flex',
        alignItems: "flex-start",
        '& div > svg': {
            verticalAlign: "inherit"
        },
        span: {
            paddingLeft: '10px',
        }
    },
    '@media only screen and (max-width: 430px)': {
        width: '90%',
        '& .MuiDivider-vertical': {
            display: 'none',
        },
        '& .IconContainer': {
            padding: '10px',
        },
        '& .coursePading': {
            padding: '12px 0',
            '& .points': {
                width: "100%",
            },
        },
        '& .CourseDetails': {
            padding: 0,
        },
        '& .courseImg': {
            img: {
                minHeight: '160px'
            }
        }
    }
});

// Customizable Area End
