import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SiTwitter } from "react-icons/si";
import { withRouter } from "react-router";
import { SiInstagram } from "react-icons/si";
import { SiFacebook } from "react-icons/si";
import { SiLinkedin } from "react-icons/si";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { MdPhoneInTalk } from "react-icons/md";
import { baseURL } from "../../framework/src/config";

function Footer() {
  const [CmsData, setCmsData] = useState([]);
  const [CmsDataDoor, setCmsDataDoor] = useState([]);

  const getCmsApi = () => {
    const fetchUrl = `${baseURL}/bx_block_dashboard/contact_infos`;
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        if (ResponseJson && ResponseJson.data) {
          setCmsData(ResponseJson?.data[0]?.attributes);
        }
      })
      .catch((error) => console.log(error, "AllCourses page"));
  };

  const getCmsApiSocical = () => {
    const fetchUrl = `${baseURL}/bx_block_contact_us/social_links`;
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        if (ResponseJson && ResponseJson.data) {
          setCmsDataDoor(ResponseJson.data);
        }
      })
      .catch((error) => console.log(error, "AllCourses page"));
  };

  useEffect(() => {
    getCmsApi();
    getCmsApiSocical();
  }, []);

  return (
    <FooterWrapper>
      <FooterTop className="commonspacing">
        <div className="row">
          <div className="col-sm-12">
            <div className="footer-info-cons">
              <a
                className="UpperLink"
                href={`http://maps.google.com/?q=${CmsData.address}`}
                target="_blank"
              >
                <div className="footer-wrapper">
                  <div className="footer-icon-wrap">
                    <HiOutlineLocationMarker />
                  </div>
                  <div className="footer-text-wrap">
                    <span className="custom_opacity_07">ADDRESS</span>
                    <h5>
                      <b>
                        {CmsData.email
                          ? CmsData.address
                          : "Industrial City, Dubai, UAE"}
                      </b>
                    </h5>
                  </div>
                </div>
              </a>
              <a href={`mailto:${CmsData.email}`} className="UpperLink">
                <div className="footer-wrapper">
                  <div className="footer-icon-wrap">
                    <HiOutlineMail />
                  </div>
                  <div className="footer-text-wrap">
                    <span className="custom_opacity_07">EMAIL</span>
                    <h5>
                      <b>
                        {CmsData.email ? CmsData.email : "info@ecodrive.ae"}
                      </b>
                    </h5>
                  </div>
                </div>
              </a>
              <a href={`tel:+${CmsData.phone_number}`} className="UpperLink">
                <div className="footer-wrapper">
                  <div className="footer-icon-wrap">
                    <MdPhoneInTalk />
                  </div>
                  <div className="footer-text-wrap">
                    <span className="custom_opacity_07">CALL</span>
                    <h5>
                      <b>
                        {CmsData.phone_number
                          ? CmsData.phone_number
                          : "600 595 959"}
                      </b>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-lg-2">
            <div className="SvgChnage">
              <img className="logo-footer" src="assets/logo/logo.svg" alt="" />
            </div>
          </div>
          <div className="col-sm-3">
            <h4 className="footer-head-titles w_1">QUICKLINKS</h4>
            <div className="footer-cols">
              <div className="row">
                <div className="col-sm-12 col-lg-5">
                  <ul>
                    <li>
                      <Link to="/About">About Us</Link>
                    </li>
                    <li>
                      <Link to="/NewsAndEvent">News</Link>
                    </li>
                    <li>
                      <Link to="#">Facilities</Link>
                    </li>
                    <li>
                      <Link to="/FAQ">FAQs</Link>
                    </li>
                    <li>
                      <Link to="#">Careers</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-lg-7">
                  <ul>
                    <li>
                      <Link to="/Documents">Documents</Link>
                    </li>
                    <li>
                      <Link to="#">Feedback</Link>
                    </li>
                    <li>
                      <Link to="/TermsOfUse">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/ContactUs">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <h4 className="footer-head-titles w_2">COURSES</h4>
            <div className="footer-cols">
              <ul>
                <li>
                  <Link
                    to={{ pathname: `/CourseDetail`, state: { name: "Car" } }}
                  >
                    Car Course
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/CourseDetail`,
                      state: { name: "Motor Bike" },
                    }}
                  >
                    Motorcycle Course
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/CourseDetail`,
                      state: { name: "Heavy Bus" },
                    }}
                  >
                    Heavy Bus Course
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/CourseDetail`,
                      state: { name: "Heavy Truck" },
                    }}
                  >
                    Heavy Truck Course
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/CourseDetail`,
                      state: { name: "Heavy Forklift" },
                    }}
                  >
                    Forklift Course
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-3">
            <h4 className="footer-head-titles w_3">Download the App</h4>
            <div className="footer-logos mobile_logos">
              <img src="assets/logo/app_store.png" alt="" />
              <img src="assets/logo/google_play.png" alt="" />
            </div>
          </div>
        </div>
      </FooterTop>
      <FooterBottom>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-8 m-auto">
              <div className="footer-social-wrappers">
                {CmsDataDoor.length !== 0 ? (
                  CmsDataDoor?.map((item, index) => {
                    return (
                      <div className="footer-social-icons" key={index}>
                        <a
                          href={item?.attributes?.link}
                          target="_blank"
                          style={{ color: "#fff" }}
                        >
                          <img src={item?.attributes?.image} />
                          <span>{item?.attributes?.name}</span>
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="footer-social-icons">
                      <a href="#">
                        <SiTwitter />
                        TWITTER
                      </a>
                    </div>
                    <div className="footer-social-icons">
                      <a href="#">
                        <SiLinkedin />
                        LINKEDIN
                      </a>
                    </div>
                    <div className="footer-social-icons">
                      <a href="#">
                        <SiFacebook />
                        FACEBOOK
                      </a>
                    </div>
                    <div className="footer-social-icons">
                      <a href="#">
                        <SiInstagram />
                        INSTAGRAM
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </FooterBottom>
    </FooterWrapper>
  );
}

// styles

const FooterWrapper = styled.footer({
  background: "#f8f8f8",
  "& a.UpperLink": {
    color: "unset",
    ":hover": {
      textDecoration: "none",
      color: "unset",
    },
  },
});

const FooterTop = styled.section``;

const FooterBottom = styled.section`
  background: #151817;
  padding: 20px 0px;
`;

export default withRouter(Footer);
