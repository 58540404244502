import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import axios from "axios";

// Customizable Area Start
const { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    detail: any;
    loading: boolean;
    documentArr: any[];
    preview: any;
    btnText: any,
    apicallText: any
    eye_test_avatar_url: any,
    noc_avatar_url: any,
    passport_avatar_url: any,
    photo_avatar_url: any,
    residency_visa_copy_avatar_url: any,
    emirate_id_avatar_url: any,
    learing_permit_avatar_url: any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserNotificationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    documentApiCallId: string = "";
    getDetailApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            detail: "",
            loading: false,
            documentArr: [],
            preview: false,
            btnText: "Eye Test Document",
            apicallText: "eye_test_avatar",
            eye_test_avatar_url: null,
            noc_avatar_url: null,
            passport_avatar_url: null,
            photo_avatar_url: null,
            residency_visa_copy_avatar_url: null,
            emirate_id_avatar_url: null,
            learing_permit_avatar_url: null
            // Customizable Area End
        };
    }
    DocumentShow = (e: any, text: any) => {
        const uploadFIle = e.target as any
        if (text === "Eye Test Document") {
            this.setState((e) => ({
                ...e, eye_test_avatar_url: uploadFIle.files[0]
            }))
        }
        else if (text === "Learing permit") {
            this.setState((e) => ({
                ...e, learing_permit_avatar_url: uploadFIle.files[0]
            }))
        }
        else if (text === "NOC") {
            this.setState((e) => ({
                ...e, noc_avatar_url: uploadFIle.files[0]
            }))
        }
        else if (text === "Passport") {
            this.setState((e) => ({
                ...e, passport_avatar_url: uploadFIle.files[0]
            }))
        }
        else if (text === "Photo") {
            this.setState((e) => ({
                ...e, photo_avatar_url: uploadFIle.files[0]
            }))
        }
        else if (text === "Residence Visa Copy") {
            this.setState((e) => ({
                ...e, residency_visa_copy_avatar_url: uploadFIle.files[0]
            }))
        }
        else if (text === "Emirate ID") {
            this.setState((e) => ({
                ...e, emirate_id_avatar_url: uploadFIle.files[0]
            }))
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson && responseJson.data) {
                if (apiRequestCallId === this.documentApiCallId) {
                    this.setState({ documentArr: responseJson.data });

                } else if (apiRequestCallId === this.getDetailApiCallId) {
                    this.setState({ detail: responseJson?.data?.attributes });
                } else if (apiRequestCallId === this.uploadApi) {
                    if (responseJson) {
                        this.ShowDocuments();
                        toast.success(`${this.state.btnText.charAt(0).toUpperCase() + this.state.btnText.slice(1)} successfully Uploaded!`);
                        this.setState({
                            ...this.state, loading: false
                        })
                    }
                } else if (apiRequestCallId === this.ShowUploadDocument) {
                    this.setState({
                        ...this.state, eye_test_avatar_url: responseJson?.data?.attributes?.eye_test_avatar_url,
                        learing_permit_avatar_url: responseJson?.data?.attributes?.learing_permit_avatar_url,
                        noc_avatar_url: responseJson?.data?.attributes?.noc_avatar_url,
                        passport_avatar_url: responseJson?.data?.attributes?.passport_avatar_url,
                        photo_avatar_url: responseJson?.data?.attributes?.photo_avatar_url,
                        residency_visa_copy_avatar_url: responseJson?.data?.attributes?.residency_visa_copy_avatar_url,
                        emirate_id_avatar_url: responseJson?.data?.attributes?.emirate_id_avatar_url
                    })
                } else if (apiRequestCallId === this.DeletDoc) {
                    this.ShowDocuments()
                    toast.success(`${this.state.btnText.charAt(0).toUpperCase() + this.state.btnText.slice(1)} deleted Successfully!`)
                    this.setState((e) => ({
                        ...e, preview: false
                    }))
                    if (apiRequestCallId === this.ShowUploadDocument) {
                        this.setState({
                            ...this.state, eye_test_avatar_url: responseJson?.data?.attributes?.eye_test_avatar_url,
                            learing_permit_avatar_url: responseJson?.data?.attributes?.learing_permit_avatar_url,
                            noc_avatar_url: responseJson?.data?.attributes?.noc_avatar_url,
                            passport_avatar_url: responseJson?.data?.attributes?.passport_avatar_url,
                            photo_avatar_url: responseJson?.data?.attributes?.photo_avatar_url,
                            residency_visa_copy_avatar_url: responseJson?.data?.attributes?.residency_visa_copy_avatar_url,
                            emirate_id_avatar_url: responseJson?.data?.attributes?.emirate_id_avatar_url
                        })
                    }
                }
            } else {
                this.parseApiErrorResponse(responseJson);
            }
            this.setState({ loading: false });
        }
        this.parseApiCatchErrorResponse(errorReponse);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getDetails();
        this.getDocument();
        this.ShowDocuments()
    }

    getDocument = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.contentTypeApiDocument,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.documentApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_documentation/user_documents"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDocument
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    uploadApi: any = "";
    ShowUploadDocument: any = "";
    DeletDoc: any = ""

    StateChange = (item: any) => {
        if (item === "Eye Test Document") {
            this.setState((e) => ({
                ...e, apicallText: "eye_test_avatar",
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));
        }
        else if (item === "Learing permit") {
            this.setState((e) => ({
                ...e, apicallText: "learing_permit_avatar"
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));
        }
        else if (item === "NOC") {
            this.setState((e) => ({
                ...e, apicallText: "noc_avatar"
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));
        }
        else if (item === "Passport") {
            this.setState((e) => ({
                ...e, apicallText: "passport_avatar"
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));
        }
        else if (item === "Photo") {
            this.setState((e) => ({
                ...e, apicallText: "photo_avatar"
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));

        }
        else if (item === "Residence Visa Copy") {
            this.setState((e) => ({
                ...e, apicallText: "residency_visa_copy_avatar"
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));
        }
        else if (item === "Emirate ID") {
            this.setState((e) => ({
                ...e, apicallText: "emirate_id_avatar"
            }));
            this.setState((e) => ({
                ...e, btnText: item,
            }));
        }
    }

    UploadDocument = async (e: any) => {
        if (e.target.files.length !== 0) {

            this.setState({ loading: true });
            const localToken = localStorage.getItem("authToken") as string
            const header = {
                token: localToken,
            };

            let formData = new FormData();
            // formData.append("eye_test_avatar", e.target.files[0])
            formData.append(this.state.apicallText, e.target.files[0])

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.uploadApi = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `account_block/accounts/documents_upload`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "POST"
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        }
    }

    ShowDocuments = () => {
        this.setState({ loading: true });
        const localToken = localStorage.getItem("authToken") as string
        const header = {
            "Content-Type": "application/json",
            token: localToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ShowUploadDocument = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/accounts/view_document"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    DeletDocument = () => {
        this.setState({ loading: true });
        const localToken = localStorage.getItem("authToken") as string
        const header = {
            token: localToken
        };
        var formData = new FormData();
        formData.append(this.state.apicallText, "true");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.DeletDoc = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/accounts/documents_destroy"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    downloadDocument = (e: any, name: string, url: string) => {
        e.stopPropagation();
        let link = document.createElement("a");
        link.href = baseURL + url;
        link.download = name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    getDetails = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_dashboard/page_titles/:id?name=Documents"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}
