import React from "react";
// Customizable Area Start
import { Box, Grid, Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Spinner from "../../../components/src/Spinner.web";
const { baseURL } = require("../../../framework/src/config");

let authToken: any;
// Customizable Area End

import LanguageOptionsController, {
  Props,
  configJSON,
} from "./LanguageOptionsController";




export default class LanguageOptions extends LanguageOptionsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area Ends
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCMSDetails();
    authToken = localStorage.getItem('authToken');
    this.setState({ courseId: new URLSearchParams(location.search).get('id') });
    this.setState({ isLoading: true });
    this.fetchCourses();
  }

  setId = () => {
    localStorage.setItem("id", this.state.courseId);
    localStorage.setItem("name", this.state.coursesTypeDetails.name);
    localStorage.setItem("fees", this.state.coursesTypeDetails.Fees ?? 0);
    localStorage.setItem("discount", this.state.coursesTypeDetails.Save ?? 0);
    this.props.navigation.navigate("Payments");
  }

  fetchCourses = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "token": authToken
      }
    }

    fetch(baseURL + `/courses`, requestOptions).then((res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Something went wrong, Please try again.');
    }))
      .then((json) => {
        const selectedData = json.data;
        let selectedCourse = selectedData.filter((x: any) => x.id === this.state.courseId);

        if (selectedCourse.length > 0) {
          let infoArray: any[] = [];
          let name = localStorage.getItem("name");
          if (name === "Regular" || name === "Flexi") {
            selectedCourse[0].attributes = {
              ...selectedCourse[0]?.attributes,
              Fees: selectedCourse[0]?.attributes?.Fees.reg_total_amount
            }
          }

          this.setState({ coursesTypeDetails: selectedCourse[0].attributes });
          selectedCourse[0].attributes.information.data.forEach((detail: any) => {
            infoArray.push(detail);
          });
          this.setState({ courseTypeInformation: infoArray, isLoading: false });
        }
      }).catch((error) => {
        this.setState({
          err: error,
          isLoading: false
        });
      })
  }

  getCMSDetails = () => {
    this.setState({ isLoading: true });

    let name = localStorage.getItem("name");
    const fetchUrl: any = `${baseURL}/bx_block_dashboard/page_titles/:id?name=${name}_course`

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          detail: ResponseJson?.data?.attributes,
          isLoading: false
        });
      }).catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  // Customizable Area End

  render() {
    let { err, isLoading } = this.state;
    return (
      // Customizable Area Start
      <div className="document common course-info">
        {isLoading && <Spinner spinnerModal={isLoading} />}
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/courses.png')` }}>
          <div className="content">
            <h2 className="title">{this.state.coursesTypeDetails?.name}</h2>
            <h5 className="sub-title">Fees: {Number(this.state.coursesTypeDetails.Fees ?? 0).toFixed(2)}</h5>
            <h3 style={{ fontWeight: "bold", marginTop: "20px" }}>{this.state.coursesTypeDetails?.detail}</h3>
          </div>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%" style={{ display: "flex", justifyContent: "center" }}>
              {err ? <h1 style={{ textAlign: "center" }}>{err.message}</h1>
                : <Box>
                  <Grid container spacing={3}>
                    {this.state.courseTypeInformation?.map((data: any, index: number) => {
                      return <Grid item xs={12} sm={6} lg={5} key={index}>
                        <li>
                          <div>
                            <Box component="h5" fontWeight="bold" fontSize={{ xs: "20px", sm: "22px", md: "24px" }}>{data.attributes.info_title}</Box>
                            <Box component="h6" fontSize="13px">{data.attributes.info_detail}</Box>
                          </div>
                        </li>
                      </Grid>
                    })
                    }
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={5} xl={3}>
                      {this.state.courseTypeInformation.length > 0 &&
                        <CustomButton fullWidth onClick={this.setId}>
                          Choose {this.state.coursesTypeDetails.name}
                        </CustomButton>
                      }
                    </Grid>
                  </Grid>
                </Box>
              }
            </Box>
          </Box>
        </section>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomButton = styled(Button)({
  backgroundColor: "black",
  marginTop: "20px",
  marginLeft: "-20px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  },
});

// Customizable Area End
