import React from "react";
// Customizable Area Start
import {
  Button,
  Box,
  InputBase,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { ArrowRightAlt, Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";
import { Formik } from "formik";
import ConfirmPasswordController, {
  Props,
} from "./ConfirmPasswordController.web";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End

const ConfirmButton = styled(Button)({
  backgroundColor: "black",
  margin: "70px 0px 20px 0px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: "ITC Avant Garde Gothic Std",
  "&:hover": {
    backgroundColor: "black",
  },
});

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: "ITC Avant Garde Gothic Std",
  "& .MuiIconButton-label .MuiSvgIcon-root": {
    height: "20px",
  },
});

const CustomInputAdornment = styled(InputAdornment)({
  "@media(max-width: 470px)": {
    display: "none",
  },
});

export default class ConfirmPassword extends ConfirmPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  confirmPasswordSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .required("*Password is required")
        .min(8, "*Password must be greater then 8 character"),
      confirmPassword: Yup.string()
        .required("*Confirm Password required")
        .oneOf([Yup.ref("password"), null], "*Confirm Password must be same"),
    });
  };
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <div className="reset-password common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section">
          <div className="content">
            <h2 className="title">Reset Password</h2>
            <h5 className="sub-title">Eco Drive – The driving institute.</h5>
          </div>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={this.confirmPasswordSchema}
            onSubmit={(values) => {
              this.resetPasswordApi();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >
                <Box mb={3}>
                  <CustomInputBase
                    fullWidth
                    type={this.state.isShowPassword ? "text" : "password"}
                    placeholder="Create Password*"
                    value={this.state.password}
                    startAdornment={
                      <CustomInputAdornment
                        position="start"
                        style={{ marginRight: "45px" }}
                      >
                        Create Password
                      </CustomInputAdornment>
                    }
                    onChange={(event: any) => {
                      this.setState({ password: event.target.value });
                      setFieldValue("password", event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            this.setState({
                              isShowPassword: !this.state.isShowPassword,
                            })
                          }
                          edge="end"
                          style={{ color: "#A2A2A2" }}
                        >
                          {this.state.isShowPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.password && errors.password && (
                    <div className="text-danger">{errors.password}</div>
                  )}
                </Box>
                <Box mb={3}>
                  <CustomInputBase
                    fullWidth
                    type={
                      this.state.isShowConfirmPassword ? "text" : "password"
                    }
                    placeholder="Confirm Password*"
                    value={this.state.confirmPassword}
                    startAdornment={
                      <CustomInputAdornment
                        position="start"
                        style={{ marginRight: "36px" }}
                      >
                        Confirm Password
                      </CustomInputAdornment>
                    }
                    onChange={(event: any) => {
                      this.setState({ confirmPassword: event.target.value });
                      setFieldValue("confirmPassword", event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            this.setState({
                              isShowConfirmPassword: !this.state
                                .isShowConfirmPassword,
                            })
                          }
                          edge="end"
                          style={{ color: "#A2A2A2" }}
                        >
                          {this.state.isShowConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <div className="text-danger">{errors.confirmPassword}</div>
                  )}
                </Box>
                <ConfirmButton fullWidth type="submit">
                  Confirm Password <ArrowRightAlt />
                </ConfirmButton>
              </form>
            )}
          </Formik>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}
