import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Container,
    Grid,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogActions,
    DialogTitle,
    Slide,
    Badge,
    Avatar,
    MenuItem,
    Menu,
    ListItemIcon,
    Divider,
    Tooltip,
    MenuList,
} from "@material-ui/core";
import { CgClose } from "react-icons/cg";
import styled from "styled-components";
import { MdDelete } from "react-icons/md"
import { RiFolderUploadLine } from "react-icons/ri";
//@ts-ignore
import FileViewer from "react-file-viewer";
import moment from "moment";
import Spinner from "../../../components/src/Spinner.web";

import UserNotificationController, { Props } from "./UserNotificationController.web";
const { baseURL } = require("../../../framework/src/config");
// Customizable Area End

const documentBox = {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "15px",
    backgroundColor: "#FFF",
} as const;

const CustomButton = styled.button({
    backgroundColor: "white",
    color: "black",
    border: "2px solid grey",
    padding: "5px 12px",
    fontSize: "14px",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontFamily: 'ITC Avant Garde Gothic Std',
    "&:hover": {
        backgroundColor: "black",
        color: "white"
    }
});

const btnTextArray = [
    "Eye Test Document",
    "Learing permit",
    "NOC",
    "Passport",
    "Photo",
    "Residence Visa Copy",
    "Emirate ID"
]

const titleStyle = {
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis"
} as const;

export default class UserNotification extends UserNotificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            //Merge Engine Start DefaultContainer
            <DocumentsRequiredStyle className="dashboard common">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <div className="first-section">
                    <div className="content">
                        <h2 className="title">Notifications</h2>
                        <h5 className="sub-title">Reference site about lorem Ipsum</h5>
                    </div>
                </div>
                <div className="second-section">
                    <Box width="80%" margin="auto">
                        <Box width="100%">
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <NotificationsStyle>
                                        <MenuList>
                                            <MenuItem className="menuitemNotification">
                                                <div className="notificationContainer">
                                                    <div className="dateAndCloseIcons">
                                                        <div className="day">today</div>
                                                    </div>
                                                    <div className="NotificationDetails">
                                                        <div className="nameAndTimeContainer">
                                                            <div className="IconAndName">
                                                                <div className="blackBox" />
                                                                <div className="name">name</div>
                                                            </div>
                                                            <div className="Time">
                                                                {moment(new Date()).format(
                                                                    "h:mm a"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="message">
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore lorem lorem lorem lore
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem className="menuitemNotification">
                                                <div className="notificationContainer">
                                                    <div className="dateAndCloseIcons">
                                                        <div className="day">today</div>
                                                    </div>
                                                    <div className="NotificationDetails">
                                                        <div className="nameAndTimeContainer">
                                                            <div className="IconAndName">
                                                                <div className="blackBox" />
                                                                <div className="name">name</div>
                                                            </div>
                                                            <div className="Time">
                                                                {moment(new Date()).format(
                                                                    "h:mm a"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="message">
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore lorem lorem lorem lore
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem className="menuitemNotification">
                                                <div className="notificationContainer">
                                                    <div className="dateAndCloseIcons">
                                                        <div className="day">today</div>
                                                    </div>
                                                    <div className="NotificationDetails">
                                                        <div className="nameAndTimeContainer">
                                                            <div className="IconAndName">
                                                                <div className="blackBox" />
                                                                <div className="name">name</div>
                                                            </div>
                                                            <div className="Time">
                                                                {moment(new Date()).format(
                                                                    "h:mm a"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="message">
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore lorem lorem lorem lore
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem className="menuitemNotification">
                                                <div className="notificationContainer">
                                                    <div className="dateAndCloseIcons">
                                                        <div className="day">today</div>
                                                    </div>
                                                    <div className="NotificationDetails">
                                                        <div className="nameAndTimeContainer">
                                                            <div className="IconAndName">
                                                                <div className="blackBox" />
                                                                <div className="name">name</div>
                                                            </div>
                                                            <div className="Time">
                                                                {moment(new Date()).format(
                                                                    "h:mm a"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="message">
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore lorem lorem lorem lore
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem className="menuitemNotification">
                                                <div className="notificationContainer">
                                                    <div className="dateAndCloseIcons">
                                                        <div className="day">today</div>
                                                    </div>
                                                    <div className="NotificationDetails">
                                                        <div className="nameAndTimeContainer">
                                                            <div className="IconAndName">
                                                                <div className="blackBox" />
                                                                <div className="name">name</div>
                                                            </div>
                                                            <div className="Time">
                                                                {moment(new Date()).format(
                                                                    "h:mm a"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="message">
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore lorem lorem lorem lore
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem className="menuitemNotification">
                                                <div className="notificationContainer">
                                                    <div className="dateAndCloseIcons">
                                                        <div className="day">today</div>
                                                    </div>
                                                    <div className="NotificationDetails">
                                                        <div className="nameAndTimeContainer">
                                                            <div className="IconAndName">
                                                                <div className="blackBox" />
                                                                <div className="name">name</div>
                                                            </div>
                                                            <div className="Time">
                                                                {moment(new Date()).format(
                                                                    "h:mm a"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="message">
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore lorem lorem lorem lore
                                                            lorem lorem lorem lore lorem lorem
                                                            lorem lore
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        </MenuList>
                                    </NotificationsStyle>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </div>
            </DocumentsRequiredStyle >
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const NotificationsStyle = styled.span({
    "& .notificationList": {
        width: "150px",
        backgroundColor: "wheat",
        color: "black",
    },
    "& .StaticHeight": {
        height: "200px",
    },
    "& .badgeAndIcon": {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    "& .notificationContainer": {
        width: "100%",
    },
    "& .dateAndCloseIcons": {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 0",
        "& .day": {
            textTransform: "capitalize",
            margin: "8px 0"
        },
        "& .closeIcon": {
            padding: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ":hover": {
                svg: {
                    transform: "scale(1.1,1.1)",
                },
                backgroundColor: "black",
                color: "white",
            },
        },
    },
    "& .nameAndTimeContainer": {
        display: "flex",
        justifyContent: "space-between",
        "& .Time": {
            fontWeight: "600",
        },
    },
    "& .message": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    "& .IconAndName": {
        display: "flex",
        alignItems: "center",
        "& .name": {
            fontSize: "20px",
            fontWeight: "600",
        },
    },
    "& .blackBox": {
        width: "22px",
        height: "22px",
        borderRadius: "4px",
        marginRight: "10px",
        backgroundColor: "black",
    },
    "& .NotificationDetails": {
        padding: "8px 16px",
        backgroundColor: "#cfcfcf",
        borderRadius: "8px",
    },
    "& .FixHeight": {
        maxHeight: "100%",
        height: "300px",
        minHeight: "300p",
    },
    "& .SeeAll": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "600",
        fontSize: "18px",
        cursor: "pointer",
    },
    "& .MuiList-padding": {
        padding: "0",
    },
});

const DocumentsRequiredStyle = styled.div({
    '& .smallText': {
        color: "grey",
        fontSize: "12px",
        fontWeight: "100",
    },
    '& .InputUpload': {
        display: "none"
    },

    '& .pg-viewer-wrapper': {
        overflowY: 'auto',
        display: 'flex',
        "& .photo-viewer-container": {
            height: '100% !important',
            width: "100% !important",
            img: {
                width: '100%',
                height: "100%",
            }
        },
    },
    '& .btnContainer': {
        display: "flex",
        '> button': {
            margin: "0 8px"
        },
        '& :first-child': {
            marginLeft: "0"
        },
        '& :last-child': {
            marginRight: "0px"
        },
        '@media only screen and (max-width: 768px)': {
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            columnGap: "4px",
            rowGap: "6px",
            '> button': {
                margin: "0",
                fontSize: "12px",
                padding: "4px"
            }
        },
        '@media only screen and (max-width: 430px)': {
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: "4px",
            rowGap: "6px",
            '> button': {
                margin: "0",
                fontSize: "12px",
                padding: "4px"
            }
        },
    },
    '& .SubmitBtn': {
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
    },
    '& .uploadContainer': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        backgroundColor: "#f5f5f5",
        padding: '50px',
        borderRadius: "8px",
        marginTop: "30px",
        '@media only screen and (max-width: 430px)': {
            padding: "0px"
        }
    },
    '& .uploadPlace': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "260px",
        borderRadius: "8px",
        backgroundColor: "#161616",
        cursor: "pointer",
        '& .UploadIconAndText': {
            margin: "10px 0",
            cursor: "pointer"
        },
        '& .Heading': {
            color: "white",
            cursor: "pointer",
            textAlign: "center",
            textTransform: 'capitalize',
        },
        '& .details': {
            textAlign: "center",
            color: "gray",
            cursor: "pointer",
            svg: {
                fontSize: "30px",
                color: "white",
                cursor: "pointer",
            }
        },
        '& .Icons': {
            display: 'flex',
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "center"
        }
    }
})
// Customizable Area End
