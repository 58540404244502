import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import Spinner from "../../../components/src/Spinner.web";
import SpecialOfferDetailController, { Props } from "./SpecialOfferDetailController.web";

const { baseURL } = require("../../../framework/src/config");

// Customizable Area End

const RegisterButton = styled(Button)({
  backgroundColor: "#FFF",
  borderRadius: "20px",
  fontWeight: "bold",
  padding: "5px 25px",
  "&:hover": {
    backgroundColor: "#FFF"
  }
});

const CustomButton = styled(Button)({
  border: "1.5px solid black",
  borderRadius: "20px",
  textTransform: "none",
  padding: "7px 25px"
});

class SpecialOfferDetail extends SpecialOfferDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="special-offer common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('./assets/special-offer.png')` }}>
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Special Offers"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Take Advantage of our Special Offers Before they Expire"}</h5>
          </div>
          <div>
            <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
              <img src={"assets/images/whatapp.png"} className="whatapp-img" />
            </Box>
          </div>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%">
              <div className="offer-detail">
                <Box marginBottom={{ xs: "20px", md: "45px" }}>
                  <h4 style={{ fontWeight: "bold" }}>{this.state.offerDetail?.title}</h4>
                  <p>{this.state.offerDetail?.description}</p>
                </Box>
                <Box marginBottom={{ xs: "20px", md: "45px" }}>
                  {this.state.offerDetail?.steps_to_follow?.split("\n").map((item: string, index: number) =>
                    <>
                      {index === 0 && <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>{item}</h4>}
                      {index !== 0 && <p style={{ marginBottom: "10px" }}>{item}</p>}
                    </>
                  )}
                </Box>
                <Box position="relative" marginBottom={{ xs: "20px", md: "40px" }}>
                  <Box height={{ xs: "200px", sm: "300px", lg: "400px" }}>
                    <img src={baseURL + this.state.offerDetail?.special_offer_image} style={{ width: "100%", height: "100%" }} />
                    <Box color="#FFF" position="absolute" bottom={{ xs: 30, sm: 60, lg: 100 }} left={{ xs: 20, sm: 35 }}>
                      <h3 style={{ fontWeight: "bold" }}>Offer Ends Soon!</h3>
                      {this.state.offerDetail?.valid_upto && <p>Register Now before {moment(this.state.offerDetail?.valid_upto).format("MMMM Do")}</p>}
                      <RegisterButton onClick={() => this.props.navigation.navigate("EmailAccountRegistration")}>
                        Register Now
                      </RegisterButton>
                    </Box>
                  </Box>
                </Box>
                <Box marginBottom={{ xs: "20px", md: "40px" }}>
                  {this.state.offerDetail?.terms_and_conditions?.split("\n").map((item: string, index: number) =>
                    <>
                      {index === 0 && <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>{item}</h4>}
                      {index !== 0 && <p style={{ marginBottom: "10px" }}>{item}</p>}
                    </>
                  )}
                </Box>
                <div>
                  <CustomButton onClick={() => { this.props.navigation.navigate("SpecialOffer") }}>
                    <ArrowBackIcon style={{ height: "13px", width: "auto" }} />
                    Go Back to Special Offers
                  </CustomButton>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export default withRouter(SpecialOfferDetail);
// Customizable Area End
