Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

exports.userDetailUrl = "bx_block_dashboard/dashboards/user_details";

exports.resendOtpMethod = "POST";
exports.resendOtpContentType = "application/json";
exports.resendOtpUrl = "account_block/accounts/resend_otps";

exports.verifyOtpMethod = "POST";
exports.verifyOtpContentType = "application/json";
exports.verifyOtpUrl = "account_block/accounts/sms_confirmations";

exports.trainingStageEndPoint = "/bx_block_scheduling/schedules/get_stages";

exports.cmsApiMethod = "GET";
exports.cmsApiContentType = "application/json";
exports.cmsApiEndPoint = "/bx_block_dashboard/page_titles/:id?name=Dashboard";

// Customizable Area End