import React from 'react';
import { withRouter } from 'react-router';
import styled from "styled-components";
import { MdCheckCircle } from 'react-icons/md';
import CustomerPaymentController, { Props, PendingPaymentOption, PaidPaymentOption } from './CustomerPaymentController.web';
import Spinner from "../../../components/src/Spinner.web";

class CustomerPayment extends CustomerPaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <CommonWrapper className="dashboard common">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <div className="first-section" style={{ backgroundImage: this.state.cmsDetail?.image ? `url(${this.state.cmsDetail?.image})` : `url('/assets/images/vehicle_detail.png')` }}>
                    <div>
                        <div className="content">
                            <h2 className="title">Hello, {this.state.userName}</h2>
                            <h5 className="sub-title">Student</h5>
                        </div>
                    </div>
                </div>
                <section className="second-section" style={{ backgroundColor: "#FFF" }}>
                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading">
                                    <h2>Amount to pay</h2>
                                    <span>You can pay by choosing multiple stages</span>
                                </div>
                                <form autoComplete="off">
                                    <div className="row ">
                                        <div className="col-sm-6">
                                            <div className="blackBox">
                                                <div className='divFirst'>
                                                    <div className='PendingAmount'>
                                                        <div className='SmallHeading'>
                                                            <h5>Pending amount</h5>
                                                        </div>
                                                        <div>
                                                            <span className="title">AED {this.state.pendingAmount.toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className='TotalAmount'>
                                                        <div>
                                                            <div className='SmallHeading'>
                                                                <h5>Total amount</h5>
                                                            </div>
                                                            <div>
                                                                <span className="subtitle">AED {this.state.totalAmount.toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='PaidAmount'>
                                                                <div className='SmallHeading'>
                                                                    <h5>Paid amount</h5>
                                                                </div>
                                                                <div>
                                                                    <span className="subtitle">AED {this.state.paidAmount.toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="blackBox">
                                                <div className='divFirst'>
                                                    <div className='PendingAmount'>
                                                        <div>
                                                            <span className="title">Payment</span>
                                                        </div>
                                                        <div>
                                                            <span className="subtitle">{this.state.partialNetAmount.toFixed(2)} AED</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {this.state.pendingPaymentStage.map((item: PendingPaymentOption) => {
                                                            return (
                                                                <div className="partial-payments-block" key={item.id}>
                                                                    <div className="partial-payments-wrapper checkbox-custom">
                                                                        <input type="checkbox" id={item.name} name={item.name} onChange={() => this.partialCheckBoxChange(item)} value={item.amount} checked={item.isChecked} />
                                                                        <label className="checkbox-custom-label" htmlFor={item.name}>
                                                                            <div>
                                                                                <div>{item.name}</div>
                                                                                <div className='smallDec'>
                                                                                    <h5>{item.subTitle}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    <h6 className="partial-amount">AED {item.amount.toFixed(2)}</h6>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                {this.state.paidPaymentStage.map((item: PaidPaymentOption) => {
                                                    return (
                                                        <div className='divConatiner' key={item.id}>
                                                            <div className='descriptionConainer'>
                                                                <div>
                                                                    <span className="subtitle">{item.name}</span>
                                                                </div>
                                                                <div className='FileOpenDec'>
                                                                    <h5>{item.subTitle}</h5>
                                                                </div>
                                                            </div>
                                                            <div className='fileDownContainer'>
                                                                <div className='AmountIcon'>
                                                                    <div>
                                                                        <span className="title">AED {(item.total).toFixed(2)}</span>
                                                                    </div>
                                                                    <div className='iconAndPaid'>
                                                                        <div className='Icon'>
                                                                            <MdCheckCircle />
                                                                        </div>
                                                                        <span className="subtitle paid">Paid</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </div>
                                            <div className="mainBlock detail">
                                                <div>
                                                    <div className='AmountAndDec'>
                                                        <span className="subtitle">Eco share</span>
                                                        <span className="subtitle">AED {this.state.partialNetAmount.toFixed(2)}</span>
                                                    </div>
                                                    <div className='AmountAndDec'>
                                                        <span className="subtitle">Discount</span>
                                                        <span className="subtitle">AED {this.state.partialDiscount.toFixed(2)}</span>
                                                    </div>
                                                    <div className='AmountAndDec'>
                                                        <span className="subtitle">Vat (5%)</span>
                                                        <span className="subtitle">AED {this.state.partialVatAmount.toFixed(2)}</span>
                                                    </div>
                                                    <div className='AmountAndDec'>
                                                        <span className="subtitle weight">Subtotal</span>
                                                        <span className="subtitle weight">AED {(this.state.partialNetAmount + this.state.partialVatAmount).toFixed(2)}</span>
                                                    </div>
                                                    <div className='AmountAndDec'>
                                                        <span className="subtitle">RTA</span>
                                                        <span className="subtitle">AED {this.state.partialRTAAmount.toFixed(2)}</span>
                                                    </div>
                                                    <div className='AmountAndDec'>
                                                        <span className="subtitle weight">Amount payable:</span>
                                                        <span className="subtitle weight">AED {this.state.totalPayableAmount.toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='BtnContainer'>
                                                <button
                                                    className="PayBtn"
                                                    style={{ opacity: this.state.totalPayableAmount === 0 ? '0.5' : '1', cursor: this.state.totalPayableAmount === 0 ? "default" : "pointer" }}
                                                    disabled={this.state.totalPayableAmount === 0 ? true : false}
                                                    onClick={() => this.onPayNowButtonClick()}
                                                >
                                                    Pay now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Container>
                </section>
            </CommonWrapper>
        )
    }
}

export default withRouter(CustomerPayment);

const CommonWrapper = styled.main({
    '& .heading': {
        marginBottom: "45px",
        '& h2': {
            fontWeight: "600",
            fontSize: "22px",
        },
        '& span': {
            fontSize: "15px",
            fontWeight: "400"
        },
    },
    '& .mainBlock': {
        borderRadius: '15px',
        color: '#fff',
        '&.detail': {
            border: "1px solid grey",
            padding: '10px 20px',
        },
        '& .AmountAndDec': {
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 0",
            '& .weight': {
                fontWeight: "600"
            },
            '& .subtitle': {
                fontSize: "14px"
            },
            '& .subtitle:nth-child(2)': {
                textAlign: "right"
            }
        },
        '& .divConatiner': {
            marginBottom: "20px",
            padding: "10px 20px",
            borderRadius: '15px',
            boxShadow: "0px 1px 4px 1px rgb(0 0 0 / 12%)",
        },
        '& .divConatiner:last-child': {
            borderBottom: "unset"
        },
        '& .title': {
            color: "black"
        },
        '& .subtitle': {
            color: "black",
            fontSize: "16px",
            fontWeight: "400",
        },
        '& .paid ': {
            fontWeight: "bold"
        },
        '& .FileOpenDec': {
            h5: {
                color: 'black',
                fontSize: '12px',
                marginBottom: 0,
            },
        },
        '& .descriptionConainer': {
            marginBottom: "4px"
        },
        '& .fileDownContainer': {
            display: 'flex'
        },
        '& .AmountIcon': {
            display: "flex",
            alignItems: "center",
        },
        '& .iconAndPaid': {
            color: "black",
            paddingLeft: "12px",
            display: "flex",
            alignItems: "flex-start",
            '& .Icon': {
                color: "#003E3B",
                paddingRight: "6px"
            }
        }
    },
    '& .smallDec': {
        h5: {
            color: "grey",
            fontSize: "12px",
            marginBottom: 0
        }
    },
    '& .BtnContainer': {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
    '& .FristDiv': {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        padding: '10px 20px',
        h5: {
            color: "grey",
            fontSize: "14px",
            marginBottom: 0
        },
        'h5:nth-child(even)': {
            fontWeight: "600",
            color: "black"
        },
        '@media only screen and (max-width: 430px)': {
            padding: '10px 10px',
        }
    },
    '@media only screen and (max-width: 430px)': {
        '& .col-sm-6': {
            paddingBottom: '20px !important'
        },
        '& .partial-payments-block': {
            h6: {
                width: "90px"
            }
        }
    },
    '& .blackBox': {
        padding: '28px 25px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        background: '#161616',
        borderRadius: '15px',
        color: '#fff',
        minHeight: "130px",
        marginBottom: '18px',
        '& .divFirst': {
            width: "100%",
            '& .partial-payments-block': {
                alignItems: "unset",
                '& .checkbox-custom-label': {
                    alignItems: 'unset'
                },
                '& .checkbox-custom input[type="checkbox"]+label:before': {
                    marginTop: "4px"
                }
            },
            '& .SmallHeading': {
                marginBottom: "3px"
            },
            '& .PendingAmount': {
                marginBottom: "40px"
            },
            h5: {
                color: "#979393",
                fontSize: "12px",
                marginBottom: 0,
                maxWidth: "215px"
            },
            '& .TotalAmount': {
                display: "flex",
                justifyContent: "space-between",
                '& .subtitle': {
                    fontSize: "16px",
                },
                '& .PaidAmount': {
                    textAlign: "right"
                }
            },
        }
    },
    '& .Paid': {
        display: 'flex',
        alignItems: "center"
    },
    '& .PaidText': {
        paddingLeft: "5px"
    }
});

const Container = styled.div`
width: 80%;
margin: auto;
.title{
    font-size: 16px;
    color:white;
    font-weight: "500";
}
.PayBtn{
    width: 60%;
    font-weight: bold;
    background-color: #161616;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
    border: 0px;
}
.OkBtn{
    background-color: #161616 !important;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
}
@media only screen and (max-width: 992px) {
  .PayBtn{
    width: 100%;
  }
}
`;
