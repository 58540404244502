import React from "react";
// Customizable Area Start
import TestController, {
  Props,
  //configJSON
} from "./OnlineLectureController.web";
export default class Test extends TestController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
 
    return (
      <>
        <div className="dashboard common">
          <div className="first-section pb70">
            <div className="content">
              <h2 className="title">Hello, !</h2>
              <h5 className="sub-title">Student</h5>
            </div>
          </div>
        </div>
        <iframe
          style={{
            marginBottom:"2rem",
            height: "100vh",
            width: "100vw",
            display: "block",
          }}
          src={this.state.loginURL}
          allow="autoplay; encrypted-media"
          title="video"
        />
      </>
    );
  }
}

// Customizable Area End
