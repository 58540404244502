import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  cmsDetail: any;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  userName: string;
  quantity: number;
  userPicture: string;
  category: string;
  LPNNumber: number | null;
  classType: string;
  hours: number | null;
  pendingLecture: string | null;
  theoryLecture: string;
  knowledgeTest: string;
  yardTest: string;
  roadTest: string;
  open: boolean;
  openIframe: boolean;
  isOnlineLecturesCompleted: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      cmsDetail:"",
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      userName: "",
      quantity: 0,
      userPicture: "",
      LPNNumber: null,
      category: "",
      classType: "",
      hours: null,
      pendingLecture: "0",
      theoryLecture: "0",
      knowledgeTest: "",
      yardTest: "",
      roadTest: "",
      open: false,
      openIframe: false,
      isOnlineLecturesCompleted: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dashboardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.dashboardApiCallId) {
        if (responseJson && responseJson.status !== 500) {
          let hour = responseJson?.practical_training_hours?.hours;
          let theoryLecture = responseJson?.practical_training_hours?.theory_lecture;
          let knowledgeTest = responseJson?.practical_training_hours?.knowledge_test;
          let yardTest = responseJson?.practical_training_hours?.yard_test;
          let roadTest = responseJson?.practical_training_hours?.road_test;

          this.setState({
            userName: responseJson?.name,
            quantity: responseJson?.quantity,
            LPNNumber: responseJson?.LPN,
            category: responseJson?.category,
            classType: responseJson?.class_type,
            hours: hour,
            theoryLecture: theoryLecture?.replace("Theory_lecture = ", ""),
            knowledgeTest: knowledgeTest?.replace("Knowledge Test = ", ""),
            yardTest: yardTest?.replace("Yard Test = ", ""),
            roadTest: roadTest?.replace("Road Test = ", "")
          });

        } else {
          toast.error("Something went wrong!");
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.getCourseStatusApiCallId) {
        if (responseJson && !responseJson.errors) {
          if (responseJson.all_courses_completed === true) {
            this.setState({ isOnlineLecturesCompleted: true });
          }
        } else {
          console.log("@@@@@ get course status fail", responseJson)
        }
      } else if(apiRequestCallId === this.getCmsDetailApiCallId) {
        this.setState({ cmsDetail: responseJson?.data?.attributes });
      }
    }
    this.setState({ loading: false });
    // Customizable Area End
  }

  // Customizable Area Start
  getCourseStatusApiCallId: string = "";
  getCmsDetailApiCallId: string = "";

  getCourseStatus = async () => {
    // Customizable Area Start
    let token = localStorage.getItem("authToken")
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'api/talent_lms/accounts/course_status'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCmsDetails = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.cmsApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCmsDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cmsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cmsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End

}
